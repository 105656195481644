<script setup lang="ts">
import { onMounted, ref } from "vue";
import api from "@/api";
import MicroModal from "micromodal";
import PermissionDeniedView from "@/views/PermissionDeniedView.vue";
import UserView from "@/views/UserView.vue";
import { useStore } from "vuex";
import { useDisplayStore } from "@/stores/display";
import { useFeaturesStore } from "@/stores/features";

// Should use proper routing, but...
const view = ref<"user" | "denied" | null>(null);

const store = useStore();

const display = useDisplayStore();
const features = useFeaturesStore();

onMounted(async () => {
    MicroModal.init();

    let user;

    try {
        user = await api.users.get();
    } catch (e) {
        // empty
    }

    if (user === undefined) {
        view.value = "denied";
    } else {
        const displayData = await api.strings.displayData();

        display.update(displayData);
        features.update(user.admin_access);

        store.commit("setLoginUser", user);
        store.dispatch("updateUserInAll", user);
        store.dispatch("updateTree");

        view.value = "user";
    }
});
</script>

<template>
    <UserView v-if="view === 'user' && store.state.user" />
    <PermissionDeniedView v-else-if="view === 'denied'" />
    <div v-else></div>
</template>
