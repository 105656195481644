import _ from "lodash";
import { z } from "zod";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { ATTRIBUTE_GROUP_STRUCTURE } from "@/config";
import { groupByStructure } from "@/utils";
import { parseUsersAttributes, areAttributesEqual } from "@/attributes";
import api from "@/api";
import { normalizeErrorResponse } from "@/forms";
import type { UserDetail } from "@/api";
import type { Attribute } from "@/attributes";
import type { FormErrors } from "@/forms";
import { useOverviewStore } from "@/stores/overview";

export const useAttributesStore = defineStore("attributes", () => {
    const overview = useOverviewStore();

    // Tag of data used to avoid data races
    const user = ref<UserDetail | undefined>(undefined);

    const state = ref<"editing" | "saving">("editing");
    const errors = ref<FormErrors | undefined>(undefined);

    const attributes = ref<Record<string, Attribute<string>>>({});
    const original = ref<Record<string, Attribute<string>>>({});

    const hasBeenModified = computed(() => {
        if (!attributes.value || !original.value) {
            return false;
        }

        for (const key of Object.keys(attributes.value)) {
            if (
                !areAttributesEqual(attributes.value[key], original.value[key])
            ) {
                return true;
            }
        }

        return false;
    });

    const sortedAttributes = computed(() => {
        let allKeys: Record<string, string> = {};

        Object.keys(attributes.value).forEach((k) => (allKeys[k] = k));

        const groups = groupByStructure(allKeys, ATTRIBUTE_GROUP_STRUCTURE);

        let sortedKeys = [];

        for (const group of Object.values(groups)) {
            for (const key of group.items) {
                sortedKeys.push(key);
            }
        }

        return sortedKeys.map((key) => {
            return attributes.value[key];
        });
    });

    const updateUser = (updatedUser: UserDetail) => {
        state.value = "editing";
        errors.value = undefined;
        user.value = updatedUser;
        attributes.value = parseUsersAttributes(updatedUser);
        original.value = _.cloneDeep(attributes.value);
    };

    const reset = () => {
        errors.value = undefined;
        attributes.value = _.cloneDeep(original.value);
    };

    const updateValue = ([key, value]: [string, any]) => {
        attributes.value[key].value = value;
    };

    const setToDefault = (key: string) => {
        if (!attributes.value[key]?.inherit) {
            attributes.value[key].value = attributes.value[key].default;
        }
    };

    const updateInherit = (key: string) => {
        attributes.value[key].inherit = !attributes.value[key].inherit;
    };

    const updateWhocanset = ([key, whocanset]: [string, number]) => {
        attributes.value[key].whocanset = whocanset;
    };

    const save = async () => {
        if (!user.value) {
            return;
        }

        const idUser = user.value.id_user;

        // Sanity check that stores has not gotten out of sync
        if (overview.idUser !== idUser) {
            errors.value = {
                general: "Users out of sync. Please refresh.",
            };
            return;
        }

        state.value = "saving";
        errors.value = undefined;

        try {
            let updatedAttributes: Record<string, Attribute<string>> = {};

            for (const key of Object.keys(attributes.value)) {
                if (
                    !areAttributesEqual(
                        attributes.value[key],
                        original.value[key]
                    )
                ) {
                    updatedAttributes[key] = attributes.value[key];
                }
            }

            let updatedUser = await api.users.patchAttributes(
                idUser,
                updatedAttributes
            );

            if (user.value.id_user === idUser) {
                updateUser(updatedUser);
            }
        } catch (rawErrors) {
            const errorSchema = z
                .union([
                    z.string().transform((_) => "Error when saving"),
                    z
                        .object({
                            attributes: z.record(z.string().array()),
                        })
                        .transform((a) => a.attributes),
                ])
                .catch("Error when saving");

            errors.value = normalizeErrorResponse(
                errorSchema.parse(rawErrors),
                Object.keys(attributes.value)
            );
        }

        if (user.value.id_user === idUser) {
            state.value = "editing";
        }
    };

    return {
        user,
        attributes,
        sortedAttributes,
        hasBeenModified,
        state,
        errors,
        updateUser,
        updateValue,
        setToDefault,
        updateInherit,
        updateWhocanset,
        reset,
        save,
    };
});
