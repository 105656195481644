<template>
    <div :class="{ inherited: isActiveSubTabInherited }">
        <div class="flex items-center mx-2 bt">
            <TabItemSecondary
                class="flex-grow-0 mr-1"
                :active="isActiveSubTab('regular')"
                :disabled="isSubTabDisabled('regular')"
                mode="respect"
                @click="changeSubTab('regular')"
            >
                Respect
            </TabItemSecondary>

            <div class="meta-container--wide flex-grow">
                <AttributeInherit
                    :attribute="license.regular"
                    :disabled="!isActiveSubTab('regular')"
                    @change="updateInherit([licenseType, 'regular'])"
                />
                <AttributeWhoCanSet
                    :relative-id-user="user.id_user"
                    :attribute="license.regular"
                    :disabled="!isActiveSubTab('regular')"
                    @update="
                        (v) =>
                            $store.commit('licenses/updateWhocanset', [
                                [licenseType, 'regular'],
                                v,
                            ])
                    "
                />
            </div>
        </div>

        <div class="flex items-center mx-2 bt">
            <TabItemSecondary
                class="flex-grow-0 mr-1"
                :active="isActiveSubTab('enforce')"
                :disabled="isSubTabDisabled('enforce')"
                mode="enforce"
                @click="changeSubTab('enforce')"
            >
                Enforce
            </TabItemSecondary>

            <div class="meta-container--wide flex-grow">
                <AttributeInherit
                    :attribute="license.enforce"
                    :disabled="!isActiveSubTab('enforce')"
                    @change="updateInherit([licenseType, 'enforce'])"
                />
                <AttributeWhoCanSet
                    :relative-id-user="user.id_user"
                    :attribute="license.enforce"
                    :disabled="!isActiveSubTab('enforce')"
                    @update="
                        (v) =>
                            $store.commit('licenses/updateWhocanset', [
                                [licenseType, 'enforce'],
                                v,
                            ])
                    "
                />
            </div>
        </div>

        <div class="bg-inherit px-2 py-label">
            <div class="license-content">
                <div
                    v-for="groupDescription in licenseGroupDescriptions"
                    :key="groupDescription"
                    class="license-group"
                >
                    <p class="label label-clean">
                        {{ groupDescription.text }}
                    </p>
                    <div
                        v-for="description in groupDescription.items"
                        :key="description"
                        class="license-entry"
                    >
                        <LicenseToggle
                            :license="license"
                            :license-type="licenseType"
                            :description="description"
                            :tab-type="activeSubTab"
                        />
                    </div>
                </div>
            </div>
        </div>

        <ErrorSection :message="errorMessage" />

        <SaveResetFooter
            :is-saving="isSaving"
            :is-modified="hasTypeBeenModified(licenseType)"
            @reset="resetLicense"
            @save="saveLicense"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { mapState as mapPiniaState } from "pinia";
import AttributeInherit from "@/components/attributes/AttributeInherit.vue";
import AttributeWhoCanSet from "@/components/attributes/AttributeWhoCanSet.vue";
import LicenseToggle from "./LicenseToggle.vue";
import TabItemSecondary from "@/components/utils/TabItemSecondary.vue";
import { useDisplayStore } from "@/stores/display";

export default {
    components: {
        AttributeInherit,
        AttributeWhoCanSet,
        LicenseToggle,
        TabItemSecondary,
    },
    props: ["license", "licenseType"],
    computed: {
        ...mapPiniaState(useDisplayStore, ["licenseGroupDescriptions"]),
        ...mapState(["user"]),
        ...mapGetters("licenses", ["hasTypeBeenModified"]),
        ...mapState("licenses", {
            errorMessage(state) {
                return state.errorMessage[this.licenseType];
            },
            isSaving(state) {
                return state.isSaving[this.licenseType];
            },
            activeSubTab(state) {
                return state.activeSubTabs[this.licenseType];
            },
        }),
        isActiveSubTabInherited() {
            return this.license[this.activeSubTab].inherit;
        },
    },
    methods: {
        ...mapMutations("licenses", ["updateInherit", "reset"]),
        ...mapActions("licenses", ["save"]),
        resetLicense() {
            this.reset(this.licenseType);
        },
        saveLicense() {
            this.save(this.licenseType);
        },
        isSubTabDisabled(tabType) {
            return this.$store.getters["licenses/isSubTabDisabled"](
                this.license,
                tabType
            );
        },
        subTabText(tabType, text) {
            if (this.isSubTabDisabled(tabType)) {
                return `${text} (not allowed to change)`;
            }

            return text;
        },
        isActiveSubTab(tabType) {
            return this.activeSubTab === tabType;
        },
        changeSubTab(tabType) {
            if (!this.isSubTabDisabled(tabType)) {
                this.$store.commit("licenses/setActiveSubTab", [
                    this.licenseType,
                    tabType,
                ]);
            }
        },
    },
};
</script>

<style scoped>
.license-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 0;
}

.license-group {
    padding-right: 1rem;
}
</style>
