<script setup lang="ts">
import { useFeaturesStore } from "@/stores/features";
import { useMiscStore } from "@/stores/misc";
import { storeToRefs } from "pinia";
import TranslateQuotaFields from "@/components/utils/TranslateQuotaFields.vue";
import ImpersonationTargets from "@/components/utils/ImpersonationTargets.vue";

const features = useFeaturesStore();
const misc = useMiscStore();

const { translated, quota, user, state, errors, hasBeenModified } =
    storeToRefs(misc);
</script>

<template>
    <section>
        <h1 class="px-2">Misc</h1>

        <div v-if="user" class="px-2 py-label w-1/2">
            <h2 class="h4">Impersonation</h2>
            <div class="mt-1">
                <ImpersonationTargets :targets="user.impersonation_targets" />
            </div>
        </div>

        <div class="px-2 py-label w-1/2">
            <div v-if="features.misc">
                <h2 class="h4">Translation</h2>
                <div class="mt-1">
                    <TranslateQuotaFields
                        v-model:translated="translated"
                        v-model:quota="quota"
                    />
                </div>
            </div>
        </div>

        <ErrorSection class="w-1/2" :message="errors?.general" />

        <SaveResetFooter
            class="w-1/2"
            :is-saving="state === 'saving'"
            :is-modified="hasBeenModified"
            @reset="misc.reset"
            @save="misc.save"
        />
    </section>
</template>
