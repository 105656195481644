import api from "@/api";

const siteRequestBatchSize = 100;

interface SiteCacheEntry {
    id_site: number;
    sitename: string;
    type?: string;
    getLabel(): string;
}

interface SiteCache {
    data: Record<number, SiteCacheEntry>;
    getDummy(idSite: number, sitename: string): SiteCacheEntry;
    requestMany(idSites: number[]): Promise<SiteCacheEntry[]>;
}

const siteCache: SiteCache = {
    data: {},
    getDummy(idSite: number, sitename: string = ""): SiteCacheEntry {
        return {
            id_site: idSite,
            sitename: sitename,
            getLabel() {
                return `${this.sitename} (${this.id_site})`;
            },
        };
    },
    async requestMany(idSites: number[]): Promise<SiteCacheEntry[]> {
        const idSitesToFind = idSites.filter((id) => !(id in this.data));

        if (idSitesToFind.length > 0) {
            // API don't like too big requests
            for (
                let i = 0;
                i < idSitesToFind.length;
                i += siteRequestBatchSize
            ) {
                const batch = idSitesToFind.slice(i, i + siteRequestBatchSize);
                const siteResponse = await api.sites.search(batch);

                const siteData: Record<number, SiteCacheEntry> = {};

                //@ts-ignore
                siteResponse.forEach((s) => {
                    siteData[s.id_site] = {
                        id_site: s.id_site,
                        sitename: s.sitename,
                        type: s.type,
                        getLabel() {
                            return `${this.sitename} (${this.id_site})`;
                        },
                    };
                });

                batch.forEach((idSite) => {
                    if (idSite in siteData) {
                        this.data[idSite] = siteData[idSite];
                    } else {
                        // Some sites does not have site data
                        this.data[idSite] = this.getDummy(idSite, "No name");
                    }
                });
            }
        }

        return idSites.map((idSite) => {
            return this.data[idSite];
        });
    },
};

export default siteCache;
