<script setup lang="ts">
import { ref, onMounted } from "vue";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.min.js";
import api from "@/api";
import type { SimpleCustomer } from "@/api";

const searchTerm = ref("");
const searchInput = ref<HTMLInputElement | null>(null);

const emit = defineEmits<{
    (e: "change", customer: SimpleCustomer): void;
}>();

onMounted(() => {
    // @ts-ignore
    $("#js-customer-search").autocomplete({
        minLength: 2,
        delay: 300,
        source: async (req: any, resp: any) => {
            let customers = await api.customers.search(req.term);
            resp(
                customers.map((s) => {
                    return {
                        label: `${s.name} (${s.partner})`,
                        data: s,
                    };
                })
            );
        },
        select: async (event: any, ui: any) => {
            emit("change", ui.item.data);
        },
    });

    if (searchInput.value) {
        searchInput.value.focus();
    }
});
</script>

<template>
    <input
        id="js-customer-search"
        ref="searchInput"
        v-model="searchTerm"
        type="text"
        placeholder="Search"
    />
</template>
