<script setup lang="ts">
import api from "@/api";
import TheHeader from "@/components/TheHeader.vue";

const logout = async () => await api.auth.logout();
</script>

<template>
    <TheHeader @logout="logout" />

    <div>
        <p class="message mt-1">
            You don't have access to user administration.
        </p>
    </div>
</template>

<style lang="scss" scoped>
.message {
    text-align: center;
}
</style>
