<script setup lang="ts">
import type { Attribute } from "@/attributes";

const emit = defineEmits<{
    (e: "remove", value: boolean): void;
}>();

const props = defineProps<{
    username: string;
    inherit: boolean;
}>();

function doRemove(): void {
    if (!props.inherit && confirm(`Do you really want to remove the user ${props.username}?`))
        emit("remove", true);
}

</script>

<template>
    <span class="remove-button" @click="doRemove()">
        {{ props.username }}
    </span>
</template>

<style scoped>
.remove-button {
    cursor: pointer;
}
</style>
