<script setup lang="ts">
import { computed, ref, watch } from "vue";
import api from "@/api";
import type { UserDetail } from "@/api";

import TheHeader from "@/components/TheHeader.vue";
import SectionToggles from "@/components/SectionToggles.vue";
import { Section } from "@/components/SectionToggles.vue";
import TheSidebar from "@/components/sidebar/TheSidebar.vue";
import UserDetailsSection from "@/components/user_details/UserDetailsSection.vue";
import AccessSection from "@/components/access/AccessSection.vue";
import LicenseSection from "@/components/licenses/LicenseSection.vue";
import ManualModal from "@/components/manuals/ManualModal.vue";
import ModuleSection from "@/components/modules/ModuleSection.vue";
import AttributeSection from "@/components/attributes/AttributeSection.vue";
import MiscSection from "@/components/misc/MiscSection.vue";
import CustomerSection from "@/components/customer/CustomerSection.vue";
import CustomerChangeNew from "@/components/customer/CustomerChangeNew.vue";
import CustomerChangeExisting from "@/components/customer/CustomerChangeExisting.vue";
import PasswordResetModal from "@/components/PasswordResetModal.vue";
import PasswordResetEmailModal from "@/components/PasswordResetEmailModal.vue";
import UserCreateSection from "@/components/create/UserCreateSection.vue";
import { useStore } from "vuex";
import { useCustomerStore } from "@/stores/customer";

const store = useStore();
const customerStore = useCustomerStore();

const section = ref(Section.Overview);
const sectionTogglesDisabled = computed(() => store.state.page !== "user");

const showSection = async (newSection: Section) => {
    if (!sectionTogglesDisabled.value) {
        if (newSection === Section.Customer) {
            const currentIdUser = store.state.user.id_user;

            const customer = await api.customers.get(
                store.state.userDetails.customer.id
            );

            if (store.state.user.id_user === currentIdUser) {
                customerStore.open([store.state.user, customer]);
            }
        }

        section.value = newSection;
    }
};

const isSidebarDisabled = computed(() => store.state.page !== "user");

// Reset state when we observe a new user
watch(
    () => {
        return store.state.user.id_user;
    },
    (_, _1) => {
        section.value = Section.Overview;
    }
);

const changeToNewUser = (newUser: UserDetail) => {
    store.commit("sidebar/updateTree", newUser, { root: true });
    store.dispatch(
        "updateUser",
        {
            idUser: newUser.id_user,
            resetTree: false,
        },
        { root: true }
    );
};

const logout = async () => await api.auth.logout();
</script>

<template>
    <TheHeader :username="store.state.loginUser.username" @logout="logout" />

    <div class="container">
        <div class="toggles-fill-left"></div>
        <div class="toggles-container">
            <SectionToggles
                :section="section"
                :disabled="sectionTogglesDisabled"
                @show-section="showSection"
            />
        </div>
        <div class="toggles-fill-right"></div>

        <div class="sidebar-container">
            <TheSidebar :is-disabled="isSidebarDisabled" />
        </div>

        <div class="content-container bl">
            <UserCreateSection
                v-if="store.state.page === 'newUser'"
                :options="store.state.user.create_options"
                @new-user="changeToNewUser"
                @cancel="store.dispatch('cancelNewUser')"
            />
            <CustomerChangeNew
                v-else-if="store.state.page === 'customerChangeNew'"
            />
            <CustomerChangeExisting
                v-else-if="
                    store.state.page === 'customerChangeExisting' &&
                    customerStore.user
                "
                :id-user="customerStore.user.id_user"
            />
            <div v-else class="content">
                <UserDetailsSection v-if="section === Section.Overview" />
                <AccessSection v-if="section === Section.Access" />
                <LicenseSection v-if="section === Section.Licenses" />
                <ModuleSection v-if="section === Section.Modules" />
                <AttributeSection v-if="section === Section.Attributes" />
                <MiscSection v-if="section === Section.Misc" />
                <CustomerSection v-if="section === Section.Customer" />
            </div>
        </div>
    </div>

    <!-- Modals -->
    <ManualModal manual="licenses" />
    <ManualModal manual="modules" />
    <PasswordResetModal :idUser="store.state.user.id_user" />
    <PasswordResetEmailModal />
</template>

<style scoped>
.container {
    position: relative;
    margin-bottom: 5rem;

    display: grid;
    grid-template-columns: 1fr minmax(300px, 350px) 900px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "left left toggles right"
        ". sidebar content .    ";
    grid-gap: 0;
}

.toggles-container {
    background: var(--section-background);
    grid-area: toggles;
}

.toggles-fill-left {
    background: var(--section-background);
    grid-area: left;
}

.toggles-fill-right {
    background: var(--section-background);
    grid-area: right;
}

.sidebar-container {
    grid-area: sidebar;
}

.content-container {
    grid-area: content;
}
</style>
