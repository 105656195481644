/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import _ from "lodash";

const PAGE_SIZE = 10;

class PaginatedNode {
    constructor(open = false) {
        this.children = [];
        this.visibleChildren = [];

        this.activeFilter = false;
        this.filteredByActiveFilter = false;

        this.searchTerm = null;

        this.pagination = {
            page: 1,
            pages: 1,
            total: 0,
            pageSize: PAGE_SIZE,
            open: open,
        };
    }

    updateUser(user) {
        if (this.hasUser()) {
            this.user.username = user.username;
            this.user.customer = user.customer;
            this.user.is_active = user.is_active;
            this.user.user_type = _.cloneDeep(user.user_type);
        }
    }

    isShowable() {
        return !this.filteredByActiveFilter;
    }

    setActiveFilter() {
        this.activeFilter = true;

        if (this.user.is_active) {
            this.filteredByActiveFilter = false;
        } else {
            this.filteredByActiveFilter = true;

            for (const node of this.children) {
                if (!node.filteredByActiveFilter) {
                    this.filteredByActiveFilter = false;
                    break;
                }
            }
        }

        this.updatePagination();
    }

    removeActiveFilter() {
        this.activeFilter = false;
        this.filteredByActiveFilter = false;

        this.updatePagination();
    }

    addChildNodes(nodes) {
        for (const node of nodes) {
            node.parent = this;
            this.children.push(node);
        }

        this.children.sort(nodeCmp);
        this.updatePagination();
    }

    addChildNode(node) {
        this.addChildNodes([node]);
    }

    addChildUsers(children) {
        this.addChildNodes(children.map((user) => new Node(user, this)));
    }

    addChildUser(child) {
        this.addChildUsers([child]);
    }

    popNodeById(idUser) {
        let found_i = null;

        for (const [i, child] of this.children.entries()) {
            if (child.user.id_user === idUser) {
                found_i = i;
                break;
            }
        }

        if (found_i === null) {
            throw `Did not find node ${idUser}`;
        }

        let pop = this.children.splice(found_i, 1)[0];
        this.updatePagination();

        return pop;
    }

    getVisibleChildren() {
        return this.visibleChildren;
    }

    showableChildren() {
        return this.children.filter((c) => {
            if (this.searchTerm !== null) {
                if (
                    c.hasUser() &&
                    c.user.username.indexOf(this.searchTerm) == -1
                ) {
                    return false;
                }
            }

            return c.isShowable();
        });
    }

    updateVisibleChildren() {
        if (this.pagination.open) {
            this.visibleChildren = this.showableChildren().slice(
                (this.pagination.page - 1) * this.pagination.pageSize,
                this.pagination.page * this.pagination.pageSize
            );
        } else {
            this.visibleChildren = [];
        }
    }

    updatePagination() {
        const total = this.showableChildren().length;

        this.pagination = {
            page: 1,
            pages: Math.ceil(total / this.pagination.pageSize),
            total: total,
            pageSize: this.pagination.pageSize,
            open: this.pagination.open,
        };

        this.updateVisibleChildren();
    }

    moveToPreviousPage() {
        if (this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.updateVisibleChildren();
        }
    }

    moveToNextPage() {
        if (this.pagination.page < this.pagination.pages) {
            this.pagination.page += 1;
            this.updateVisibleChildren();
        }
    }

    showPageWithChild(target) {
        this.pagination.open = true;

        for (let [i, child] of Object.entries(this.showableChildren())) {
            if (child.user.id_user === target.user.id_user) {
                this.pagination.page =
                    Math.floor(i / this.pagination.pageSize) + 1;
                break;
            }
        }

        this.updateVisibleChildren();
    }

    showCurrentPage() {
        this.pagination.open = true;
        this.updateVisibleChildren();
    }

    closeCurrentPage() {
        this.pagination.open = false;
        this.updateVisibleChildren();
    }

    isNumberOfDescendantsExceeding(threshold) {
        let count = 0;

        for (let descendant of dfs(this)) {
            if (descendant.isShowable()) {
                count += descendant.showableChildren().length;
            }

            if (count > threshold) {
                return true;
            }
        }

        return false;
    }

    filterByUsername(searchTerm) {
        this.searchTerm = searchTerm;
        this.updatePagination();
    }

    resetSearchFilter() {
        this.searchTerm = "";
        this.updatePagination();
    }
}

export class Node extends PaginatedNode {
    // Node arranged hierarchically.
    //
    // showable: Whether the node can be viewed. Could be that it is not toggled,
    //     or that it is inactive, and inactive nodes are hidden.
    // pagination: Determines which of the showable children that are currently
    //     viewed.
    // visibleChildren: Contains the list of nodes that are shown.
    //
    constructor(user, parent = null) {
        super(false);

        this.user = user;
        this.parent = parent;
    }

    hasUser() {
        return true;
    }

    isUserEditable() {
        return true;
    }

    isRoot() {
        return this.parent === null;
    }

    isExpandable() {
        return this.showableChildren().length > 0;
    }

    getAncestors() {
        if (!this.parent) {
            return [];
        }

        let ancestors = [];
        let lastAncestor = this.parent;

        while (true) {
            ancestors.unshift(lastAncestor);

            if (!lastAncestor.parent) {
                break;
            }

            lastAncestor = lastAncestor.parent;
        }

        return ancestors;
    }
}

const nodeCmp = (first, second) => {
    return first.user.username
        .toLowerCase()
        .localeCompare(second.user.username.toLowerCase());
};

export const openNode = (node) => {
    node.showCurrentPage();
    return node;
};

export const openNodeAttemptExpand = (node) => {
    if (node.pagination.pages > 1) {
        node.showCurrentPage();
    } else if (node.isNumberOfDescendantsExceeding(20)) {
        node.showCurrentPage();
    } else {
        for (let descendant of dfs(node)) {
            descendant.showCurrentPage();
        }
    }

    return node;
};

export const openNodeFocusingOnChild = (node, childInFocus) => {
    if (node.pagination.pages === 1) {
        node.showCurrentPage();
    } else {
        node.showPageWithChild(childInFocus);
    }

    return node;
};

export const ensureNodeIsVisible = (node, userNode) => {
    if (node.user.id_user === userNode.user.id_user) {
        return;
    }

    node.showPageWithChild(userNode);
};

export const closeNode = (root, skipIdUser = null) => {
    let stack = [root];

    while (stack.length > 0) {
        let node = stack.shift();

        if (skipIdUser !== null && node.user.id_user === skipIdUser) {
            continue;
        }

        node.closeCurrentPage();

        for (let child of node.children) {
            stack.push(child);
        }
    }

    return root;
};

const getUserMaps = (users) => {
    const userMap = {};
    const childrenMap = {};

    for (let user of users) {
        userMap[user.id_user] = user;

        if (!(user.id_user in childrenMap)) {
            childrenMap[user.id_user] = [];
        }

        if (user.owner > 0) {
            if (!(user.owner in childrenMap)) {
                childrenMap[user.owner] = [];
            }

            childrenMap[user.owner].push(user.id_user);
        }
    }

    return [userMap, childrenMap];
};

const findRootUsers = (userMap) => {
    let rootUsers = [];

    for (const user of Object.values(userMap)) {
        if (!(user.owner in userMap)) {
            rootUsers.push(user);
        }
    }

    return rootUsers;
};

export const buildTree = (users) => {
    let [userMap, childrenMap] = getUserMaps(users);
    let rootUsers = findRootUsers(userMap);

    if (rootUsers.length !== 1) {
        throw "Tree is not rooted";
    }

    let root = new Node(rootUsers[0]);
    let stack = [root];

    while (stack.length > 0) {
        let node = stack.shift();

        let children = childrenMap[node.user.id_user].map((id) => userMap[id]);
        node.addChildUsers(children);

        for (let child of node.children) {
            stack.push(child);
        }
    }

    return root;
};

const addNode = (tree, user) => {
    let owner = findUserNodeById(tree, user.owner);
    owner.addChildUser(user);
};

const moveNode = (tree, userNode, newIdOwner) => {
    // We might keep the top user and keep its parent too
    if (userNode.isRoot()) {
        if (userNode.user.owner === newIdOwner) {
            return;
        } else {
            throw "Something went wrong. Can not change owner of root.";
        }
    }

    let oldOwner = userNode.parent;
    let oldIdOwner = oldOwner.user.id_user;

    if (oldIdOwner === newIdOwner) {
        return;
    }

    let newOwner = findUserNodeById(tree, newIdOwner);
    let pop = oldOwner.popNodeById(userNode.user.id_user);
    newOwner.addChildNode(pop);
};

// Make a common user structure from the full user kind
const makeTreeUser = (user) => {
    return {
        id_user: user.id_user,
        username: user.username,
        is_active: user.is_active,
        owner: user.owner.id_user,
        customer: user.customer.name,
        user_type: _.cloneDeep(user.user_type),
    };
};

const updateNodeUser = (tree, fullUser) => {
    const userNode = findUserNodeById(tree, fullUser.id_user);
};

export const updateTree = (tree, fullUser) => {
    const user = makeTreeUser(fullUser);
    const userNode = findUserNode(tree, user);

    if (userNode) {
        moveNode(tree, userNode, user.owner);
        userNode.updateUser(user);
    } else {
        addNode(tree, user);
    }
};

export function* dfs(node, includeRoot = true) {
    let stack;
    if (includeRoot) {
        stack = [node];
    } else {
        stack = node.children.map((c) => c);
    }

    while (stack.length > 0) {
        let node = stack.shift();
        yield node;

        for (let child of node.children) {
            stack.push(child);
        }
    }
}

export const toggleActiveFilter = (tree) => {
    if (tree.activeFilter) {
        showInactive(tree);
    } else {
        hideInactive(tree);
    }
};

const showInactive = (tree) => {
    for (const node of Array.from(dfs(tree)).reverse()) {
        node.removeActiveFilter();
    }

    return tree;
};

const hideInactive = (tree) => {
    for (const node of Array.from(dfs(tree)).reverse()) {
        node.setActiveFilter();
    }

    return tree;
};

export const findUserNodeById = (tree, idUser) => {
    for (let node of dfs(tree)) {
        if (node.hasUser() && node.user.id_user === idUser) {
            return node;
        }
    }

    return null;
};

export const findUserNode = (tree, user) => {
    return findUserNodeById(tree, user.id_user);
};

export const isUserVisibleInTree = (tree, view, idUser) => {
    if (view.user.id_user === idUser) {
        return true;
    }

    const userNode = findUserNodeById(tree, idUser);

    if (!userNode) {
        return false;
    }

    if (
        userNode.parent &&
        userNode.parent
            .getVisibleChildren()
            .some((node) => node.user.id_user === idUser)
    ) {
        return true;
    }

    return false;
};
