<script setup lang="ts">
import type { ModuleAttribute } from "@/modules";
import { useModulesStore } from "@/stores/modules";
import { useDisplayStore } from "@/stores/display";
import AttributeInherit from "@/components/attributes/AttributeInherit.vue";
import AttributeWhoCanSet from "@/components/attributes/AttributeWhoCanSet.vue";
import ModuleToggle from "./ModuleToggle.vue";

const modulesStore = useModulesStore();
const display = useDisplayStore();

defineProps<{
    item: ModuleAttribute;
}>();

const defaultText = (moduleString: string) => {
    // Move to modules, parse into structured default objects
    return moduleString
        .slice(0, 3)
        .replace(/A/g, "Off, ")
        .replace(/C/g, "Restricted, ")
        .replace(/D/g, "On, ")
        .replace(/[\s,]*$/g, "");
};
</script>

<template>
    <div v-if="modulesStore.user" class="flex bt modules-container module">
        <div class="name">
            <span class="label">{{ display.nameOf(item.key) }}</span>
            <div class="default-container">
                <span
                    class="default-choice"
                    @click="modulesStore.setModuleState(item.key, item.default)"
                    >Default:
                    <span>{{ defaultText(item.default) }}</span>
                </span>
            </div>
        </div>

        <div class="flex-grow mt-0.25" :class="{ inherited: item.inherit }">
            <div class="flex justify-between -mt-0.5 px-0.5 py-0.5 bg-inherit">
                <ModuleToggle
                    :value="item.getActiveValue().normal"
                    :inherit="item.inherit"
                    @increment="
                        modulesStore.incrementModuleState(item.key, 'normal')
                    "
                    @decrement="
                        modulesStore.decrementModuleState(item.key, 'normal')
                    "
                />
                <ModuleToggle
                    :value="item.getActiveValue().admin"
                    :inherit="item.inherit"
                    @increment="
                        modulesStore.incrementModuleState(item.key, 'admin')
                    "
                    @decrement="
                        modulesStore.decrementModuleState(item.key, 'admin')
                    "
                />
                <ModuleToggle
                    :value="item.getActiveValue().super"
                    :inherit="item.inherit"
                    @increment="
                        modulesStore.incrementModuleState(item.key, 'super')
                    "
                    @decrement="
                        modulesStore.decrementModuleState(item.key, 'super')
                    "
                />
            </div>

            <div class="flex justify-between inherit-wrapper mt-0.5 mx-0.5">
                <AttributeInherit
                    :attribute="item"
                    @change="modulesStore.updateInherit(item.key)"
                />
                <AttributeWhoCanSet
                    :relative-id-user="modulesStore.user.id_user"
                    :attribute="item"
                    @update="
                        (v: number) => modulesStore.updateWhocanset([item.key, v])
                    "
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.module {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
}

.name {
    width: 280px;
}

.default-container {
    line-height: 1rem;
}

.inherit {
    width: 280px;
}

.inherit-wrapper {
    align-items: center;
    min-height: 1.9rem;
}
</style>
