<template>
    <section id="access">
        <h1 class="mx-2">Access to content</h1>

        <div class="mx-2">
            <ol class="flex">
                <TabItem
                    :active="isTabActive('access')"
                    @click="setActiveTab('access')"
                >
                    Standard
                </TabItem>
                <TabItem
                    :active="isTabActive('accessTeaser')"
                    @click="setActiveTab('accessTeaser')"
                >
                    Teaser
                </TabItem>
                <TabItem
                    :active="isTabActive('accessPay')"
                    @click="setActiveTab('accessPay')"
                >
                    Pay access
                </TabItem>
            </ol>
        </div>

        <div>
            <CanSetWrapper
                v-if="isTabActive('access')"
                :can-be-set="typeCanBeSet('access')"
            >
                <AccessTab :access="access" access-type="access" />
            </CanSetWrapper>
            <CanSetWrapper
                v-if="isTabActive('accessTeaser')"
                :can-be-set="typeCanBeSet('accessTeaser')"
            >
                <AccessTab :access="accessTeaser" access-type="accessTeaser" />
            </CanSetWrapper>
            <CanSetWrapper
                v-if="isTabActive('accessPay')"
                :can-be-set="typeCanBeSet('accessPay')"
            >
                <AccessTabPay :access="accessPay" :access-regular="access" />
            </CanSetWrapper>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import AccessTab from "./AccessTab.vue";
import AccessTabPay from "./AccessTabPay.vue";
import CanSetWrapper from "@/components/utils/CanSetWrapper.vue";
import TabItem from "@/components/utils/TabItem.vue";

export default {
    components: {
        AccessTab,
        AccessTabPay,
        CanSetWrapper,
        TabItem,
    },
    computed: {
        ...mapGetters("access", ["isTabActive", "typeCanBeSet"]),
        ...mapState("access", ["access", "accessTeaser", "accessPay"]),
    },
    methods: {
        ...mapMutations("access", ["setActiveTab"]),
    },
};
</script>
