<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";
import MicroModal from "micromodal";
import ModalWrapper from "@/components/utils/ModalWrapper.vue";

const store = useStore();

const loading = ref(false);

const send = async function () {
    loading.value = true;
    await store.dispatch("userDetails/sendPasswordResetEmail");
    loading.value = false;

    MicroModal.close("modal-password-reset-email");
};

const closeModal = () => {
    loading.value = false;
    MicroModal.close("modal-password-reset-email");
};
</script>

<template>
    <ModalWrapper modal-id="modal-password-reset-email">
        <section class="password-reset-wrapper">
            <h3 class="h2">Send password reset email</h3>

            <div class="py-1">
                <p>
                    This wil send a password reset email to
                    <strong>{{ store.state.userDetails.contact.email }}</strong>
                </p>
            </div>

            <div class="button-container-right">
                <button class="btn btn-underline" @click="closeModal">
                    Cancel
                </button>
                <button :disabled="loading" @click="send">Send</button>
            </div>
        </section>
    </ModalWrapper>
</template>

<style scoped>
.password-reset-wrapper {
    padding: 2rem;
}

h3 {
    margin-top: 0.5rem;
}

.button-container-right {
    margin-top: 0.5rem;
}
</style>
