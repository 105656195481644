<template>
    <li class="item">
        <slot />
    </li>
</template>

<style lang="scss" scoped>
.item {
    display: flex;
    border-radius: var(--border-radius-small);
    padding-left: 5px;
    margin-left: -5px;
    padding-right: 5px;
    margin-right: -5px;
    line-height: 1.8rem;
    color: var(--text);
}

.item:hover {
    background: var(--background-hover);
}
</style>
