<script setup lang="ts">
import HelpMenu from "./HelpMenu.vue";
import UserChange from "./UserChange.vue";
import UserSearch from "./UserSearch.vue";
import UserTree from "./UserTree.vue";
import { useStore } from "vuex";

const store = useStore();

defineProps<{
    isDisabled: boolean;
}>();
</script>

<template>
    <div v-if="store.state.sidebar.isLoading" class="sidebar mt-1 loading">
        <UserSearch :disabled="true" />
        <UserChange />
        <img src="/loading.gif" alt="Loading" />
    </div>
    <div v-else class="sidebar">
        <UserSearch :disabled="isDisabled" />
        <UserChange />
        <UserTree :disabled="isDisabled" />
        <HelpMenu />
    </div>
</template>

<style lang="scss" scoped>
.sidebar {
    margin-top: 1rem;
}

.loading img {
    width: 50px;
    margin: auto;
    margin-top: 5rem;
    display: block;
}

.sidebar > section {
    padding: 1.5rem;
    padding-bottom: 2rem;
}

.sidebar > section + section {
    @include border-top;
}
</style>
