<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { storeToRefs } from "pinia";
import { useCustomerStore } from "@/stores/customer";
import CustomerCurrentOptions from "./CustomerCurrentOptions.vue";
import FormInput from "@/components/utils/FormInput.vue";

const store = useStore();
const customerStore = useCustomerStore();

const { customer, state, errors, hasBeenModified } = storeToRefs(customerStore);

const displayName = computed(() => {
    return customerStore.customer?.name ?? "";
});

const displayId = computed(() => {
    return customerStore.customer?.id ?? 0;
});

const username = computed(() => {
    return store.state.userDetails.user.username;
});

const displayInfo = computed(() => {
    if (!customerStore.customer) {
        return "";
    }

    const numUsers = customerStore.customer.num_users;
    const username = store.state.user.username;

    if (numUsers <= 1) {
        return `Only ${username}`;
    } else if (numUsers === 2) {
        return `${username} and ${numUsers - 1} other`;
    } else {
        return `${username} and ${numUsers - 1} others`;
    }
});
</script>

<template>
    <section v-if="customer">
        <div class="user-create-content">
            <div class="title">
                <div id="name-and-id">
                    <h1 v-if="displayName.length > 0">{{ displayName }}</h1>
                    <h1 v-else>&nbsp;</h1>
                    <span> ID {{ displayId }} </span>
                </div>
                <span class="title__secondary">
                    {{ displayInfo }}
                </span>
            </div>

            <div>
                <div class="px-2 py-label w-2/3 form form-layout">
                    <FormInput
                        v-model="customer.name"
                        label="Name"
                        char-limit="255"
                        :error="errors?.fields?.name"
                    />
                    <FormInput
                        v-model="customer.partner"
                        label="Partner"
                        :error="errors?.fields?.partner"
                        type-name="number"
                    />

                    <FormInput
                        v-model="customer.max_num_users"
                        label="Maximum number of users"
                        :error="errors?.fields?.max_num_users"
                        placeholder="No limit"
                    />
                    <FormInput
                        v-model="customer.is_educational"
                        label="Educational"
                        :error="errors?.fields?.is_educational"
                        type-name="checkbox"
                    />

                    <FormInput
                        v-model="customer.is_active"
                        label="Active"
                        :error="errors?.fields?.is_active"
                        type-name="checkbox"
                    />
                    <FormInput
                        v-model="customer.is_opoint"
                        label="Is Opoint"
                        :error="errors?.fields?.is_opoint"
                        type-name="checkbox"
                    />
                    <FormInput
                        v-model="customer.salesforce_id"
                        label="Salesforce Account ID"
                        :error="errors?.fields?.salesforce_id"
                        placeholder="No ID"
                    />
                </div>

                <ErrorSection class="w-2/3" :message="errors?.general" />

                <SaveResetFooter
                    class="w-2/3"
                    :is-saving="state === 'saving'"
                    :is-modified="hasBeenModified"
                    @reset="customerStore.reset"
                    @save="customerStore.save"
                />
            </div>
        </div>

        <CustomerCurrentOptions
            :username="username"
            @show-new="store.commit('changePage', 'customerChangeNew')"
            @show-change="store.commit('changePage', 'customerChangeExisting')"
        />
    </section>
</template>

<style scoped>
#name-and-id {
    display: flex;
    justify-content: space-between;
}
</style>
