<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import type { Attribute } from "@/attributes";
import type { Option } from "@/config";

const props = defineProps<{
    attribute: Attribute<string>;
    options: readonly Option[];
}>();

const emit = defineEmits<{
    (e: "update", value: string): void;
}>();

const choices = computed(() => {
    let value = parseInt(props.attribute.getActiveValue());
    let options = _.cloneDeep(props.options);

    return options.map((o) => {
        return {
            label: o.label,
            value: o.value,
            set: value === o.value,
        };
    });
});
</script>

<template>
    <div class="-mt-0.5 py-0.5">
        <div
            v-for="choice in choices"
            :key="choice.value"
            class="checkbox-container--plain"
            :class="{
                disabled: attribute.inherit,
            }"
        >
            <label>
                <input
                    type="radio"
                    :disabled="attribute.inherit"
                    :checked="choice.set"
                    @input="emit('update', choice.value.toString())"
                />{{ choice.label }}
            </label>
        </div>
    </div>
</template>
