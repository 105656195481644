<script setup lang="ts">
import UserEntry from "./UserEntry.vue";
import UserListContainer from "./UserListContainer.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = withDefaults(
    defineProps<{
        node: any;
        disabled: boolean;
        showEntry?: boolean;
        showEmptyMessage?: boolean;
        parentShowControlWrapper?: boolean;
    }>(),
    {
        showEntry: true,
        showEmptyMessage: false,
    }
);

const showControlWrapper = computed(() => {
    if (props.parentShowControlWrapper) {
        return true;
    }

    if (props.showEntry) {
        return props.node.isExpandable();
    } else {
        // @ts-ignore
        return props.node.getVisibleChildren().some((n) => n.isExpandable());
    }
});

const toggleIcon = computed(() => {
    return props.node.visibleChildren.length > 0
        ? "chevron-down"
        : "chevron-right";
});

const hasNewUser = computed(() => {
    return (
        store.state.page === "newUser" &&
        store.state.user.id_user === props.node.user.id_user
    );
});

const toggleChildren = () => {
    store.commit("sidebar/toggleChildren", props.node);
};
</script>

<template>
    <div class="tree__node" :class="{ 'tree__node--child': showEntry }">
        <UserEntry v-if="showEntry" :node="node" :disabled="disabled">
            <template #controls>
                <div
                    v-if="showControlWrapper"
                    class="sidebar__controls-wrapper"
                >
                    <button
                        v-if="node.isExpandable()"
                        class="subtree-toggle"
                        @click="toggleChildren"
                    >
                        <BaseIcon :variant="toggleIcon" weight="bold" />
                    </button>
                </div>
            </template>
        </UserEntry>

        <UserListContainer
            :node="node"
            :show-empty-message="showEmptyMessage"
            :has-new-user="hasNewUser"
        >
            <template v-if="hasNewUser" #new-user>
                <div class="sidebar__user-entry">
                    <div
                        v-if="showControlWrapper"
                        class="sidebar__controls-wrapper"
                    ></div>
                    <span class="sidebar__username disabled -focus">
                        {{ "New user" }}
                    </span>
                </div>
            </template>

            <template #child="{ child }">
                <UserTreeNode
                    :disabled="disabled"
                    :node="child"
                    :parent-show-control-wrapper="showControlWrapper"
                />
            </template>
        </UserListContainer>
    </div>
</template>

<style lang="scss" scoped>
.subtree-toggle {
    color: var(--text);
    border: none;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not([disabled]):hover {
        background: hsl(210deg 10% 94%);
    }

    &:not([disabled]):active {
        background: hsl(210deg 10% 94%);
    }
}
</style>
