export interface UserSearchListEntry {
    label: string;
    value: string;
    user: any;
}

export const userToSearchListEntry = (user: any): UserSearchListEntry => {
    return {
        label: `${user.username} (${user.id_user}) - ${user.customer}`,
        value: user.username,
        user: user,
    };
};

export const isSearchTermId = (searchTerm: string): boolean => {
    const parsed = parseInt(searchTerm);

    if (isNaN(parsed)) {
        return false;
    }

    return true;
};
