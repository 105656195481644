<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{
    node: any;
    disabled: boolean;
}>();

const userTypeIdentifier = computed(() => {
    switch (props.node.user.user_type.value) {
        case 1:
            return "O";
        case 2:
            return "I";
        case 3:
            return "R";
        default:
            return "";
    }
});

const inFocus = computed(() => {
    return (
        store.state.sidebar.user.id_user === props.node.user.id_user &&
        store.state.page !== "newUser"
    );
});

const changeUser = () => {
    if (!props.disabled) {
        store.dispatch("updateToUser", props.node.user.id_user);
    }
};
</script>

<template>
    <div
        class="sidebar__user-entry"
        :class="{ inactive: !node.user.is_active }"
    >
        <slot name="controls" />

        <span v-if="inFocus" class="sidebar__username -focus">{{
            store.state.sidebar.user.username
        }}</span>
        <span
            v-else
            class="sidebar__username"
            :class="{ disabled: disabled }"
            @click="changeUser"
            >{{ node.user.username }}</span
        >

        <div v-if="userTypeIdentifier.length > 0" class="short-info">
            {{ userTypeIdentifier }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.short-info {
    box-shadow: 0 0 2px #eee;
    background: hsl(210deg 10% 97%);
    color: $color-button;
    border: none;
    font-weight: 600;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: var(--border-radius);
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin-left: 0.25rem;
}
</style>
