<template>
    <div v-if="isSaving" class="footer bt flex justify-between items-center">
        <span class="footer__save-text">Saving...</span>
    </div>
    <div v-else class="footer bt flex justify-between items-center">
        <slot></slot>

        <div class="footer__save button-container">
            <button
                class="btn btn-save"
                type="button"
                :disabled="isDisabled"
                @click="$emit('save')"
            >
                {{ saveText }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaveFooter",
    props: {
        isSaving: {
            type: null,
            default: false
        },
        isDisabled: {
            type: null,
            default: false
        },
        saveText: {
            type: null,
            default: "Save"
        }
    },
    emits: ["save"]
};
</script>
