<script setup lang="ts">
import { onMounted, ref } from "vue";
import api from "@/api";

defineProps<{
    username?: string;
}>();

const emit = defineEmits<{
    (e: "logout"): void;
}>();

const canRoot = ref(false);

onMounted(async () => {
    canRoot.value = await api.auth.canRoot();
});
</script>

<template>
    <header>
        <div class="logo">Opoint</div>
        <div class="login-info">
            <div v-if="username" class="user">Logged in as {{ username }}</div>
            <button
                v-if="canRoot && !api.auth.isImpersonating"
                class="btn btn-narrow btn-header"
                @click="() => api.auth.toRoot()"
            >
                Switch to root
            </button>
            <button
                v-else-if="api.auth.isImpersonating"
                class="btn btn-narrow btn-header"
                @click="() => api.auth.fromRoot()"
            >
                Return to self
            </button>
            <button class="btn btn-narrow btn-header" @click="emit('logout')">
                Logout
            </button>
        </div>
    </header>
</template>

<style lang="scss" scoped>
header {
    background: var(--header-background);
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
}

.logo {
    color: var(--header-text);
    font-weight: 600;
    font-size: $font-size-md;
    font-family: $font-family-logo;
}

.login-info {
    display: flex;
    align-items: center;
}

.user {
    margin-right: 1rem;
    color: var(--header-text);
    font-weight: 600;
    font-size: $font-size-sm;
    font-style: italic;
}
.btn {
    margin-left: 0.5rem;
}
</style>
