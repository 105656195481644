export const parseErrors = (rawErrors, map) => {
    const errors = {};

    for (const [name, value] of Object.entries(rawErrors)) {
        if (map[name]) {
            errors[map[name]] = value[0];
        }
    }

    return errors;
}
