<template>
    <div v-if="isSaving" class="footer bt flex justify-between items-center">
        <span class="footer__save-text">Saving...</span>
    </div>
    <div v-else class="footer bt flex justify-between items-center">
        <slot></slot>

        <div class="footer__save button-container">
            <button
                v-if="!hideReset"
                class="btn btn-underline -left"
                type="button"
                :disabled="!isModified"
                @click="$emit('reset')"
            >
                Reset
            </button>
            <button
                class="btn btn-save"
                type="button"
                :disabled="isDisabled"
                @click="$emit('save')"
            >
                {{ saveText }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaveResetFooter",
    props: {
        isSaving: {
            type: null,
            required: true,
        },
        isModified: {
            type: null,
            required: true,
        },
        hideReset: {
            type: null,
            default: null,
        },
        alwaysEnabled: {
            type: null,
            default: false,
        },
        saveText: {
            type: null,
            default: "Save",
        },
    },
    emits: ["reset", "save"],
    computed: {
        isDisabled() {
            if (this.alwaysEnabled) {
                return false;
            }

            return !this.isModified;
        },
    },
};
</script>
