import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { UserDetail } from "@/api";

// For now, a helper to migrate to pinia, to have access to the
// current selected user, so stores don't get out of sync
export const useOverviewStore = defineStore("overview", () => {
    const user = ref<UserDetail | undefined>(undefined);

    const idUser = computed(() => {
        return user.value?.id_user;
    });

    const updateUser = (updatedUser: UserDetail) => {
        user.value = updatedUser;
    };

    return {
        idUser,
        updateUser,
    };
});
