import {
    buildTree,
    dfs,
    updateTree,
    findUserNode,
    findUserNodeById,
    openNode,
    openNodeAttemptExpand,
    openNodeFocusingOnChild,
    ensureNodeIsVisible,
    closeNode,
    toggleActiveFilter,
    isUserVisibleInTree,
} from "@/tree.js";

const updateViewModuleState = (state, user) => {
    const userNode = findUserNode(state.tree, user);

    if (!state.view) {
        state.view = getViewNode(userNode);
        openNodeAttemptExpand(state.view);
    } else if (isUserVisibleInTree(state.tree, state.view, user.id_user)) {
        // state.view = state.view;
        openNode(userNode);
        ensureNodeIsVisible(state.view, userNode);
    } else {
        closeNode(state.view, user.id_user);
        state.view = getViewNode(userNode);
        openNodeAttemptExpand(state.view);
        ensureNodeIsVisible(state.view, userNode);
    }
};

const updateModuleState = (state, user) => {
    state.user = user;

    if (state.tree) {
        updateViewModuleState(state, user);
    }

    return state;
};

const getters = {
    isRoot: (state) => {
        if (!state.tree) {
            return false;
        }

        return findUserNode(state.tree, state.user).isRoot();
    },
};

const mutations = {
    updateUser: (state, { user }) => {
        updateModuleState(state, user);
    },
    setUsers: (state, users) => {
        state.tree = buildTree(users);
        updateViewModuleState(state, state.user);
        state.isLoading = false;
    },
    viewFromCurrentUser: (state) => {
        if (state.view.user.id_user !== state.user.id_user) {
            state.view = findUserNode(state.tree, state.user);
        }
    },
    toggleActiveFilter: (state) => {
        toggleActiveFilter(state.tree);
    },
    toggleChildren: (state, node) => {
        if (node.pagination.open > 0) {
            node.closeCurrentPage();
        } else {
            openNode(node);
        }
    },
    updateTree: (state, fullUser) => {
        updateTree(state.tree, fullUser);
    },
    moveUp: (state) => {
        if (!state.view.isRoot()) {
            state.view = openNodeFocusingOnChild(state.view.parent, state.view);
        }
    },
    previousPage: (state, node) => {
        node.moveToPreviousPage();
    },
    nextPage: (state, node) => {
        node.moveToNextPage();
    },
};

const getViewNode = (node) => {
    // We show more context if we are the bottom, thus without any children.
    let viewNode = node;

    if (node.children.length === 0 && node.parent) {
        viewNode = node.parent;
    }

    return viewNode;
};

export default {
    namespaced: true,
    state: {
        isLoading: true,
    },
    getters,
    mutations,
};
