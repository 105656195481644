<script lang="ts">
export type ContactDetails = {
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    description: string;
    password: string;
    displayName: string;
};
</script>

<script setup lang="ts">
import { ref } from "vue";
import ErrorSection from "@/components/utils/ErrorSection.vue";
import ErrorMessage from "@/components/utils/ErrorMessage.vue";
import FormInput from "@/components/utils/FormInput.vue";
import SaveFooter from "@/components/utils/SaveFooter.vue";
import type { FormErrors } from "@/forms";

withDefaults(
    defineProps<{
        customerName: string;
        isSaving: boolean;
        errors?: FormErrors;
        cancelText?: string;
    }>(),
    {
        cancelText: "Cancel",
    }
);

const username = ref("");
const email = ref("");
const firstName = ref("");
const lastName = ref("");
const description = ref("");
const password = ref("");
const displayName = ref("");

const emit = defineEmits<{
    (e: "cancel"): void;
    (e: "submit", payload: ContactDetails): void;
}>();

function submit(): void {
    emit("submit", {
        username: username.value,
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        description: description.value,
        password: password.value,
        displayName: displayName.value,
    });
}
</script>

<template>
    <section>
        <div class="user-create-content">
            <div class="title">
                <h1>New user</h1>
                <span class="title__secondary">
                    {{ customerName }}
                </span>
            </div>
            <div class="px-2 py-label w-2/3 form form-layout">
                <FormInput
                    v-model="username"
                    label="Username"
                    char-limit="255"
                    :error="errors?.fields?.username"
                />
                <FormInput
                    v-model="displayName"
                    label="Display name"
                    char-limit="255"
                    :error="errors?.fields?.display_name"
                />

                <FormInput
                    v-model="firstName"
                    label="First name"
                    char-limit="100"
                    :error="errors?.fields?.first_name"
                />
                <FormInput
                    v-model="lastName"
                    label="Last name"
                    char-limit="100"
                    :error="errors?.fields?.last_name"
                />

                <FormInput
                    v-model="email"
                    label="Email"
                    char-limit="100"
                    :error="errors?.fields?.email"
                />
                <FormInput
                    v-model="description"
                    label="Description"
                    type-name="textarea"
                    char-limit="200"
                    :error="errors?.fields?.descr"
                />

                <FormInput
                    v-model="password"
                    label="Password"
                    type-name="password"
                    :error="errors?.fields?.password"
                />
            </div>

            <ErrorSection class="w-2/3" :message="errors?.general" />
        </div>

        <SaveFooter
            class="w-2/3"
            save-text="Create the new user"
            :is-saving="isSaving"
            @save="submit"
        >
            <button class="btn btn-underline -left" @click="emit('cancel')">
                {{ cancelText }}
            </button>
        </SaveFooter>
    </section>
</template>
