<template>
    <div class="site-search-container">
        <div class="site-list-container">
            <div class="input-group">
                <label for="added-site-search">Individual sites</label>
                <input
                    id="addded-site-search"
                    v-model="siteListSearch"
                    :placeholder="addedSitesSearchPlaceholder"
                    :disabled="disabledSitesSearch"
                    type="text"
                />
            </div>
            <ul v-if="filteredSiteList.length > 0" class="site-list">
                <li
                    v-for="site in filteredSiteList"
                    :key="site"
                    class="checkbox-container--spread"
                    :class="{
                        disabled: disabled,
                    }"
                    @click.prevent="toggleSite(site.id_site)"
                >
                    <label class="site-name">{{ site.getLabel() }}</label>
                    <div class="checkbox-alignment">
                        &#8203;
                        <input
                            type="checkbox"
                            :disabled="disabled"
                            :checked="isPayEnabled(site.id_site)"
                        />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import siteCache from "@/siteCache";

export default {
    props: ["access", "accessRegular", "disabled"],
    data: function () {
        return {
            siteData: {},
            siteSearch: "",
            siteListSearch: "",
        };
    },
    computed: {
        siteList() {
            return this.accessRegular.getActiveValue().sites;
        },
        filteredSiteList() {
            let searchTerm = this.siteListSearch.trim().toLowerCase();

            return this.siteList
                .map((idSite) => {
                    if (this.siteData[idSite]) {
                        return this.siteData[idSite];
                    } else {
                        return siteCache.getDummy(idSite, "");
                    }
                })
                .filter((siteData) => {
                    return siteData
                        .getLabel()
                        .trim()
                        .toLowerCase()
                        .includes(searchTerm);
                });
        },
        disabledSitesSearch() {
            return (
                this.access.inherit ||
                this.accessRegular.value.sites.length == 0
            );
        },
        addedSitesSearchPlaceholder() {
            if (this.access.inherit) {
                return "";
            }

            if (this.accessRegular.value.sites.length == 0) {
                return "";
            }

            return "Filter list";
        },
        siteSearchPlaceholder() {
            if (this.access.inherit) {
                return "";
            }

            return "Search";
        },
    },
    watch: {
        access: {
            handler() {
                siteCache.requestMany(this.siteList).then((data) => {
                    data.map((siteData) => {
                        this.siteData[siteData.id_site] = siteData;
                    });
                });
            },
            deep: true,
        },
    },
    created() {
        siteCache.requestMany(this.siteList).then((data) => {
            data.map((siteData) => {
                this.siteData[siteData.id_site] = siteData;
            });
        });
    },
    methods: {
        isPayEnabled(idSite) {
            return (
                this.access.getActiveValue().sites.filter((i) => i == idSite)
                    .length > 0
            );
        },
        toggleSite(idSite) {
            if (!this.disabled) {
                this.$store.commit("access/toggleSite", ["accessPay", idSite]);
            }
        },
    },
};
</script>
