<script setup lang="ts">
import { computed } from "vue";
import feather from "feather-icons";

type IconVariant =
    | "alert-triangle"
    | "arrow-up-right"
    | "chevron-down"
    | "chevron-left"
    | "chevron-right"
    | "chevron-up"
    | "chevrons-up"
    | "file-plus"
    | "file-text"
    | "globe"
    | "sliders"
    | "tv"
    | "user"
    | "users";

type IconWeight = "normal" | "bold";

const props = withDefaults(
    defineProps<{
        variant: IconVariant;
        weight?: IconWeight;
    }>(),
    {
        weight: "normal",
    }
);

const iconSVG = computed(() => {
    let strokeWidth = props.weight === "bold" ? "2.1" : "1.7";

    return feather.icons[props.variant].toSvg({
        width: "0.9em",
        height: "0.9em",
        "stroke-width": strokeWidth,
        class: "flex",
    });
});
</script>

<template>
    <span v-html="iconSVG"></span>
</template>
