import type { CreateOption, CustomerOption } from "@/api";

export const findDefaultOption = (
    options: CreateOption[]
): CreateOption | null => {
    let allowedOptions = options.filter((c) => c.user_type_allowed);

    if (allowedOptions.length !== 1) {
        return null;
    }

    return allowedOptions[0];
};

export const findDefaultCustomerOption = (
    option: CreateOption | null
): CustomerOption | null => {
    if (!option) {
        return null;
    }

    let allowedCustomerOptions = option.customer_options.filter(
        (c) => c.option_allowed
    );

    if (allowedCustomerOptions.length === 1) {
        return allowedCustomerOptions[0];
    }

    return null;
};

export const hasOptions = (options: CreateOption[]): boolean => {
    return false;
};
