<script setup lang="ts">
import { computed } from "vue";
import { assertNever } from "@/utils";
import ModalWrapper from "@/components/utils/ModalWrapper.vue";
// @ts-ignore
import LicenseManual from "@/components/manuals/LicenseManual.md";
// @ts-ignore
import ModulesManual from "@/components/manuals/ModulesManual.md";

const props = defineProps<{
    manual: "licenses" | "modules";
}>();

const modalId = computed(() => {
    switch (props.manual) {
        case "licenses":
            return "modal-license-manual";
        case "modules":
            return "modal-modules-manual";
        default:
            assertNever(props.manual);
            return "";
    }
});

const component = computed(() => {
    switch (props.manual) {
        case "licenses":
            return LicenseManual;
        case "modules":
            return ModulesManual;
        default:
            assertNever(props.manual);
    }
});
</script>

<template>
    <ModalWrapper :modal-id="modalId">
        <div class="wrapper prose">
            <component :is="component" />
        </div>
    </ModalWrapper>
</template>

<style lang="scss" scoped>
.wrapper {
    padding: 3rem 5rem 5rem;
    width: 75ch;
    overflow-y: scroll;
}
</style>
