<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useModulesStore } from "@/stores/modules";
import ModuleSectionHeader from "./ModuleSectionHeader.vue";
import ModuleItem from "./ModuleItem.vue";

const modulesStore = useModulesStore();

const { modules, state, errors, hasBeenModified } = storeToRefs(modulesStore);
</script>

<template>
    <section>
        <div class="content-wrapper">
            <ModuleSectionHeader />

            <div class="px-2 pt-1">
                <ModuleItem
                    v-for="(item, key) in modules"
                    :key="key"
                    :item="item"
                />
            </div>

            <ErrorSection :message="errors?.general" />

            <SaveResetFooter
                :is-saving="state === 'saving'"
                :is-modified="hasBeenModified"
                @reset="modulesStore.reset"
                @save="modulesStore.save"
            />
        </div>
    </section>
</template>
