<script setup lang="ts">
import type { MigrationState } from '../../api';
import { computed, ref } from 'vue';
import MicroModal from "micromodal";
import ModalWrapper from "@/components/utils/ModalWrapper.vue";

interface MigrateUserProps {
    state: MigrationState;
    status: String | null;
}

const props = defineProps<MigrateUserProps>();
const emit = defineEmits<{ (e: 'migrate'): void }>();
let showModal = ref(false);

const openModal = () => {
    showModal.value = true;
    MicroModal.show("modal-migrate", {
        disableFocus: false,
        disableScroll: true,
    });
}

const closeModal = () => {
    showModal.value = false;
    MicroModal.close("modal-migrate");
}

const clickable = computed(() => {
    return props.state.value === 2 || props.state.value === 4;
});

const buttonString = computed(() => {
    switch (props.state.value) {
        case 1:
            return 'Not applicable';
        case 2:
            return 'Partially migrated';
        case 3:
            return 'Already migrated';
        case 4:
            return 'Migrate';
        default:
            return 'Not ready to migrate';
    }
})

const buttonClass = computed(() => {
    switch (props.state.value) {
        case 1:
            return 'not-applicable';
        case 2:
            return 'legacy-migrated';
        case 3:
            return 'already-migrated';
        case 4:
            return 'can-be-migrated';
        default:
            return 'incorrect-state';
    }
})

</script>

<template>
    <div v-if="props.state.value !== 1">
        <div class="migration-container">
            <h5>Migration</h5>

            <button id="migrate-button" :disabled="!clickable" :class="buttonClass" type="button" @click="openModal()">
                {{ buttonString }}
            </button>

        </div>
        <p v-if="props.status !== null">{{ props.status }}</p>
        <p class="migration-desc">{{ props.state.description }}</p>

        <ModalWrapper modal-id="modal-migrate">
            <section class="migrate-modal-wrapper form">
                <h3 class="h2">Migrate user</h3>

                <p>This will migrate the chosen user. This is permanent and nonreversible.</p>

                <div class="button-container-right">
                    <button class="btn btn-underline" @click="closeModal">
                        Cancel
                    </button>
                    <button id="modal-migrate-btn" @click="{ closeModal(); $emit('migrate') }">
                        Confirm
                    </button>
                </div>
            </section>
        </ModalWrapper>
    </div>
</template>

<style>
.migration-container {
    display: flex;
    justify-content: space-between;
}

.migration-desc {
    font-size: small;
    color: #444;
}

.migrate-modal-wrapper {
    padding: 2rem;
}

.button-container-right {
    margin-top: 2rem;
}

#migrate-button.not-applicable,
#migrate-button.incorrect-state {
    background-color: var(--red-600);
    color: #111;
    opacity: 0.8;
}

#migrate-button.already-migrated {
    background-color: var(--green-500);
    color: #111;
}

#migrate-button.legacy-migrated,
#migrate-button.can-be-migrated {
    background-color: var(--blue-500);
    color: white;
}

#migrate-button.legacy-migrated:hover,
#migrate-button.can-be-migrated:hover {
    background-color: var(--blue-600);
}

#modal-migrate-btn {
    background-color: var(--blue-500);
    color: white;
}

#modal-migrate-btn:hover {
    background-color: var(--blue-600);
}
</style>



