import _ from "lodash";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import api from "@/api";
import type { UserDetail } from "@/api";
import type { FormErrors } from "@/forms";
import { useOverviewStore } from "@/stores/overview";

export const useMiscStore = defineStore("misc", () => {
    const overview = useOverviewStore();

    // Tag of data used to avoid data races
    const user = ref<UserDetail | undefined>(undefined);

    const state = ref<"editing" | "saving">("editing");
    const errors = ref<FormErrors | undefined>(undefined);

    const translated = ref<string>("0");
    const quota = ref<string>("0");

    const original = ref<{ translated: string; quota: string }>({
        translated: translated.value,
        quota: quota.value,
    });

    const hasBeenModified = computed(() => {
        if (!original.value) {
            return false;
        }

        return !_.isEqual(
            {
                translated: translated.value,
                quota: quota.value,
            },
            original.value
        );
    });

    const updateUser = (updatedUser: UserDetail) => {
        user.value = updatedUser;
        translated.value = updatedUser.misc.GT_DATA.translated.toString();
        quota.value = updatedUser.misc.GT_DATA.quota.toString();

        original.value = {
            translated: translated.value,
            quota: quota.value,
        };

        state.value = "editing";
        errors.value = undefined;
    };

    const reset = () => {
        translated.value = original.value?.translated;
        quota.value = original.value?.quota;
        errors.value = undefined;
    };

    const save = async () => {
        if (!user.value) {
            return;
        }

        const idUser = user.value.id_user;

        // Sanity check that stores has not gotten out of sync
        if (overview.idUser !== idUser) {
            errors.value = {
                general: "Users out of sync. Please refresh.",
            };
            return;
        }

        state.value = "saving";
        errors.value = undefined;

        try {
            let updatedUser = await api.users.patchMisc(idUser, {
                GT_DATA: {
                    translated: translated.value,
                    quota: quota.value,
                },
            });

            if (user.value.id_user === idUser) {
                updateUser(updatedUser);
            }
        } catch (rawErrors) {
            errors.value = {
                general: "Error when saving",
            };
        }

        if (user.value.id_user === idUser) {
            state.value = "editing";
        }
    };

    return {
        user,
        translated,
        quota,
        hasBeenModified,
        state,
        errors,
        updateUser,
        reset,
        save,
    };
});
