<script setup lang="ts">
import { ref } from "vue";
import api, { getCreateCustomerPayload } from "@/api";
import type { CustomerDetail, CreateCustomerPayload } from "@/api";
import { parseGenericErrorResponse } from "@/forms";
import type { FormErrors } from "@/forms";
import CustomerNewForm from "@/components/forms/CustomerNewForm.vue";

const state = ref<"editing" | "saving">("editing");
const errors = ref<FormErrors | undefined>(undefined);

const emit = defineEmits<{
    (e: "newCustomer", customer: CustomerDetail): void;
    (e: "cancel"): void;
}>();

const save = async (customerData: CreateCustomerPayload) => {
    state.value = "saving";
    errors.value = undefined;

    try {
        const customer = await api.customers.post(
            getCreateCustomerPayload(customerData)
        );

        emit("newCustomer", customer);
    } catch (rawErrors) {
        state.value = "editing";
        errors.value = parseGenericErrorResponse(
            rawErrors,
            "Something went wrong creating the new customer",
            [
                "name",
                "partner",
                "is_educational",
                "is_opoint",
                "max_num_users",
                "salesforce_id",
            ]
        );
    }
};
</script>

<template>
    <CustomerNewForm
        :is-saving="state === 'saving'"
        :errors="errors"
        cancel-text="Go back"
        @cancel="emit('cancel')"
        @submit="save"
    />
</template>
