import type { GroupStructure } from "@/utils";

export const MODULES = [
    "PROFILE_MODULE",
    "ALERT_MODULE",
    "TAG_MODULE",
    "FEED_MODULE",
    "TRASH_MODULE",
    "REPORT_MODULE",
    "STATISTICS_MODULE",
    "CREATE_ARTICLE_MODULE",
    "SETTING_MODULE",
    "CONTACT_MODULE",
    "NEWSLETTER_EDITOR_MODULE",
    "SOURCE_LIST_MODULE",
    "TEMPLATE_EDITOR_MODULE"
] as const satisfies readonly string[];

export const LICENSE_GROUP_STRUCTURE = {
    groups: {
        article: {
            title: "Article",
            keys: [
                "NO_HEADER",
                "NO_SUMMARY",
                "NO_TEXT",
                "NO_QUOTES",
                "NO_ARTPIC",
                "NO_SCREENSHOT",
                "NO_BIG_ARTPIC",
                "NO_MATCHES",
                "NO_REDIR_LINK",
                "NO_TRANSCRIPTION",
                "NO_PDF",
                "NO_MEDIA_URLS",
            ],
        },
        meta: {
            title: "Meta data",
            keys: [
                "SIMILARWEB_MONTH_VISITS",
                "SIMILARWEB_MONTH_UVISITS",
                "SIMILARWEB_EXTENDED_DATA",
                "READERSHIP_DATA_SITE",
                "READERSHIP_DATA_ARTICLE",
                "TEXTRAZOR_DATA",
                "INFOMEDIA_SENTIMENT_DATA",
            ],
        },
        misc: {
            title: "Miscellaneous",
            keys: [],
        },
        search: {
            title: "Search",
            keys: [
                "NO_FREE_SEARCH",
                "STRICT_NO_FREE_SEARCH",
                "NO_SEARCH_BEFORE_PROFILE_START",
                "STRICT_COPYRIGHT_AGE",
                "SITE_ARCHIVE_AGE",
                "MAX_DATESITE_COUNT",
                "TEASER_OUTSIDE_BLESSED_PROFILE",
                "ARTICLE_DELAY_OVERRIDE",
            ],
        },
    },
    defaultGroup: "misc",
} as const satisfies GroupStructure;

export const ATTRIBUTE_GROUP_STRUCTURE = {
    groups: {
        misc: {
            title: "Misc",
            keys: [
                "ARTICLE_LAYOUT_PERMISSION",
                "NEW_PORTAL_MAIN_DOMAIN",
                "NEW_PORTAL_SUPPORT",
                "NEW_PORTAL_SEARCH_BAR",
                "NEW_PORTAL_FILTER_DRILLDOWN_ACCESS",
                "NEW_PORTAL_KPI_ACCESS",
                "NEW_PORTAL_STATISTICS_EXPORT_FORMATS",
                "NEW_PORTAL_MAX_PROFILES",
                "NEW_PORTAL_MAX_ALERTS",
                "NEW_PORTAL_MAX_ALERTS_RECIPIENTS",
                "NEW_PORTAL_SHOW_SENTIMENT",
            ],
        },
        search: {
            title: "Search",
            keys: [
                "OCG_SF_def_search",
                "MAX_SEARCH_LENGTH",
                "MIN_ARTICLE_AGE",
                "MAX_ARTICLE_AGE",
            ],
        },
        templates: {
            title: "Templates",
            keys: [
                "TEMPLATE_ACCESS",
                "TEMPLATE_SETTINGS_ACCESS",
                "NEW_PORTAL_DEFAULT_TEMPLATE",
                "INHERIT_TEMPLATES",
                "HIDE_DEFAULT_TEMPLATES",
            ],
        },
        folders: {
            title: "Folders",
            keys: [
                "DEFAULT_FOLDER_NAME_PROFILES",
                "DEFAULT_FOLDER_ICON_PROFILES",
                "DEFAULT_FOLDER_NAME_TAGS",
                "DEFAULT_FOLDER_ICON_TAGS",
                "DEFAULT_FOLDER_NAME_SENTIMENT_TAGS",
                "DEFAULT_FOLDER_ICON_SENTIMENT_TAGS",
                "DEFAULT_FOLDER_NAME_STATISTICS",
                "DEFAULT_FOLDER_ICON_STATISTICS",
            ],
        },
    },
    defaultGroup: "misc",
} as const satisfies GroupStructure;

export const EXCLUDE_FROM_ATTRIBUTES_PAGE = [
    "OCG_ACCESS_GROUP",
    "OCG_ACCESS_GROUP_teaser",
    "ACCESS_GROUP_PAY",
    "OCG_RL_license",
    "OCG_RL_license_enforce",
    "OCG_RL_license_teaser",
    "OCG_RL_license_enforce_teaser",
    "OCG_RL_license_login",
    "OCG_RL_license_enforce_login",
    ...MODULES,
] as const satisfies readonly string[];

export interface Option {
    readonly value: number;
    readonly label: string;
}

export const BIT_STRING_ATTRIBUTES: Record<string, readonly Option[]> = {
    ARTICLE_EDIT_ACCESS: [
        {
            value: 0,
            label: "Create print articles",
        },
        {
            value: 1,
            label: "Create broadcast articles",
        },
        {
            value: 2,
            label: "Create global articles",
        },
        {
            value: 3,
            label: "Edit manual fields",
        },
        {
            value: 4,
            label: "Grant article access",
        },
        {
            value: 5,
            label: "Edit translated header",
        },
        {
            value: 6,
            label: "Edit manual summary",
        },
        {
            value: 7,
            label: "Override author",
        },
    ],
    ARTICLE_LAYOUT_PERMISSION: [
        {
            value: 0,
            label: "Modern",
        },
        {
            value: 1,
            label: "Search engine",
        },
        {
            value: 2,
            label: "Email",
        },
        {
            value: 3,
            label: "Archive (articles to the right)",
        },
        {
            value: 4,
            label: "Archive (articles to the left)",
        },
        {
            value: 5,
            label: "Intro",
        },
    ],
    NEW_PORTAL_SUPPORT: [
        {
            value: 0,
            label: "Report an issue",
        },
        {
            value: 1,
            label: "Request additional data",
        },
        {
            value: 2,
            label: "Enable chat",
        },
    ],
    TEMPLATE_ACCESS: [
        {
            value: 1,
            label: "PDF",
        },
        {
            value: 3,
            label: "HTML",
        },
        {
            value: 7,
            label: "EXCEL",
        },
        {
            value: 8,
            label: "XML",
        },
        {
            value: 9,
            label: "WORD",
        },
        {
            value: 10,
            label: "PPT",
        },
        {
            value: 11,
            label: "RSS",
        },
        {
            value: 12,
            label: "FEED",
        },
    ],
    NEW_PORTAL_SHOW_SENTIMENT: [
        {
            value: 0,
            label: "View Company",
        },
        {
            value: 1,
            label: "Create/Edit entity groups",
        },
        {
            value: 2,
            label: "Manually override",
        },
        {
            value: 3,
            label: "Most popular",
        },
        {
            value: 4,
            label: "General",
        },
    ],
    TEMPLATE_SETTINGS_ACCESS: [
        {
            value: 0,
            label: "Excel analytics",
        },
        {
            value: 1,
            label: "Custom RSS",
        },
        {
            value: 2,
            label: "Custom branding",
        },
        {
            value: 3,
            label: "Curated fields",
        },
        {
            value: 4,
            label: "Include restricted alert settings",
        },
    ],
    NEW_PORTAL_STATISTICS_EXPORT_FORMATS: [
        {
            value: 0,
            label: "PDF",
        },
        {
            value: 1,
            label: "Powerpoint",
        },
        {
            value: 2,
            label: "Excel - static",
        },
        {
            value: 3,
            label: "Excel - dynamic",
        },
        {
            value: 4,
            label: "Raw data",
        },
    ],
};

const ON_OFF_OPTIONS = [
    {
        value: 0,
        label: "Off",
    },
    {
        value: 1,
        label: "On",
    },
] satisfies readonly Option[];

const YES_NO_OPTIONS = [
    {
        value: 0,
        label: "No",
    },
    {
        value: 1,
        label: "Yes",
    },
] satisfies readonly Option[];

export const CHOICE_ATTRIBUTES: Record<string, readonly Option[]> = {
    NEW_PORTAL_SEARCH_BAR: [
        {
            value: 0,
            label: "All-in-One",
        },
        {
            value: 1,
            label: "Separated",
        },
    ],
    NEW_PORTAL_MAIN_DOMAIN: [
        {
            value: 0,
            label: "m360.opoint.com",
        },
        {
            value: 1,
            label: "app.infomedia.no",
        },
        {
            value: 2,
            label: "app.infomedia.se",
        },
        {
            value: 3,
            label: "app.infomedia.dk",
        },
        {
            value: 5,
            label: "app.infomedia.fi",
        },
        {
            value: 4,
            label: "ecb.infomedia.eu",
        },
        {
            value: 6,
            label: "mia.opoint.com",
        },
    ],

};

export const SWITCH_ATTRIBUTES = [
    "NEW_PORTAL_KPI_ACCESS",
    "SIMILAR_SETTING_VISIBLE",
    "SHOW_COPYRIGHT_DISCLAIMER",
    "NON_PDF_REPORT_ACCESS",
    "DISPLAY_PRINT_PDF_LINK",
    "NEW_PORTAL_FILTER_DRILLDOWN_ACCESS",
    "INTERNAL_ORM",
    "USE_MAILJET",
    "CAN_ADD_TO_ALERT_BASKET",
    "PERSISTENT_PROFILE_ACCESS",
    "ENABLE_EDIT_ARTICLES",
    "ENABLE_REACH_ON_ARTICLES",
    "ENABLE_SINGLE_ARTICLE_MODE",
    "ENABLE_TEXT_SMS_ALERTS",
    "FOLDER_ADMIN",
    "LIMITED_ADD_CONTENT",
    "LIMITED_CREATE_PROFILES",
    "ORG_MAIL_FILTERING",
    "STATISTICS_ENABLE_EAC_AND_CIRCULATION",
    "SYNDICATES_ACCESS",
    "UPLOAD_ARTICLES_AS_CUSTOMER",
    "HIDE_DEFAULT_TEMPLATES",
    "ENABLE_EAC_ON_ARTICLE",
    "SHARE_HTML_ONLY",
    "DISPLAY_ARTICLE_COUNT",
];

export const USER_SEARCH_ATTRIBUTES = [
    "USER_INVITE_TARGETS",
    "INHERIT_ALERTS",
];
