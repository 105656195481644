
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><h1>Modules</h1>
<p>Modules are coarse-grained feature controls for the portal.</p>
<p>What set’s them apart from other attributes, many of which also control features in the portal, is the fact that the values can be different depending on whether the user is logged in normally, or as an admin or super admin.</p>
<p>An alternative to approach, which is gaining support in the system, and used by the biggest clients, is for each person to have their own separate login, always logging in normally, and set different module access for the logins that are supposed to have adminstrator rights than the more standard users.</p>
<h2>Values</h2>
<p>Generally speaking, each module has one out of three states:</p>
<ul>
<li>Off: The feature is hidden from the portal.</li>
<li>Restricted: Generally, it possible to view items, but not create, edit or delete them.</li>
<li>On: Every operation is permitted.</li>
</ul>
<p>To take a concrete example, if the profiles module is turned off, no profiles will be visible, and the user is not able to create any new ones. If the profile module is set to restricted, the user can view the profiles, but not modify, delete or create any new ones. When the the module is on, any profile related functionality is available.</p>
</div>
</template>
