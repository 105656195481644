<template>
    <div class="site-search-container">
        <div class="site-list-container">
            <div class="input-group">
                <label for="added-site-search">Individual sites</label>
                <input
                    id="addded-site-search"
                    v-model="siteListSearch"
                    :placeholder="addedSitesSearchPlaceholder"
                    :disabled="disabledSitesSearch"
                    type="text"
                />
            </div>
            <ul v-if="filteredSiteList.length > 0" class="site-list">
                <li v-for="site in filteredSiteList" :key="site">
                    <span class="site-name">{{ site.getLabel() }}</span>
                    <button
                        v-if="!access.inherit"
                        class="site-delete"
                        @click="
                            $store.commit('access/removeSite', [
                                accessType,
                                site.id_site,
                            ])
                        "
                    >
                        Delete
                    </button>
                </li>
            </ul>
        </div>
        <div class="site-search">
            <div class="input-group">
                <label for="site-search">Add new sites</label>
                <input
                    id="site-search"
                    v-model="siteSearch"
                    type="text"
                    :disabled="disabled"
                    :placeholder="siteSearchPlaceholder"
                />
            </div>
        </div>
    </div>
</template>

<script>
import "jquery-ui-dist/jquery-ui.min.js";
import api from "@/api";
import { invertAccessGroup } from "@/access";
import { isSearchTermId } from "@/search";
import siteCache from "@/siteCache";

export default {
    props: ["access", "disabled", "accessType"],
    data: function () {
        return {
            siteData: {},
            siteSearch: "",
            siteListSearch: "",
        };
    },
    computed: {
        siteList() {
            return this.access.getActiveValue().sites;
        },
        filteredSiteList() {
            let searchTerm = this.siteListSearch.trim().toLowerCase();

            return this.siteList
                .map((idSite) => {
                    if (this.siteData[idSite]) {
                        return this.siteData[idSite];
                    } else {
                        return siteCache.getDummy(idSite, "");
                    }
                })
                .filter((siteData) => {
                    return siteData
                        .getLabel()
                        .trim()
                        .toLowerCase()
                        .includes(searchTerm);
                });
        },
        disabledSitesSearch() {
            return this.access.inherit || this.access.value.sites.length == 0;
        },
        addedSitesSearchPlaceholder() {
            if (this.access.inherit) {
                return "";
            }

            if (this.access.value.sites.length == 0) {
                return "";
            }

            return "Filter list";
        },
        siteSearchPlaceholder() {
            if (this.access.inherit) {
                return "";
            }

            return "Search";
        },
    },
    watch: {
        access: {
            handler() {
                siteCache.requestMany(this.siteList).then((data) => {
                    data.map((siteData) => {
                        this.siteData[siteData.id_site] = siteData;
                    });
                });
            },
            deep: true,
        },
    },
    created() {
        siteCache.requestMany(this.siteList).then((data) => {
            data.map((siteData) => {
                this.siteData[siteData.id_site] = siteData;
            });
        });
    },
    mounted() {
        $("#site-search").autocomplete({
            minLength: 1,
            source: async (req, resp) => {
                let idSites;
                if (isSearchTermId(req.term)) {
                    idSites = [req.term];
                } else {
                    // Suggestion server returns results with sensible ranking
                    let suggestions = await api.suggest.search(
                        req.term,
                        invertAccessGroup(this.access.value.accessGroup)
                    );
                    // Suggestion server groups several sites under same entry
                    // Also means that the access filter does not apply to sites individually
                    idSites = suggestions.results
                        .map((s) => s.id.split(","))
                        .flat();
                }
                let siteData = await siteCache.requestMany(idSites);
                resp(
                    siteData.map((data) => {
                        return {
                            label: data.getLabel(),
                            idSite: data.id_site,
                        };
                    })
                );
            },
            select: async (event, ui) => {
                this.siteSearch = "";
                this.siteListSearch = "";
                this.$store.commit("access/addSite", [
                    this.accessType,
                    ui.item.idSite,
                ]);
            },
        });
    },
};
</script>
