<script setup lang="ts">
import UserSearchField from "@/components/utils/UserSearchField.vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{
    disabled: boolean;
}>();

function updateUser(idUser: number): void {
    if (!props.disabled) {
        store.dispatch("updateToUser", idUser);
    }
}
</script>

<template>
    <section>
        <h3 class="user-search-header h4">User search</h3>
        <UserSearchField :disabled="disabled" @change="updateUser" />
    </section>
</template>

<style scoped>
.user-search-header {
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
}
</style>
