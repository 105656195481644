<template>
    <div class="content-wrapper">
        <div v-if="userSummarizerParam" class="flex flex-wrap flex-col gap-2 mx-2">
            <p style="margin: 0; line-height: normal;">
                These settings modify the fields in the user summarizer parameter.
            </p>
            <p style="margin: 0; line-height: normal;">
                This controls what content the user will see in their shareable searches.
            </p>
            <div class="px-2 py-1 flex">
                <div class="name">
                    <label class="label ml-0">Type</label>
                    <div class="default-container">
                        <span class="gray-text pr-1 small italic">
                            Extract selects words around hightlights.
                            Summary selects the first words.
                        </span>
                    </div>
                </div>
                <div class="flex-grow">
                    <div class="value mr-0.5 inherited">
                        <div class="px-0.5 py-0.25">
                            <select 
                                v-model.number="userSummarizerParam.type" 
                                class="border-box-full-width"
                                @change="mapAndUpdateValue"
                            >
                                <option value="0">None</option>
                                <option value="1">Extract</option>
                                <option value="2">Summary</option>
                                <!-- <option value="3">Summary and Extract</option> -->
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-2 py-1 flex">
                <div class="name">
                    <label class="label ml-0">Number of sentences</label>
                    <div class="default-container">
                        <span class="gray-text pr-1 small italic">
                            Limit the amount of sentences returned.
                        </span>
                    </div>
                </div>
                <div class="flex-grow">
                    <div class="value mr-0.5 inherited">
                        <div class="px-0.5 py-0.25">
                            <input 
                                v-model.number="userSummarizerParam.number_of_sentences"
                                type="number"
                                class="border-box-full-width"
                                :min="0"
                                :max="255"
                                @change="mapAndUpdateValue"
                                @keyup.enter="mapAndUpdateValue"
                                >
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-2 py-1 flex">
                <div class="name">
                    <label class="label ml-0">Max percentage of character to show</label>
                    <div class="default-container">
                        <span class="gray-text pr-1 small italic">
                            Percentage of characters to show, will vary by article length.
                        </span>
                    </div>
                </div>
                <div class="flex-grow">
                    <div class="value mr-0.5 inherited">
                        <div class="px-0.5 py-0.25">
                            <input 
                                v-model.number="userSummarizerParam.max_percentage_of_characters_to_show"
                                type="number" 
                                class="border-box-full-width"
                                :min="0"
                                :max="100"
                                @change="mapAndUpdateValue"
                                @keyup.enter="mapAndUpdateValue"
                                >
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-2 py-1 flex">
                <div class="name">
                    <label class="label ml-0">Max number of words to show</label>
                    <div class="default-container">
                        <span class="gray-text pr-1 small italic">
                            Max number of words to show, up to 4095.
                        </span>
                    </div>
                </div>
                <div class="flex-grow">
                    <div class="value mr-0.5 inherited">
                        <div class="px-0.5 py-0.25">
                            <input 
                                v-model.number="userSummarizerParam.max_number_of_words_to_show" 
                                type="number"
                                class="border-box-full-width"
                                :min="0"
                                :max="255"
                                @change="mapAndUpdateValue"
                                @keyup.enter="mapAndUpdateValue"
                                >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SaveResetFooter 
            :is-saving="isSaving" 
            :is-modified="hasTypeBeenModified(licenseType)" 
            @reset="resetLicense"
            @save="saveLicense" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { decodeFromSummarizerParam, encodeToSummarizerParam } from "@/licenses";

export default {
    components: {

    },
    props: ["license", "licenseType"],
    computed: {
        ...mapState(["user"]),
        ...mapGetters("licenses", ["hasTypeBeenModified"]),
        ...mapState("licenses", {
            userSummarizerParam(state) {
                return decodeFromSummarizerParam(state.licenseUser.regular?.value ?? 0);
            },
            errorMessage(state) {
                return state.errorMessage[this.licenseType];
            },
            isSaving(state) {
                return state.isSaving[this.licenseType];
            },
        }),
        isActiveSubTabInherited() {
            return this.license[this.activeSubTab].inherit;
        },
    },
    methods: {
        ...mapMutations("licenses", ["updateInherit", "reset", "setValue"]),
        ...mapActions("licenses", ["save"]),
        resetLicense() {
            this.reset(this.licenseType);
        },
        saveLicense() {
            this.save(this.licenseType);
        },
        isSubTabDisabled(tabType) {
            return this.$store.getters["licenses/isSubTabDisabled"](
                this.license,
                tabType
            );
        },
        subTabText(tabType, text) {
            if (this.isSubTabDisabled(tabType)) {
                return `${text} (not allowed to change)`;
            }

            return text;
        },
        isActiveSubTab(tabType) {
            return this.activeSubTab === tabType;
        },
        changeSubTab(tabType) {
            if (!this.isSubTabDisabled(tabType)) {
                this.$store.commit("licenses/setActiveSubTab", [
                    this.licenseType,
                    tabType,
                ]);
            }
        },
        mapAndUpdateValue() {
            const before = this.userSummarizerParam;
            const after = encodeToSummarizerParam(before);
            
            this.setValue([this.licenseType, after]);
        },
    },
};
</script>

<style scoped>
.attribute {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
}

.name {
    width: 280px;
}

.default-container {
    line-height: 1rem;
}

.inherit {
    width: 280px;
}

.inherit-wrapper {
    min-height: 1.9rem;
}

.value {
    width: 280px;
}
.gray-text {
    color: #999;
}
.small {
    font-size: 0.9rem;
}
.italic {
    font-style: italic;
}
</style>
