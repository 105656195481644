<script setup lang="ts">
import { computed } from "vue";
import { BIT_STRING_ATTRIBUTES, CHOICE_ATTRIBUTES, USER_SEARCH_ATTRIBUTES, SWITCH_ATTRIBUTES } from "@/config";
import type { Attribute } from "@/attributes";
import { useAttributesStore } from "@/stores/attributes";
import { useDisplayStore } from "@/stores/display";
import AttributeValue from "./AttributeValue.vue";
import AttributeValueBitString from "./AttributeValueBitString.vue";
import AttributeValueChoice from "./AttributeValueChoice.vue";
import AttributeValueSwitch from "./AttributeValueSwitch.vue";
import AttributeValueUserSelectWrapper from "./AttributeValueUserSelectWrapper.vue";
import AttributeInherit from "./AttributeInherit.vue";
import AttributeWhoCanSet from "./AttributeWhoCanSet.vue";

const attributesStore = useAttributesStore();
const display = useDisplayStore();

const props = defineProps<{
    attribute: Attribute<any>;
    error: undefined | string;
}>();

const bitStringOptions = computed(() => {
    return BIT_STRING_ATTRIBUTES[props.attribute.key];
});

const choiceOptions = computed(() => {
    return CHOICE_ATTRIBUTES[props.attribute.key];
});

const switchOptions = computed(() => {
    return SWITCH_ATTRIBUTES.includes(props.attribute.key);
});

const userOptions = computed(() => {
    return USER_SEARCH_ATTRIBUTES.includes(props.attribute.key);
});

const defaultText = computed(() => {
    if (props.attribute.default === "") {
        if (USER_SEARCH_ATTRIBUTES.includes(props.attribute.key)) {
            return "No target users.";
        }
        return "empty string";
    }
    if (SWITCH_ATTRIBUTES.includes(props.attribute.key)) {
        return props.attribute.default == 1 ? "Active" : "Inactive";
    }

    return props.attribute.default;
});

const updateValue = (value: any) => {
    console.log("updateValue: " + value);
    attributesStore.updateValue([props.attribute.key, value]);
};
</script>

<template>
    <div v-if="attributesStore.user" class="attribute flex bt flex-row">
        <div class="name">
            <label class="label ml-0">{{
                display.nameOf(attribute.key)
            }}</label>
            <div class="flex justify-between defaults mb-0.5">
                <AttributeInherit
                    class="inherit"
                    :attribute="attribute"
                    @change="attributesStore.updateInherit(attribute.key)"
                />
            </div>

        </div>

        <div class="flex-grow">
            <div class="value mr-0.5" :class="{ inherited: attribute.inherit }">
                <AttributeValueBitString
                    v-if="bitStringOptions"
                    :attribute="attribute"
                    :options="bitStringOptions"
                    @update="updateValue"
                />
                <AttributeValueChoice
                    v-else-if="choiceOptions"
                    :attribute="attribute"
                    :options="choiceOptions"
                    @update="updateValue"
                />

                <AttributeValueSwitch
                    v-else-if="switchOptions"
                    :attribute="attribute"
                    @update="updateValue"
                />

                <AttributeValueUserSelectWrapper
                    v-else-if="userOptions"
                    :attribute="attribute"
                    @update="updateValue"
                />

                <AttributeValue
                    v-else
                    :attribute="attribute"
                    @update="updateValue"
                />
            </div>

            <div
                class="inherit-wrapper flex justify-between items-center mt-0.5 ml-0.5"
            >
                <AttributeWhoCanSet
                    :relative-id-user="attributesStore.user.id_user"
                    :attribute="attribute"
                    @update="
                        (v: number) =>
                        attributesStore.updateWhocanset([attribute.key, v])
                    "
                />
            </div>

            <ErrorMessage :message="error" :icon="true" class="mx-0.5 mt-0.5" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.attribute {
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
}

.name {
    width: 280px;
}

.default-container {
    line-height: 1rem;
}

.inherit {
    width: 180px;
}

.inherit-wrapper {
    min-height: 1.9rem;
}

.value {
    width: 280px;
}
</style>
