<script setup lang="ts">
import { computed } from "vue";
import type { Attribute, Lineage } from "@/attributes";

// Todo: narrow to lineage interface instead of full attribute
const props = withDefaults(
    defineProps<{
        attribute: Attribute<any>;
        disabled: boolean;
    }>(),
    { disabled: false }
);

const isDisabled = computed(() => {
    return props.disabled || !props.attribute.canBeSet();
});

const inheritLineage = computed(() => {
    let lineage: (Lineage | null)[] = props.attribute.inheritLineage;

    // We don't have space for the entire lineage
    if (lineage.length > 3) {
        lineage = [lineage[0], null, ...lineage.slice(-2)];
    }

    return lineage.map((l, i) => {
        if (l === null) {
            return {
                label: "...",
                dots: true,
            };
        } else {
            return {
                label: l.username,
                dots: false,
                final: i == lineage.length - 1,
            };
        }
    });
});
</script>

<template>
    <div :class="{ disabled: isDisabled }">
        <label class="inherit flex items-center bb">
            <input
                :disabled="isDisabled"
                :checked="attribute.inherit"
                type="checkbox"
                data-testid="inherit-checkbox"
            />

            <span
                v-if="attribute.inheritLineage.length === 0"
                class="inherit-label"
            >
                Follow default
            </span>
            <div v-else class="flex items-baseline">
                <span class="inherit-label">Inherit from&nbsp; </span>

                <span class="inherit-link flex items-baseline">
                    <span
                        v-for="item in inheritLineage"
                        class="inherit-link-item"
                    >
                        <span v-if="item.dots" class="dots">
                            {{ item.label }}
                        </span>
                        <span v-else class="user flex items-baseline">
                            {{ item.label }}
                            <BaseIcon
                                v-if="!item.final"
                                variant="chevron-right"
                                weight="bold"
                                class="icon"
                            />
                        </span>
                    </span>
                </span>
            </div>
        </label>
    </div>
</template>

<style lang="scss" scoped>
.disabled {
    color: #999;
}

.inherit input {
    flex-basis: 0;
    flex-grow: 0;
}

.inherit-label {
    margin-left: 0.5rem;
    white-space: nowrap;
}

.inherit-link {
    flex-wrap: wrap;

    .user {
        white-space: nowrap;
    }

    .icon {
        padding-top: 1px;
        align-self: center;
        color: #999;
    }

    .dots {
        color: #666;
    }
}
</style>
