import _ from "lodash";
import api from "@/api";
import { hasLicense, parseLicense, areLicensesEqual } from "@/licenses";

const updateStateFromUser = (state, user) => {
    state.user = user;

    state.isSaving = {
        license: false,
        licenseTeaser: false,
        licenseLogin: false,
        licenseUser: false,
    };
    state.errorMessage = {
        license: null,
        licenseTeaser: null,
        licenseLogin: null,
        licenseUser: null,
    };

    state.license = parseLicense(
        user.attributes.OCG_RL_license,
        user.attributes.OCG_RL_license_enforce
    );
    state.licenseTeaser = parseLicense(
        user.attributes.OCG_RL_license_teaser,
        user.attributes.OCG_RL_license_enforce_teaser
    );
    state.licenseLogin = parseLicense(
        user.attributes.OCG_RL_license_login,
        user.attributes.OCG_RL_license_enforce_login
    );

    state.licenseUser = parseLicense(
        user.attributes.USER_SUMMARIZER_PARAM,
        user.attributes.USER_SUMMARIZER_PARAM
    );

    state.original = {
        license: _.cloneDeep(state.license),
        licenseTeaser: _.cloneDeep(state.licenseTeaser),
        licenseLogin: _.cloneDeep(state.licenseLogin),
        licenseUser: _.cloneDeep(state.licenseUser),
    };

    state.activeTab = "license";
    state.activeSubTabs = {
        license: state.license.regular.canBeSet() ? "regular" : "enforce",
        licenseTeaser: state.licenseTeaser.regular.canBeSet()
            ? "regular"
            : "enforce",
        licenseLogin: state.licenseLogin.regular.canBeSet()
            ? "regular"
            : "enforce",
        licenseUser: state.licenseUser.regular.canBeSet()
            ? "regular"
            : "enforce",
    };

    return state;
};

const updateStateFromUserPartial = (state, licenseType, user) => {
    switch (licenseType) {
        case "license":
            state.license = parseLicense(
                user.attributes.OCG_RL_license,
                user.attributes.OCG_RL_license_enforce
            );
            state.original.license = _.cloneDeep(state.license);
            break;
        case "licenseTeaser":
            state.licenseTeaser = parseLicense(
                user.attributes.OCG_RL_license_teaser,
                user.attributes.OCG_RL_license_enforce_teaser
            );
            state.original.licenseTeaser = _.cloneDeep(state.licenseTeaser);
            break;
        case "licenseLogin":
            state.licenseLogin = parseLicense(
                user.attributes.OCG_RL_license_login,
                user.attributes.OCG_RL_license_enforce_login
            );
            state.original.licenseLogin = _.cloneDeep(state.licenseLogin);
            break;
        case "licenseUser":
            state.licenseUser = parseLicense(
                user.attributes.USER_SUMMARIZER_PARAM,
                user.attributes.USER_SUMMARIZER_PARAM
            );
            state.original.licenseUser = _.cloneDeep(state.licenseUser);
            break;
        default:
            throw `updateStateFromUserPartial: Did not recognize license type ${licenseType}.`;
    }

    return state;
};

const getters = {
    hasTypeBeenModified: (state) => (licenseType) => {
        let license = findLicenseByType(state, licenseType);
        let originalLicense = findLicenseByType(state.original, licenseType);

        return !areLicensesEqual(license, originalLicense);
    },
    hasBeenModified: (state, getters) => {
        return (
            getters.hasTypeBeenModified("license") ||
            getters.hasTypeBeenModified("licenseTeaser") ||
            getters.hasTypeBeenModified("licenseLogin") ||
            getters.hasTypeBeenModified("licenseUser")
        );
    },
    isValueDisabled: (state) => (license, tabType, value) => {
        const activeEnforce = license.enforce.getActiveValue();

        switch (tabType) {
            case "regular":
                return (
                    license.regular.inherit || hasLicense(value, activeEnforce)
                );
            case "enforce":
                return license.enforce.inherit;
            default:
                throw "Unkown tab type.";
        }
    },
    isSubTabDisabled: (state) => (license, tabType) => {
        return !license[tabType].canBeSet();
    },
    typeCanBeSet: (state) => (licenseType) => {
        return canLicenseBeSet(findLicenseByType(state, licenseType));
    },
    isTabActive: (state) => (licenseType) => {
        return state.activeTab === licenseType;
    },
    isTabVisible: (state, getters) => (licenseType) => {
        return getters.typeCanBeSet(licenseType);
    },
};

const mutations = {
    reset: (state, licenseType) => {
        let originalLicense = findLicenseByType(state.original, licenseType);

        state[licenseType] = _.cloneDeep(originalLicense);
        state.errorMessage[licenseType] = null;
    },
    updateUser: (state, user) => {
        updateStateFromUser(state, user);
    },
    updateUserPartial: (state, [licenseType, user]) => {
        updateStateFromUserPartial(state, licenseType, user);
    },
    setActiveTab: (state, licenseType) => {
        state.activeTab = licenseType;
    },
    setActiveSubTab: (state, [licenseType, tabType]) => {
        state.activeSubTabs[licenseType] = tabType;
    },
    updateValue: (state, [licenseType, tabType, value], getters) => {
        let license = findLicenseByType(state, licenseType);

        if (tabType === "regular") {
            if (hasLicense(value, license.regular.value)) {
                license.regular.value -= value;
            } else {
                license.regular.value += value;
            }
        } else if (tabType === "enforce") {
            if (hasLicense(value, license.enforce.value)) {
                license.enforce.value -= value;
            } else {
                license.enforce.value += value;
            }
        } else {
            throw "Unknown tab type";
        }
    },
    setValue: (state, [licenseType, value]) => {
        let license = findLicenseByType(state, licenseType);

        license.regular.value = value;
        license.regular.inherit = false;
        license.enforce.value = licenseType === "licenseUser" ? value : BigInt(0);
        license.enforce.inherit = false;
    },
    updateInherit: (state, [licenseType, kind]) => {
        let license = findLicenseByType(state, licenseType);

        switch (kind) {
            case "regular":
                license.regular.inherit = !license.regular.inherit;
                break;
            case "enforce":
                license.enforce.inherit = !license.enforce.inherit;
                break;
            default:
                throw `Did not regonize license kind ${kind}`;
        }
    },
    updateWhocanset: (state, [key, whocanset]) => {
        let [licenseType, kind] = key;
        let license = findLicenseByType(state, licenseType);

        if (kind === "regular") {
            license.regular.whocanset = whocanset;
        } else {
            license.enforce.whocanset = whocanset;
        }
    },
    resetErrorMessage: (state, licenseType) => {
        state.errorMessage[licenseType] = null;
    },
    setErrorMessage: (state, [licenseType, message]) => {
        state.errorMessage[licenseType] = message;
    },
    updateIsSaving: (state, [licenseType, value]) => {
        state.isSaving[licenseType] = value;
    },
};

const actions = {
    save: async ({ state, commit, rootState }, licenseType) => {
        if (state.user.id_user !== rootState.user.id_user) {
            commit("setErrorMessage", "Users out of sync. Please refresh.");
            return;
        }

        commit("updateIsSaving", [licenseType, true]);

        let license = findLicenseByType(state, licenseType);
        let [regularName, enforceName] = findNamesByLicenseType(licenseType);

        let payload = {};

        if (license.regular.canBeSet()) {
            payload[regularName] = license.regular;
        }
        if (license.enforce.canBeSet()) {
            payload[enforceName] = license.enforce;
        }

        try {
            let updatedUser = await api.users.patchAttributes(
                state.user.id_user,
                payload
            );
            commit("resetErrorMessage", licenseType);
            commit("updateUserPartial", [licenseType, updatedUser]);
        } catch {
            commit("setErrorMessage", [licenseType, "Error when saving"]);
        }

        commit("updateIsSaving", [licenseType, false]);
    },
};

const findLicenseByType = (state, licenseType) => {
    switch (licenseType) {
        case "license":
            return state.license;
        case "licenseTeaser":
            return state.licenseTeaser;
        case "licenseLogin":
            return state.licenseLogin;
        case "licenseUser":
            return state.licenseUser;
        default:
            throw `findLicenseByType: Did not recognize license type ${licenseType}.`;
    }
};

const findNamesByLicenseType = (licenseType) => {
    switch (licenseType) {
        case "license":
            return ["OCG_RL_license", "OCG_RL_license_enforce"];
        case "licenseTeaser":
            return ["OCG_RL_license_teaser", "OCG_RL_license_enforce_teaser"];
        case "licenseLogin":
            return ["OCG_RL_license_login", "OCG_RL_license_enforce_login"];
        case "licenseUser":
            return ["USER_SUMMARIZER_PARAM", "USER_SUMMARIZER_PARAM"];
        default:
            throw `findNamesByLicenseType: Did not recognize license type ${licenseType}.`;
    }
};

const canLicenseBeSet = (license) => {
    return license.regular.canBeSet() || license.enforce.canBeSet();
};

export default {
    namespaced: true,
    state: {},
    getters,
    mutations,
    actions,
};
