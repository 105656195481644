<script setup lang="ts">
import { ref } from 'vue';

import 'primevue/resources/themes/lara-light-green/theme.css';
import InputSwitch from 'primevue/inputswitch';
import type { Attribute } from "@/attributes";

const props = defineProps<{
    attribute: Attribute<string>;
}>();

const emit = defineEmits<{
    (e: "update", value: string): void;
}>();

const value1 = ref(props.attribute.inheritValue);
const value2 = ref(props.attribute.value);

const getValue = () => (props.attribute.inherit ? value1.value : value2.value);

const updateValue = (newValue: string) => {
  if (props.attribute.inherit) {
    value1.value = newValue;
  } else {
    value2.value = newValue;
  }
  emit("update", newValue);
};

</script>

<template>
  <div>
    <InputSwitch 
        true-value="1"
        false-value="0"
        :disabled="props.attribute.inherit"
        :model-value="getValue()"
        @update:model-value="updateValue"
    />
  </div>
    <span v-if="getValue() == '1'">Active</span>
    <span v-else>Inactive</span>
</template>

<style scoped>
    span {
        margin-left: 10%;
    }
</style>

