<script setup lang="ts">
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import UserTreeNode from "./UserTreeNode.vue";

const store = useStore();

const OPENING_DELAY = 800;

const moveUp = ref(null);
const inactive = ref(null);
const view = ref(null);

const props = defineProps<{
    disabled: boolean;
}>();

onMounted(() => {
    // @ts-ignore
    tippy(moveUp.value, {
        content: "Move focus of tree one level up",
        delay: [OPENING_DELAY, null],
    });
    // @ts-ignore
    tippy(inactive.value, {
        content: "Toggle inactive users visibility",
        delay: [OPENING_DELAY, null],
    });
    // @ts-ignore
    tippy(view.value, {
        content: "View tree from selected user",
        delay: [OPENING_DELAY, null],
    });
});
</script>

<template>
    <section>
        <div class="user-tree-header flex justify-between">
            <h3 class="h4">Users</h3>
            <div class="sidebar-controls">
                <div
                    ref="moveUp"
                    class="sidebar-control"
                    @click="store.commit('sidebar/moveUp')"
                >
                    <BaseIcon variant="chevrons-up" weight="bold" />
                </div>
                <div
                    ref="inactive"
                    class="sidebar-control"
                    @click="store.commit('sidebar/toggleActiveFilter')"
                >
                    <BaseIcon variant="flag" weight="bold" />
                </div>
                <div
                    ref="view"
                    class="sidebar-control"
                    @click="store.commit('sidebar/viewFromCurrentUser')"
                >
                    <BaseIcon variant="compass" weight="bold" />
                </div>
            </div>
        </div>
        <div class="user-tree">
            <UserTreeNode
                :node="store.state.sidebar.view"
                :disabled="disabled"
                :show-entry="store.state.sidebar.view.isUserEditable()"
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
section {
    min-height: 420px;
}

.user-tree-header {
    padding-bottom: 0.75rem;
}

.sidebar-controls {
    display: flex;
    margin: 0rem -0.25rem;
}

.sidebar-control {
    font-size: $font-size-md;
    font-weight: 600;
    color: var(--text-medium);
    user-select: none;
    display: flex;
    align-items: center;
    padding: 0rem 0.25rem;

    &:hover {
        color: var(--text);
        cursor: pointer;
        background: var(--text-weak);
        border-radius: var(--border-radius);
    }
}
</style>
