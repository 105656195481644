<script setup lang="ts">

import AttributeValueSwitchWrapper from "./AttributeValueSwitchWrapper.vue";

import type { Attribute } from "@/attributes";

const props = defineProps<{
    attribute: Attribute<string>;
}>();

const emit = defineEmits<{
    (e: "update", value: string): void;
}>();

function update(e: string) {
    emit("update", e);
}
</script>

<template>
    <div class="-mt-0.5 py-0.5 px-0.5 checkbox-switch">
        <div :class="{ disabled: attribute.inherit, }" class="checkbox-switch">
            <AttributeValueSwitchWrapper :attribute="props.attribute" @update="update"/>
        </div>
    </div>
</template>
