<script setup lang="ts">
import { useStore } from "vuex";

const store = useStore();
</script>

<template>
    <section
        v-if="store.getters['userChangeDialogVisible']"
        class="user-change"
    >
        <h4>Discard changes?</h4>
        <p>You have unsaved changes:</p>
        <div class="button-container">
            <button
                class="btn btn-error"
                @click="store.dispatch('moveToUserChangeTarget')"
            >
                Discard changes
            </button>
            <button @click="store.commit('closeUserChangeDialog')">
                Go back
            </button>
        </div>
    </section>
</template>

<style scoped>
.user-change p {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.user-change .button-container {
    margin-top: 1rem;
}
</style>
