<script setup lang="ts">
import { ref } from "vue";
import type { CreateOption, CustomerOption } from "@/api";
import { findDefaultOption, findDefaultCustomerOption } from "@/createOptions";
import BaseIcon from "@/components/utils/BaseIcon.vue";

const props = defineProps<{
    options: CreateOption[];
    initialOption?: CreateOption;
    initialCustomerOption?: CustomerOption;
}>();

const selectedOption = ref<CreateOption | null>(
    findDefaultOption(props.options)
);

if (!selectedOption.value && props.initialOption) {
    selectedOption.value = props.initialOption;
}

const selectOption = (option: CreateOption) => {
    if (!option.user_type_allowed) {
        return;
    }

    selectedOption.value = option;
    selectedCustomerOption.value = findDefaultCustomerOption(option);
};

const selectedCustomerOption = ref<CustomerOption | null>(
    findDefaultCustomerOption(selectedOption.value)
);

if (!selectedCustomerOption.value && props.initialCustomerOption) {
    selectedCustomerOption.value = props.initialCustomerOption;
}

const selectCustomerOption = (option: CustomerOption) => {
    if (!option.option_allowed) {
        return;
    }

    selectedCustomerOption.value = option;
};

const emits = defineEmits<{
    (e: "cancel"): void;
    (e: "submit", data: [CreateOption, CustomerOption]): void;
}>();

const submit = () => {
    if (!selectedOption.value || !selectedCustomerOption.value) {
        return;
    }
    emits("submit", [selectedOption.value, selectedCustomerOption.value]);
};
</script>

<template>
    <section>
        <div class="user-create-content">
            <h1 class="mx-2">Choose user type</h1>

            <div class="px-2 py-2 w-2/3">
                <div
                    v-for="option in options"
                    :key="option.user_type.value"
                    class="create-option"
                >
                    <div
                        class="create-option-item"
                        :class="{
                            'is-selected':
                                option.user_type.value ===
                                selectedOption?.user_type.value,
                        }"
                        :disabled="!option.user_type_allowed"
                        @click="selectOption(option)"
                    >
                        <span>{{ option.user_type.name }}</span>
                        <BaseIcon variant="chevron-right" weight="bold" />
                    </div>
                    <div
                        v-if="
                            option.user_type.value ===
                            selectedOption?.user_type.value
                        "
                        class="pl-1"
                    >
                        <div
                            v-for="customerOption in option.customer_options"
                            :key="customerOption.option.value"
                            class="create-option-item -wide"
                            :class="{
                                'is-selected':
                                    customerOption.option.value ===
                                    selectedCustomerOption?.option.value,
                            }"
                            :disabled="!customerOption.option_allowed"
                            @click="selectCustomerOption(customerOption)"
                        >
                            <span>{{ customerOption.option.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-2/3 flex justify-between items-center bt footer">
            <button class="btn btn-underline -left" @click="emits('cancel')">
                Cancel
            </button>
            <button
                :disabled="!selectedOption || !selectedCustomerOption"
                @click="submit"
            >
                Next
            </button>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.create-option {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.create-option-item {
    font-size: $font-size-md;
    padding: 0.5rem;
    min-width: 12rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.-wide {
        min-width: 20rem;
    }

    &.is-selected {
        border-radius: var(--border-radius);
        background: hsl(210deg 10% 97%);
        font-weight: 600;
    }

    &:not([disabled="true"]):hover {
        cursor: pointer;
        border-radius: var(--border-radius);
        background: hsl(210deg 10% 97%);
    }

    &[disabled="true"] {
        color: var(--text-weak);
    }
}
</style>
