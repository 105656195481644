<template>
    <div class="flex justify-between items-end px-2">
        <div class="name">
            <h1>M360 modules</h1>
        </div>
        <div class="parts flex-grow flex justify-between px-0.5">
            <div class="value text-center label">Normal</div>
            <div class="value text-center label">Admin</div>
            <div class="value text-center label">Super</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.name {
    width: 280px;
}

.parts {
    margin-bottom: -0.5rem;
}

.value {
    flex-grow: 0;
    width: 9rem;
    text-transform: uppercase;
}
</style>
