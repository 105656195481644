<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
    value: number;
    inherit: boolean;
}>();

const emit = defineEmits<{
    (e: "increment"): void;
    (e: "decrement"): void;
}>();

const text = computed(() => {
    switch (props.value) {
        case 0:
            return "Off";
        case 1:
            return "Restricted";
        case 2:
            return "On";
        default:
            throw "Error";
    }
});

const stateClass = computed(() => {
    return {
        "-none": props.value == 0,
        "-read": props.value == 1,
        "-write": props.value == 2,
    };
});

const canIncrement = computed(() => props.value < 2);
const canDecrement = computed(() => props.value > 0);
</script>

<template>
    <div>
        <div class="flex toggle" :class="stateClass">
            <button
                v-if="canDecrement"
                :disabled="inherit"
                class="button -left"
                @click="emit('decrement')"
            >
                <BaseIcon variant="chevron-down" weight="bold" />
            </button>
            <div v-else class="dummy"></div>

            <span class="text">
                {{ text }}
            </span>

            <button
                v-if="canIncrement"
                :disabled="inherit"
                class="button -right"
                @click="emit('increment')"
            >
                <BaseIcon variant="chevron-up" weight="bold" />
            </button>
            <div v-else class="dummy"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/styles/utils";

.toggle {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    flex-grow: 0;
    height: 2.4rem;

    background: #fff;
    border: 1px solid var(--form-border);

    &.-not-editable {
        border: none;
    }

    &.-read {
        background: repeating-linear-gradient(
            -45deg,
            #fff,
            #fff 3px,
            $bg-enforce-opaque 3px,
            $bg-enforce-opaque 7px
        );
        border: 1px solid $border-color-enforce;

        .inherited & {
            background: repeating-linear-gradient(
                -45deg,
                #fff,
                #fff 3px,
                $bg-enforce-opaque-disabled 3px,
                $bg-enforce-opaque-disabled 7px
            );
            border: 1px solid $border-color-enforce-disabled;
        }
    }

    &.-write {
        background: $bg-enforce;
        border: 1px solid $border-color-enforce;

        .inherited & {
            background: $bg-enforce-disabled;
            border: 1px solid $border-color-enforce-disabled;
        }
    }

    .dummy {
        width: 2rem;
        height: 1rem;
    }
}

.button {
    @include reset-button;
    width: 2rem;
    height: 100%;
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $color-button;

    &.-left {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
    }

    &.-right {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
    }

    .-read & {
        color: $color-enforce;
    }

    .-write & {
        color: $color-enforce;
    }

    &:disabled {
        color: #aaa;
    }

    &:hover:disabled {
        cursor: initial;
    }

    .inherited .-read & {
        color: $color-enforce;
    }

    .inherited .-write & {
        color: $color-enforce-disabled;
    }

    .-read &:hover:disabled {
        cursor: initial;
    }

    .-read &:hover:not(:disabled) {
        background: hsl(0deg 0% 0% / 4%);
    }

    .-read &:active:not(:disabled) {
        background: hsl(0deg 0% 0% / 10%);
    }

    .-write &:hover:disabled {
        cursor: initial;
    }

    .-write &:hover:not(:disabled) {
        background: $bg-enforce-hover;
    }

    .-write &:active:not(:disabled) {
        background: $bg-enforce-active;
    }
}

.text {
    min-width: 5rem;
    flex-grow: 1;
    text-align: center;
    font-weight: 600;
    color: $color-button;

    .-read &,
    .-write & {
        color: $color-enforce;
    }

    .inherited & {
        color: #aaa;
    }

    .inherited .-read &,
    .inherited .-write & {
        color: $color-enforce-disabled;
    }
}
</style>
