import _ from "lodash";
import api from "@/api";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { UserDetail } from "@/api";
import type { Attribute } from "@/attributes";
import type { FormErrors } from "@/forms";
import {
    parseUsersModules,
    incrementModule,
    decrementModule,
    setModuleValue,
    areModulesEqual,
} from "@/modules";
import type { ModuleField, ModuleAttribute } from "@/modules";
import { useOverviewStore } from "@/stores/overview";

export const useModulesStore = defineStore("modules", () => {
    const overview = useOverviewStore();

    // Tag of data used to avoid data races
    const user = ref<UserDetail | undefined>(undefined);

    const state = ref<"editing" | "saving">("editing");
    const errors = ref<FormErrors | undefined>(undefined);

    const modules = ref<Record<string, ModuleAttribute>>({});
    const original = ref<Record<string, ModuleAttribute>>({});

    const hasBeenModified = computed(() => {
        for (let key of Object.keys(modules.value)) {
            if (!areModulesEqual(modules.value[key], original.value[key])) {
                return true;
            }
        }

        return false;
    });

    const updateUser = (updatedUser: UserDetail) => {
        state.value = "editing";
        errors.value = undefined;
        user.value = updatedUser;
        modules.value = parseUsersModules(updatedUser);
        original.value = _.cloneDeep(modules.value);
    };

    const reset = () => {
        errors.value = undefined;
        modules.value = _.cloneDeep(original.value);
    };

    const decrementModuleState = (key: string, modulePart: ModuleField) => {
        modules.value[key].value = decrementModule(
            modules.value[key].value,
            modulePart
        );
    };
    const incrementModuleState = (key: string, modulePart: ModuleField) => {
        modules.value[key].value = incrementModule(
            modules.value[key].value,
            modulePart
        );
    };

    const setModuleState = (key: string, value: string) => {
        modules.value[key].value = setModuleValue(
            modules.value[key].value,
            value
        );
    };

    const updateInherit = (key: string) => {
        modules.value[key].inherit = !modules.value[key].inherit;
    };

    const updateWhocanset = ([key, whocanset]: [string, number]) => {
        modules.value[key].whocanset = whocanset;
    };

    const save = async () => {
        if (!user.value) {
            return;
        }
        const idUser = user.value.id_user;

        // Sanity check that stores has not gotten out of sync
        if (overview.idUser !== idUser) {
            errors.value = {
                general: "Users out of sync. Please refresh.",
            };
            return;
        }

        state.value = "saving";
        errors.value = undefined;

        try {
            let updatedUser = await api.users.patchAttributes(
                idUser,
                modules.value
            );
            if (user.value.id_user === idUser) {
                updateUser(updatedUser);
            }
        } catch (rawErrors) {
            errors.value = { general: "Error when saving" };
        }

        if (user.value.id_user === idUser) {
            state.value = "editing";
        }
    };

    return {
        user,
        modules,
        hasBeenModified,
        state,
        errors,
        updateUser,
        decrementModuleState,
        incrementModuleState,
        setModuleState,
        updateInherit,
        updateWhocanset,
        reset,
        save,
    };
});
