<script setup lang="ts">
import { ref } from "vue";
import ErrorSection from "@/components/utils/ErrorSection.vue";
import FormInput from "@/components/utils/FormInput.vue";
import SaveFooter from "@/components/utils/SaveFooter.vue";
import type { CreateCustomerRaw } from "@/api";
import type { FormErrors } from "@/forms";

const name = ref("");
const partner = ref(1);
const isEducational = ref(false);
const isOpoint = ref(false);
const maxNumberOfUsers = ref("");
const salesforceCustomerNumber = ref("");

withDefaults(
    defineProps<{
        isSaving: boolean;
        errors?: FormErrors;
        cancelText?: string;
    }>(),
    {
        cancelText: "Cancel",
    }
);

const emits = defineEmits<{
    (e: "cancel"): void;
    (e: "submit", payload: CreateCustomerRaw): void;
}>();

function submit(): void {
    emits("submit", {
        name: name.value,
        partner: partner.value,
        is_educational: isEducational.value,
        max_num_users: maxNumberOfUsers.value,
        is_opoint: isOpoint.value,
        salesforce_id: salesforceCustomerNumber.value,
    });
}
</script>

<template>
    <section>
        <div class="user-create-content">
            <h1 class="mx-2">New customer</h1>

            <div class="px-2 py-label w-2/3">
                <div class="form form-layout">
                    <FormInput
                        v-model="name"
                        label="Name"
                        char-limit="255"
                        :error="errors?.fields?.name"
                    />
                    <FormInput
                        v-model="partner"
                        label="Partner"
                        type-name="number"
                        :error="errors?.fields?.partner"
                    />

                    <FormInput
                        v-model="maxNumberOfUsers"
                        label="Maximum number of users"
                        placeholder="No limit"
                        :error="errors?.fields?.max_num_users"
                    />
                    <FormInput
                        v-model="salesforceCustomerNumber"
                        label="Salesforce Account ID"
                        placeholder="No ID"
                        :error="errors?.fields?.salesforce_id"
                    />
                    <FormInput
                        v-model="isEducational"
                        label="Educational"
                        type-name="checkbox"
                        :error="errors?.fields?.is_educational"
                    />
                    <FormInput
                        v-model="isOpoint"
                        label="Is Opoint"
                        type-name="checkbox"
                        :error="errors?.fields?.is_opoint"
                    />
                </div>
            </div>
        </div>

        <ErrorSection class="w-2/3" :message="errors?.general" />

        <SaveFooter
            class="w-2/3"
            save-text="Save"
            :is-saving="isSaving"
            @save="submit"
        >
            <button class="btn btn-underline -left" @click="emits('cancel')">
                {{ cancelText }}
            </button>
        </SaveFooter>
    </section>
</template>
