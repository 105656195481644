import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import MicroModal from "micromodal";
import api from "@/api";
import { parseErrors } from "@/misc.js";

const updateState = (state, user) => {
    state.user = user;

    state.isSaving = false;
    state.errors = {};
    state.errorMessage = null;

    state.contact = {
        username: user.username,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        description: user.descr,
        isActive: user.is_active,
        isStaff: user.is_staff,
        displayName: user.display_name,
    };

    state.createDate = user.create_date;
    state.userType = user.user_type.value;
    state.owner = _.cloneDeep(user.owner);
    state.customer = _.cloneDeep(user.customer);

    state.original = {
        contact: _.cloneDeep(state.contact),
        userType: _.cloneDeep(state.userType),
        owner: _.cloneDeep(state.owner),
        customer: _.cloneDeep(state.customer),
    };

    state.passwordResetEmailMessage = null;
    state.passwordResetEmailError = null;

    state.migrationState = _.cloneDeep(user.migration_state);
    state.migrationInProgress = null;

    return state;
};

const getters = {
    getField,
    hasBeenModified: (state) => {
        for (let key of Object.keys(state.contact)) {
            if (state.contact[key] !== state.original.contact[key]) {
                return true;
            }
        }

        return (
            state.userType !== state.original.userType ||
            state.owner.id_user !== state.original.owner.id_user
        );
    },
    changeUserTypes: (state) => {
        return state.user.change_types;
    },
    isOwnerVisible: (state) => {
        return state.owner?.id_user > 0;
    },
};

const mutations = {
    updateField,
    updateUser: (state, user) => {
        updateState(state, user);
    },
    reset: (state) => {
        state.contact = _.cloneDeep(state.original.contact);

        state.userType = _.cloneDeep(state.original.userType);

        state.owner = _.cloneDeep(state.original.owner);
        state.customer = _.cloneDeep(state.original.customer);

        state.errors = {};
        state.errorMessage = null;
    },
    updateContact: (state, [key, value]) => {
        state.contact[key] = value;
    },
    updateUserType: (state, value) => {
        state.userType = value;
    },
    setOwner: (state, owner) => {
        state.owner = owner;
    },
    setCustomer: (state, [idUser, fullCustomer]) => {
        if (state.user.id_user !== idUser) {
            return;
        }

        state.customer = _.cloneDeep({
            id: fullCustomer.id,
            partner: fullCustomer.partner,
            name: fullCustomer.name,
            is_active: fullCustomer.is_active,
        });
        state.original.customer = _.cloneDeep(state.customer);
    },
    updateErrors: (state, errors) => {
        state.errors = errors;
    },
    resetErrors: (state) => {
        state.errors = {};
        state.errorMessage = null;
    },
    setErrorMessage: (state, message) => {
        state.errorMessage = message;
    },
    updateIsSaving: (state, value) => {
        state.isSaving = value;
    },
    setPasswordResetEmailMessage: (state, message) => {
        state.passwordResetEmailMessage = message;
    },
    setPasswordResetEmailError: (state, error) => {
        state.passwordResetEmailError = error;
    },
    setMigrationStatus: (state, message) => {
        state.migrationInProgress = message;
    },
};

const actions = {
    migrate: async ({ state, commit }) => {
        if (
            (state.migrationState.value === 2 ||
                state.migrationState.value === 4) &&
            state.migrationInProgress === null
        ) {
            commit(
                "setMigrationStatus",
                "Migration in progress, this could take some time."
            );
            const apiResponse = await api.users.migrate(state.user.id_user);
            if (apiResponse) {
                commit("setMigrationStatus", "Profile migrated succesfully!");
            } else {
                commit("setMigrationStatus", "Migration did not succeed.");
            }
        } else {
            commit("setMigrationStatus", "This profile can't be migrated yet.");
        }
    },
    openPasswordResetEmailModal: ({ state, commit }) => {
        if (!state.user.email) {
            commit(
                "setPasswordResetEmailError",
                "User has no email! Can only send reset emails to users with email addresses."
            );
            return;
        }

        MicroModal.show("modal-password-reset-email", {
            disableFocus: false,
            disableScroll: false,
        });
    },
    sendPasswordResetEmail: async ({ state, commit }) => {
        commit("setPasswordResetEmailError", "");
        commit("setPasswordResetEmailMessage", "");
        if (!state.user.email) {
            commit(
                "setPasswordResetEmailError",
                "User has no email! Can only send reset emails to users with email addresses."
            );
            return;
        }
        try {
            await api.auth.sendPasswordResetEmail(
                state.user.id_user,
                state.user.username
            );
            commit(
                "setPasswordResetEmailMessage",
                "Success! Email sent to the user"
            );
        } catch (e) {
            console.error(e);
            commit(
                "setPasswordResetEmailError",
                "Error when sending password reset email"
            );
        }
    },
    save: async ({ state, commit, dispatch, rootState, rootGetters }) => {
        if (state.user.id_user !== rootState.user.id_user) {
            commit("setErrorMessage", "Users out of sync. Please refresh.");
            return;
        }

        commit("updateIsSaving", true);

        let payload = {
            username: state.contact.username,
            email: state.contact.email,
            first_name: state.contact.firstName,
            last_name: state.contact.lastName,
            descr: state.contact.description,
            is_active: state.contact.isActive,
            owner: state.owner.id_user,
            user_type: state.userType,
            display_name: state.contact.displayName,
        };

        if (rootGetters["isStaff"]) {
            payload.is_staff = state.contact.isStaff;
        }

        try {
            let updatedUser = await api.users.patch(
                state.user.id_user,
                payload
            );

            commit("resetErrors");
            commit("sidebar/updateTree", updatedUser, { root: true });

            if (state.original.owner.id_user !== updatedUser.owner.id_user) {
                dispatch(
                    "updateUser",
                    {
                        idUser: updatedUser.id_user,
                        resetTree: false,
                    },
                    { root: true }
                );
            } else {
                commit("updateUser", updatedUser);
            }
        } catch (rawErrors) {
            // Use this as a commit
            // pass commit
            // and rawErrors
            let errors = parseErrors(rawErrors, {
                username: "username",
                email: "email",
                first_name: "firstName",
                last_name: "lastName",
                display_name: "displayName",
                descr: "description",
            });

            if (Object.keys(errors).length > 0) {
                commit("updateErrors", errors);
            } else if (rawErrors.non_field_errors) {
                commit("setErrorMessage", rawErrors.non_field_errors[0]);
            } else {
                commit("setErrorMessage", "Error when saving");
            }
        }

        commit("updateIsSaving", false);
    },
};

export default {
    namespaced: true,
    state: {},
    getters,
    mutations,
    actions,
};
