<template>
    <div :class="{ inherited: access.inherit }">
        <div class="mx-2 bt meta-container--wide">
            <AttributeInherit
                :attribute="access"
                @change="updateInherit('accessPay')"
            />
            <AttributeWhoCanSet
                :relative-id-user="user.id_user"
                :attribute="access"
                @update="
                    (v) =>
                        $store.commit('access/updateWhocanset', [
                            'accessPay',
                            v,
                        ])
                "
            />
        </div>

        <div class="access-groups bg-inherit px-2 py-label">
            <p class="label">Groups</p>
            <ol>
                <li
                    v-for="description in accessGroupDescriptions"
                    :key="description"
                    class="access-group checkbox-container"
                    :class="{
                        disabled: accessDisabled(description.value),
                    }"
                    @click.prevent="toggleAccessGroup(description.value)"
                >
                    <div class="checkbox-alignment">
                        &#8203;
                        <input
                            type="checkbox"
                            :disabled="accessDisabled(description.value)"
                            :checked="accessChecked(description.value)"
                            @click.stop="nothing"
                            @change.stop="toggleAccessGroup(description.value)"
                        />
                    </div>
                    <label>{{ description.text }}</label>
                </li>
            </ol>
        </div>

        <div class="access-sites bg-inherit px-2 py-label bt">
            <SiteSearchPay
                :access="access"
                :access-regular="accessRegular"
                :disabled="access.inherit"
            />
        </div>

        <div class="access-sources bg-inherit px-2 py-label bt">
            <div class="input-group">
                <label for="sources">Individual sources</label>
                <input
                    id="sources"
                    v-model="sources"
                    :disabled="access.inherit"
                    placeholder="Source string"
                    type="text"
                />
            </div>
        </div>

        <ErrorSection :message="errorMessage" />

        <SaveResetFooter
            :is-saving="isSaving"
            :is-modified="hasTypeBeenModified('accessPay')"
            @reset="resetAccess"
            @save="saveAccess"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { mapState as mapPiniaState } from "pinia";
import AttributeInherit from "@/components/attributes/AttributeInherit.vue";
import AttributeWhoCanSet from "@/components/attributes/AttributeWhoCanSet.vue";
import SiteSearchPay from "./SiteSearchPay.vue";
import { useDisplayStore } from "@/stores/display";

import { hasAccess } from "@/access";

export default {
    components: {
        AttributeInherit,
        AttributeWhoCanSet,
        SiteSearchPay,
    },
    props: ["access", "accessRegular"],
    computed: {
        ...mapPiniaState(useDisplayStore, ["accessGroupDescriptions"]),
        ...mapState(["user"]),
        ...mapGetters("access", ["hasTypeBeenModified"]),
        ...mapState("access", {
            errorMessage(state) {
                return state.errorMessage.accessPay;
            },
            isSaving(state) {
                return state.isSaving.accessPay;
            },
        }),
        sources: {
            get() {
                return this.access.value.sources;
            },
            set(value) {
                this.$store.commit("access/updateSources", [
                    "accessPay",
                    value,
                ]);
            },
        },
    },
    methods: {
        ...mapMutations("access", ["updateInherit", "reset"]),
        ...mapActions("access", ["save"]),
        resetAccess() {
            this.reset("accessPay");
        },
        saveAccess() {
            this.save("accessPay");
        },
        accessChecked: function (key) {
            return hasAccess(this.access.getActiveValue().accessGroup, key);
        },
        accessDisabled: function (key) {
            if (this.access.inherit) {
                return true;
            }

            // If there is a mismatch, and the pay group is there even though
            // the regular is not.
            if (hasAccess(this.access.value.accessGroup, key)) {
                return false;
            }

            return !hasAccess(
                this.accessRegular.getActiveValue().accessGroup,
                key
            );
        },
        toggleAccessGroup: function (key) {
            if (!this.accessDisabled(key)) {
                this.$store.commit("access/toggleAccessGroup", [
                    "accessPay",
                    key,
                ]);
            }
        },
    },
};
</script>
