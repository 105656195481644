import { computed, ref } from "vue";
import { LICENSE_GROUP_STRUCTURE } from "@/config";
import { defineStore } from "pinia";
import type { DisplayData } from "@/api";
import { getAccessGroupDescriptions } from "@/access";
import { getFlatLicenseDescriptions } from "@/licenses";
import { groupByStructure } from "@/utils";

export const useDisplayStore = defineStore("display", () => {
    const names = ref<Record<string, string>>({});

    const accessGroups = ref<any | undefined>(undefined);
    const licenses = ref<any | undefined>(undefined);

    const update = (displayData: DisplayData) => {
        names.value = displayData.userManagement;
        accessGroups.value = displayData.accessGroups;
        licenses.value = displayData.licenses;
    };

    const nameOf = (key: string) => {
        return names.value[key] ?? key;
    };

    const accessGroupDescriptions = computed(() => {
        if (!accessGroups.value) {
            return {};
        }

        return getAccessGroupDescriptions(accessGroups.value);
    });

    const licenseGroupDescriptions = computed(() => {
        if (!licenses.value) {
            return {};
        }

        return groupByStructure(
            getFlatLicenseDescriptions(licenses.value, names.value),
            LICENSE_GROUP_STRUCTURE
        );
    });

    return {
        update,
        accessGroupDescriptions,
        licenseGroupDescriptions,
        nameOf,
    };
});
