<script setup lang="ts">
import { ref, watch } from "vue";
import { useStore } from "vuex";
import OwnerSearchField from "@/components/OwnerSearchField.vue";

const store = useStore();

withDefaults(
    defineProps<{
        disabled: boolean;
    }>(),
    {
        disabled: false,
    }
);

const isSearching = ref(false);
const searchTerm = ref("");

watch(
    () => {
        return store.state.userDetails.user.id_user;
    },
    (_, _1) => {
        isSearching.value = false;
        searchTerm.value = "";
    }
);

const activateSearch = () => {
    isSearching.value = true;
    searchTerm.value = "";
};

const cancelSearch = () => {
    isSearching.value = false;
};

const selectOwner = (owner: any) => {
    store.commit("userDetails/setOwner", owner);
    isSearching.value = false;
};
</script>

<template>
    <div>
        <div v-show="isSearching">
            <OwnerSearchField
                ref="search"
                v-model:search-term="searchTerm"
                :id-user="store.state.userDetails.user.id_user"
                @select-owner="selectOwner"
            />

            <div class="input-group-controls -right">
                <button class="btn btn-narrow" @click="cancelSearch">
                    Cancel
                </button>
            </div>
        </div>

        <div v-show="!isSearching">
            <input
                id="owner-name"
                :value="store.state.userDetails.owner.username"
                :disabled="true"
                type="text"
            />

            <div v-if="!disabled" class="input-group-controls -right">
                <button class="btn btn-narrow" @click="activateSearch">
                    Search
                </button>
            </div>
        </div>
    </div>
</template>
