<script setup lang="ts">
import { storeToRefs } from "pinia";
import AttributeItem from "./AttributeItem.vue";
import { useAttributesStore } from "@/stores/attributes";

const attributesStore = useAttributesStore();

const { state, errors, hasBeenModified, sortedAttributes } =
    storeToRefs(attributesStore);
</script>

<template>
    <section class="attribute-section">
        <div class="content-wrapper">
            <h1 class="px-2">Attributes</h1>
            <SaveResetFooter
                class="pt-1"
                :is-saving="state === 'saving'"
                :is-modified="hasBeenModified"
                @reset="attributesStore.reset"
                @save="attributesStore.save"
            />

            <div class="px-2 pt-1 flex flex-wrap flex-grow justify-between">
                <AttributeItem
                    v-for="attribute in sortedAttributes"
                    :key="attribute.key"
                    :attribute="attribute"
                    :error="errors?.fields?.[attribute.key]"
                />
            </div>
        </div>

        <ErrorSection :message="errors?.general" />

        <SaveResetFooter
            :is-saving="state === 'saving'"
            :is-modified="hasBeenModified"
            @reset="attributesStore.reset"
            @save="attributesStore.save"
        />
    </section>
</template>

<style>
.attribute-section {
    max-width: 750px;
}
</style>
