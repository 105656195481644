import { MODULES } from "@/config";
import type { Attribute } from "@/attributes";
import type { RawAttribute, UserDetail } from "@/api";

export interface ModuleValue {
    normal: number;
    admin: number;
    super: number;
}

export type ModuleField = "normal" | "admin" | "super";

type ModuleInternalValue = 0 | 1 | 2;
type ModuleCharacter = "A" | "C" | "D";

export type ModuleAttribute = Attribute<ModuleValue, string>;

export const parseUsersModules = (
    user: UserDetail
): Record<string, ModuleAttribute> => {
    const modules: Record<string, ModuleAttribute> = {};

    for (const key of MODULES) {
        if (key in user.attributes) {
            if (user.attributes[key].whocanset === null) {
                continue;
            }

            modules[key] = parseModuleAttribute(key, user.attributes[key]);
        }
    }

    return modules;
};

const parseModuleCharacter = (moduleCharacter: string): ModuleInternalValue => {
    switch (moduleCharacter) {
        case "A":
            return 0;
        case "C":
            return 1;
        case "D":
            return 2;
    }

    throw `Wrong module chracter ${moduleCharacter}`;
};

const getModuleCharacter = (value: number): ModuleCharacter => {
    switch (value) {
        case 0:
            return "A";
        case 1:
            return "C";
        case 2:
            return "D";
    }

    throw `Wrong module value ${value}`;
};

const parseModuleValue = (value: string): ModuleValue => {
    return {
        normal: parseModuleCharacter(value[0]),
        admin: parseModuleCharacter(value[1]),
        super: parseModuleCharacter(value[2]),
    };
};

const parseModuleAttribute = (
    key: string,
    attribute: RawAttribute
): ModuleAttribute => {
    return {
        key: key,
        value: parseModuleValue(attribute.value),
        inherit: attribute.inherit,
        inheritValue: parseModuleValue(attribute.inherit_value),
        inheritLineage: attribute.inherit_lineage,
        whocanset: attribute.whocanset,
        whocansetLineage: attribute.whocanset_lineage,
        default: attribute.default,
        getActiveValue() {
            return this.inherit ? this.inheritValue : this.value;
        },
        getValueRepresentation() {
            let n = getModuleCharacter(this.value.normal);
            let a = getModuleCharacter(this.value.admin);
            let s = getModuleCharacter(this.value.super);

            return `${n}${a}${s}D`;
        },
        canBeSet() {
            return this.whocanset !== null;
        },
    };
};

export const areModulesEqual = (
    first: ModuleAttribute,
    second: ModuleAttribute
): boolean => {
    if (first.inherit !== second.inherit) {
        return false;
    }

    if (first.whocanset !== second.whocanset) {
        return false;
    }

    if (
        first.value.normal !== second.value.normal ||
        first.value.admin !== second.value.admin ||
        first.value.super !== second.value.super
    ) {
        return false;
    }

    return true;
};

export const incrementModule = (
    value: ModuleValue,
    moduleField: ModuleField
): ModuleValue => {
    switch (moduleField) {
        case "normal":
            value.normal = Math.min(value.normal + 1, 2);
            value.admin = Math.max(value.normal, value.admin);
            value.super = Math.max(value.normal, value.super);
            break;
        case "admin":
            value.admin = Math.min(value.admin + 1, 2);
            value.super = Math.max(value.admin, value.super);
            break;
        case "super":
            value.super = Math.min(value.super + 1, 2);
            break;
        default:
            throw `Unknown module type ${moduleField}`;
    }

    return value;
};

export const decrementModule = (
    value: ModuleValue,
    moduleField: ModuleField
): ModuleValue => {
    switch (moduleField) {
        case "normal":
            value.normal = Math.max(value.normal - 1, 0);
            break;
        case "admin":
            value.admin = Math.max(value.admin - 1, 0);
            value.normal = Math.min(value.normal, value.admin);
            break;
        case "super":
            value.super = Math.max(value.super - 1, 0);
            value.normal = Math.min(value.normal, value.super);
            value.admin = Math.min(value.admin, value.super);
            break;
        default:
            throw `Unknown module type ${moduleField}`;
    }

    return value;
};

export const setModuleValue = (module: ModuleValue, value: string) => {
    module.normal = parseModuleCharacter(value[0]);
    module.admin = parseModuleCharacter(value[1]);
    module.super = parseModuleCharacter(value[2]);

    return module;
};
