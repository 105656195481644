<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";
import { useCustomerStore } from "@/stores/customer";
import type { SimpleCustomer } from "@/api";
import api from "@/api";
import CustomerSearchField from "@/components/utils/CustomerSearchField.vue";

const props = defineProps<{
    idUser: number;
}>();

const store = useStore();
const customerStore = useCustomerStore();

const customer = ref<SimpleCustomer | undefined>(undefined);
const state = ref<"edit" | "saving">("edit");
const error = ref<string | null>(null);

const updateCustomer = (chosenCustomer: SimpleCustomer) => {
    customer.value = chosenCustomer;
};

const save = async () => {
    const idUser = props.idUser;

    state.value = "saving";
    error.value = null;

    // For type narrowing only, should be respected already
    if (!customer.value || !customerStore.user) {
        return;
    }

    let user;

    try {
        user = await api.users.patchCustomer(idUser, customer.value.id);

        if (props.idUser === idUser) {
            store.commit("sidebar/updateTree", user, { root: true });
        }
    } catch (rawErrors) {
        if (props.idUser === idUser) {
            state.value = "edit";
            error.value = "Something failed trying to update users customer";
        }

        return;
    }

    try {
        const customer = await api.customers.get(user.customer.id);

        if (props.idUser === idUser) {
            customerStore.open([customerStore.user, customer]);
            store.commit("userDetails/setCustomer", [idUser, customer], {
                root: true,
            });
            store.commit("changePage", "user");
        }
    } catch (rawErrors) {
        if (props.idUser === idUser) {
            error.value =
                "Something failed trying to get details of users new customer";

            state.value = "edit";
        }

        return;
    }

    if (props.idUser === idUser) {
        state.value = "edit";
        customer.value = undefined;
    }
};
</script>

<template>
    <section>
        <div class="user-create-content">
            <h1 class="mx-2">Search for customer</h1>

            <div class="px-2 py-clean w-2/3 customer-form">
                <CustomerSearchField @change="updateCustomer" />
            </div>
        </div>

        <ErrorSection class="w-2/3" :message="error" />

        <SaveFooter
            class="w-2/3"
            :is-saving="state === 'saving'"
            :is-disabled="!customer"
            @save="save"
        >
            <button
                class="btn btn-underline -left"
                @click="store.commit('changePage', 'user')"
            >
                Cancel
            </button>
        </SaveFooter>
    </section>
</template>
