<script setup lang="ts">
import { onMounted, ref } from "vue";

import type { Attribute } from "@/attributes";
import api from "@/api";

import AttributeValueUserSelect from "./AttributeValueUserSelect.vue";
import AttributeValueUserSelectRemove from "./AttributeValueUserSelectRemove.vue";

const USERS_SELECTED = "Currently selected:";
const NO_USERS_SELECTED = "No users selected.";


const props = defineProps<{
    attribute: Attribute<string>;
}>();

const emit = defineEmits<{
    (e: "update", value: string): void;
}>();

interface SearchObject {
    searchValue: number
    name: string
}

const usernames = ref<SearchObject[]>([]);
const addedText = ref<string>(NO_USERS_SELECTED);

async function addUser(idUser: number): Promise<void> {
    if (usernames.value.map(u => u.searchValue).includes(idUser)) {
        return;
    }
    try {
        const res = await api.users.get(idUser);
        if (usernames.value.map(u => u.name).includes(res.username))
            return;
        
        usernames.value.push({
            name: res.username,
            searchValue: res.id_user
        });
        emit("update", usernames.value.map(u => u.searchValue).join(","));
    } catch (e) {
        return;
    }
}

function removeUser(idUser: number) : void {
    const new_usernames = usernames
        .value
        .map(u => u.searchValue)
        .filter(id => id != idUser);

    if (new_usernames.length === 0) {
        addedText.value = NO_USERS_SELECTED;
    }

    usernames.value = usernames
                        .value
                        .filter(u => new_usernames.includes(u.searchValue));
    emit(
        "update",
        new_usernames
            .join(",")
    );
}


function generate_users_selected(): string {
    // An assumption is made here - namely that inherit is set to 
    // no users selected. I believe that this approach is fine, but if 
    // changes are made in the future that invalidates the assumption
    // you would likely need some more scaffolding when generating a 
    // suitable default text.
    if (usernames.value.length === 0 || props.attribute.inherit) {
        return "No users selected";
    }

    return usernames.value.map(u => u.name).join(", ");
}

function isNotLast(idx: number): boolean {
    return idx != usernames.value.length - 1;
}

function isEmpty(): boolean {
    console.log(usernames.value);
    return usernames.value.length !== 0;
}

onMounted(async () => {
    const ids = props
        .attribute
        .value
        .split(",")
        .filter(i => i.trim().length > 0)
        .map(i => parseInt(i))
        .filter(i => i > 0);

    if (ids.length === 0) {
        return;
    }
    addedText.value = USERS_SELECTED; 

    const usernames_res = ids
        .map(async u => {
            try {
                const res = await api.users.get(u);
                return {
                    name: res.username,
                    searchValue: u
                }
            } catch (e) {
                return {
                    name: u.toString(),
                    searchValue: u
                }
            }
        });
        usernames.value = await Promise.all(usernames_res);
});

</script>

<template>
    <div class="bb -mt-0.5 py-0.5">
        <label>
            Add a user
        </label>
        <AttributeValueUserSelect :disabled="attribute.inherit" :attribute="attribute" @change="addUser"/>
    </div>

    <div class="bg-inherit">
        <label>
            {{ addedText }}
        </label>
        <br v-if="usernames.length > 0">
        <span
            v-for="(user, idx) in usernames"
            :key="idx"
        >
            <AttributeValueUserSelectRemove
                :key="user.searchValue" 
                :username="user.name"
                :inherit="attribute.inherit"
                @remove="removeUser(user.searchValue)"
                :class="{ inherited: props.attribute.inherit, editable: !props.attribute.inherit }"
            />
            <span v-if="isNotLast(idx)">
                ,&nbsp;
            </span>
        </span>
        <br>
        <span id="info_str">Note that users not accessible from your current user will be displayed as ids rather than usernames.</span>
    </div>
</template>

<style scoped>
    #info_str {
        font-size:10px;
    }
    .inherited {
        text-decoration: line-through;
    }
    .inherited:hover {
        cursor: not-allowed;
    }
    .editable:hover {
        cursor: crosshair;
    }
</style>
