import { parseNumericAttribute, areAttributesEqual } from "@/attributes";
import type { RawAttribute } from "@/api";
import type { BaseAttribute } from "@/attributes";

export interface LicenseAttribute {
    regular: BaseAttribute<bigint>;
    enforce: BaseAttribute<bigint>;
}

export const parseLicense = (
    regular: RawAttribute,
    enforce: RawAttribute
): LicenseAttribute => {
    return {
        regular: parseNumericAttribute(regular),
        enforce: parseNumericAttribute(enforce),
    };
};

export const areLicensesEqual = (
    first: LicenseAttribute,
    second: LicenseAttribute
): boolean => {
    return (
        areAttributesEqual(first.regular, second.regular) &&
        areAttributesEqual(first.enforce, second.enforce)
    );
};

export const hasLicense = (
    license_value: bigint,
    user_license: bigint
): boolean => {
    return (user_license & license_value) > 0;
};

interface LicenseDescription {
    text: string;
    value: bigint;
}

export const getFlatLicenseDescriptions = (
    licenseData: Record<string, bigint>,
    userManagement: Record<string, string>
): Record<string, LicenseDescription> => {
    let licenses: Record<string, LicenseDescription> = {};

    for (const license of Object.keys(licenseData)) {
        licenses[license] = {
            text: license in userManagement ? userManagement[license] : license,
            value: BigInt(licenseData[license]),
        };
    }

    return licenses;
};
export interface SummarizerParameter {
    type: bigint;
    number_of_sentences: bigint;
    max_percentage_of_characters_to_show: bigint;
    max_number_of_words_to_show: bigint;
}

export function decodeFromSummarizerParam(
    summParam: bigint | null | undefined
): SummarizerParameter | null {
    if (summParam == null || summParam === undefined) {
        return null;
    }

    const bigSumParam = BigInt(summParam);

    const max_number_of_words_to_show = bigSumParam >> 20n;
    let summ_param_part = bigSumParam & ((1n << 20n) - 1n);
    const max_percentage_of_characters_to_show = summ_param_part >> 12n;
    summ_param_part = summ_param_part & ((1n << 12n) - 1n);
    const number_of_sentences = summ_param_part >> 4n;
    const type = summ_param_part & ((1n << 4n) - 1n);

    const result: SummarizerParameter = {
        type,
        number_of_sentences,
        max_percentage_of_characters_to_show,
        max_number_of_words_to_show,
    };

    return result;
}

export function encodeToSummarizerParam(
    siteOptions: SummarizerParameter | null | undefined
): bigint | null {
    if (siteOptions == null || siteOptions === undefined) {
        return null;
    }

    // We are limiting the max and min here to limit overflow into the other fields
    const sum_type = Math.max(0, Math.min(Number(siteOptions.type), 15));
    const sentences = Math.max(0, Math.min(Number(siteOptions.number_of_sentences), 255));
    const percentage = Math.max(0, Math.min(Number(siteOptions.max_percentage_of_characters_to_show), 100));
    const words = Math.max(0, Math.min(Number(siteOptions.max_number_of_words_to_show), 4095));

    const typeShifted: bigint = BigInt(sum_type) << 0n; // 4 bits for type
    const number_of_sentences_shifted: bigint = BigInt(sentences) << 4n; // 8 bits for number_of_sentences
    const max_percentage_of_characters_to_show_shifted: bigint =
        BigInt(percentage) << 12n; // 8 bits for max_percentage_of_characters_to_show
    const max_number_of_words_to_show_shifted: bigint = BigInt(words) << 20n; // 12 bits for max_number_of_words_to_show

    const summarizerParam: bigint =
        typeShifted |
        number_of_sentences_shifted |
        max_percentage_of_characters_to_show_shifted |
        max_number_of_words_to_show_shifted;

    return summarizerParam;
}
