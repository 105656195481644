import _ from "lodash";
import type { RawAttribute } from "@/api";
import type { BaseAttribute } from "@/attributes";

export const hasLicense = (
    license_value: bigint,
    user_license: bigint
): boolean => {
    return (user_license & license_value) > 0;
};

export const hasAccess = (accessValue: bigint, userAccess: bigint): boolean => {
    return (userAccess & accessValue) > 0;
};

export const setAccess = (access: bigint, key: bigint): bigint => {
    return access | key;
};

export const removeAccess = (access: bigint, key: bigint): bigint => {
    return access & ~key;
};

export const invertAccessGroup = (
    access: bigint,
    bits: number = 64
): bigint => {
    let bitString = access.toString(2);

    bitString = bitString.replaceAll("0", "2");
    bitString = bitString.replaceAll("1", "0");
    bitString = bitString.replaceAll("2", "1");

    const padding = Math.max(0, bits - bitString.length);
    bitString = "1".repeat(padding) + bitString;

    const inverted = BigInt("0b" + bitString);

    return inverted;
};

export interface AccessGroup {
    accessGroup: bigint;
    sites: number[];
    sources: string;
}

const parseAccessValue = (accessValue: string): AccessGroup => {
    let parts = accessValue.split(",");

    let accessGroup = BigInt(parts[0]);
    let sites = [];
    let sources = [];

    for (let rawValue of parts.slice(1)) {
        let value = parseInt(rawValue);
        if (value < 0) {
            sites.push(-value);
        } else {
            sources.push(value);
        }
    }

    return {
        accessGroup: accessGroup,
        sites: sites,
        sources: sources.join(","),
    };
};

export const parseAccessAttribute = (
    attribute: RawAttribute
): BaseAttribute<AccessGroup> => {
    return {
        value: parseAccessValue(attribute.value),
        inherit: attribute.inherit,
        inheritValue: parseAccessValue(attribute.inherit_value),
        inheritLineage: attribute.inherit_lineage,
        whocanset: attribute.whocanset,
        whocansetLineage: attribute.whocanset_lineage,
        default: parseAccessValue(attribute.default),
        getActiveValue() {
            return this.inherit ? this.inheritValue : this.value;
        },
        getValueRepresentation() {
            let rep = `${this.value.accessGroup}`;
            let sitesRep = this.value.sites.map((i) => -i).join(",");

            if (sitesRep.length > 0) {
                rep = [rep, sitesRep].join(",");
            }
            if (this.value.sources.length > 0) {
                rep = [rep, this.value.sources.trim()].join(",");
            }

            return rep;
        },
        canBeSet() {
            return this.whocanset !== null;
        },
    };
};

export const areAccessesEqual = (
    first: BaseAttribute<AccessGroup>,
    second: BaseAttribute<AccessGroup>
): boolean => {
    if (first.value.accessGroup !== second.value.accessGroup) {
        return false;
    }

    if (first.inherit !== second.inherit) {
        return false;
    }

    if (first.whocanset !== second.whocanset) {
        return false;
    }

    let firstSites = first.value.sites;
    let secondSites = second.value.sites;

    if (firstSites.length > 0 || secondSites.length > 0) {
        if (!_.isEmpty(_.xor(firstSites, secondSites))) {
            return false;
        }
    }

    if (first.value.sources !== second.value.sources) {
        return false;
    }

    return true;
};

type AccessGroupDescription = { text: string; value: bigint };

export const getAccessGroupDescriptions = (
    accessData: Record<string, bigint>
): Record<string, AccessGroupDescription> => {
    let descriptions: Record<string, AccessGroupDescription> = {};

    for (let accessGroup of Object.keys(accessData)) {
        descriptions[accessGroup] = {
            text: accessGroup,
            value: BigInt(accessData[accessGroup]),
        };
    }

    return descriptions;
};
