<script lang="ts">
export enum Section {
    Overview,
    Access,
    Licenses,
    Modules,
    Attributes,
    Misc,
    Customer,
}
</script>

<script setup lang="ts">
import _ from "lodash";
import { useStore } from "vuex";
import { useAttributesStore } from "@/stores/attributes";
import { useCustomerStore } from "@/stores/customer";
import { useFeaturesStore } from "@/stores/features";
import { useMiscStore } from "@/stores/misc";
import { useModulesStore } from "@/stores/modules";
import SectionToggle from "@/components/SectionToggle.vue";

const store = useStore();

const attributes = useAttributesStore();
const customerStore = useCustomerStore();
const features = useFeaturesStore();
const misc = useMiscStore();
const modules = useModulesStore();

defineProps<{
    section: Section;
    disabled: boolean;
}>();

const emit = defineEmits<{
    (e: "showSection", section: Section): void;
}>();

const isSectionVisible = (section: Section): boolean => {
    switch (section) {
        case Section.Overview:
            return true;
        case Section.Access:
            return (
                features.access &&
                (store.getters["access/typeCanBeSet"]("access") ||
                    store.getters["access/typeCanBeSet"]("accessTeaser") ||
                    store.getters["access/typeCanBeSet"]("accessPay"))
            );
        case Section.Licenses:
            return (
                features.licenses &&
                (store.getters["licenses/typeCanBeSet"]("license") ||
                    store.getters["licenses/typeCanBeSet"]("licenseTeaser") ||
                    store.getters["licenses/typeCanBeSet"]("licenseLogin"))
            );
        case Section.Attributes:
            return features.attributes;
        case Section.Modules:
            return features.modules;
        case Section.Misc:
            return features.misc;
        case Section.Customer:
            return features.customer;
        default:
            throw "Unkown section";
    }
};

const hasMultipleSectionOptions = () => {
    return (
        _.sum(
            [
                Section.Overview,
                Section.Access,
                Section.Licenses,
                Section.Modules,
                Section.Attributes,
                Section.Misc,
                Section.Customer,
            ].map(isSectionVisible)
        ) > 1
    );
};
</script>

<template>
    <div v-if="hasMultipleSectionOptions()">
        <div class="content section-toggles flex justify-between">
            <div class="flex">
                <SectionToggle
                    v-if="isSectionVisible(Section.Overview)"
                    name="Overview"
                    :is-modified="store.getters['userDetails/hasBeenModified']"
                    :is-current="section === Section.Overview"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Overview)"
                >
                    <BaseIcon variant="user" weight="bold" />
                </SectionToggle>

                <SectionToggle
                    v-if="isSectionVisible(Section.Access)"
                    name="Access"
                    :is-modified="store.getters['access/hasBeenModified']"
                    :is-current="section === Section.Access"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Access)"
                >
                    <BaseIcon variant="file-plus" weight="bold" />
                </SectionToggle>

                <SectionToggle
                    v-if="isSectionVisible(Section.Licenses)"
                    name="Licenses"
                    :is-modified="store.getters['licenses/hasBeenModified']"
                    :is-current="section === Section.Licenses"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Licenses)"
                >
                    <BaseIcon variant="file-text" weight="bold" />
                </SectionToggle>

                <SectionToggle
                    v-if="isSectionVisible(Section.Modules)"
                    name="Modules"
                    :is-modified="modules.hasBeenModified"
                    :is-current="section === Section.Modules"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Modules)"
                >
                    <BaseIcon variant="tv" weight="bold" />
                </SectionToggle>

                <SectionToggle
                    v-if="isSectionVisible(Section.Attributes)"
                    name="Attributes"
                    :is-modified="attributes.hasBeenModified"
                    :is-current="section === Section.Attributes"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Attributes)"
                >
                    <BaseIcon variant="sliders" weight="bold" />
                </SectionToggle>

                <SectionToggle
                    v-if="isSectionVisible(Section.Misc)"
                    name="Misc"
                    :is-modified="misc.hasBeenModified"
                    :is-current="section === Section.Misc"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Misc)"
                >
                    <BaseIcon variant="globe" weight="bold" />
                </SectionToggle>
            </div>

            <div>
                <SectionToggle
                    v-if="isSectionVisible(Section.Customer)"
                    name="Customer"
                    :is-modified="customerStore.hasBeenModified"
                    :is-current="section === Section.Customer"
                    :is-disabled="disabled"
                    @show-section="emit('showSection', Section.Customer)"
                >
                    <BaseIcon variant="users" weight="bold" />
                </SectionToggle>
            </div>
        </div>
    </div>
</template>

<style scoped>
.section-toggles {
    margin: 0.5rem 0;
    padding: 0 1.5rem;
}
</style>
