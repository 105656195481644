<script setup lang="ts">
const props = defineProps<{
    name: string;
    isModified: boolean;
    isCurrent: boolean;
    isDisabled: boolean;
}>();

const emit = defineEmits<{
    (e: "showSection"): void;
}>();

const toggle = () => {
    if (!props.isDisabled) {
        emit("showSection");
    }
};
</script>

<template>
    <div
        class="section-toggle"
        :class="{ 'is-active': isCurrent }"
        :disabled="isDisabled"
        @click="toggle"
    >
        <slot></slot>
        <span class="name">{{ name }}</span>
        <span v-if="isModified" class="section-toggle__modified">*</span>
    </div>
</template>

<style lang="scss" scoped>
.section-toggle {
    font-weight: 600;
    color: var(--section-text);
    padding: 0.75rem;
    display: flex;
    align-items: center;
    position: relative;

    &:not([disabled="true"]):hover {
        cursor: pointer;
        color: var(--section-text-hover);
    }

    &.is-active {
        color: var(--section-text-active);
    }

    .name {
        margin-left: 4px;
    }
}

.section-toggle__modified {
    position: absolute;
    top: calc(0.75rem - 0px);
    right: calc(0.75rem - 8px);
}
</style>
