<script setup lang="ts">
import { computed } from "vue";
import { getWhoCanSetChoices } from "@/attributes";
import type { BaseAttribute } from "@/attributes";

const props = withDefaults(
    defineProps<{
        relativeIdUser: number;
        attribute: BaseAttribute<unknown>;
        disabled: boolean;
    }>(),
    { disabled: false }
);

const emit = defineEmits<{
    (e: "update", value: number): void;
}>();

const isDisabled = computed(() => {
    return (
        props.disabled || props.attribute.inherit || !props.attribute.canBeSet()
    );
});

const choices = computed(() => {
    if (!props.attribute.whocansetLineage) {
        return [];
    }

    return getWhoCanSetChoices(
        props.attribute.whocanset,
        props.relativeIdUser,
        props.attribute.whocansetLineage,
        {
            levels: 2,
            cutoff: 30,
        }
    );
});
</script>

<template>
    <div
        v-if="!attribute.inherit && attribute.canBeSet()"
        class="meta__who-can-set flex items-baseline bt pt-0.5"
        :class="{ disabled: isDisabled }"
    >
        <span class="can-set-label">Can set: </span>
        <div>
            <select
                class="select--narrow choices"
                :value="props.attribute.whocanset ?? 0"
                :disabled="isDisabled"
                @change="
                    emit(
                        'update',
                        parseInt(($event.target as HTMLInputElement).value)
                    )
                "
            >
                <option
                    v-for="choice in choices"
                    :key="choice.value"
                    :value="choice.value"
                >
                    {{ choice.label }}
                </option>
            </select>
        </div>
    </div>

    <div v-if="!attribute.canBeSet()" class="not-allowed-message">
        Not allowed to edit.
    </div>
</template>

<style scoped>
.can-set-label {
    white-space: nowrap;
}

.choices {
    width: 100%;
    max-width: 180px;
    margin-left: 0.5rem;
}
</style>
