/* eslint no-redeclare: 0 */
import { Auth } from "@opoint/authjs";
import { z } from "zod";

let auth: any;

if (import.meta.env.DEV && import.meta.env.VITE_MOCK_API) {
    auth = {
        async getUser(): Promise<null> {
            return null;
        },
        async canImpersonate(): Promise<boolean> {
            return false;
        },
        async getTokenString(): Promise<string> {
            return "bogus";
        },
    };
} else {
    auth = new Auth(
        "https://auth.opoint.com",
        import.meta.env.VITE_HOST ?? "https://user-admin.opoint.com",
        true,
        true
    );
}

const callApi = async (
    endpoint: string,
    data = {},
    method = "GET",
    { full = false, mode = undefined } = {}
) => {
    const options: {
        method: string;
        headers: Record<string, string>;
        mode?: any;
        body?: any;
    } = {
        method,
        headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + (await auth.getTokenString()),
        },
        mode: mode,
    };

    if (method !== "GET") {
        options.body = JSON.stringify(data);
    }

    const host = import.meta.env.VITE_API ?? "https://api.opoint.com/";
    let url = host + (host.endsWith("/") ? "" : "/") + endpoint;
    if (full) {
        url = endpoint;
    }

    console.log(url);
    const response = await fetch(url, options);

    let result = {};
    try {
        result = await response.json();
    } catch (error) {
        result = {};
    }

    if (!response.ok) {
        if (response.status >= 500) {
            return Promise.reject("Server error");
        } else {
            return Promise.reject(result);
        }
    } else {
        return result;
    }
};

const UserType = z.object({
    value: z.number(),
    name: z.string(),
});

export type UserType = z.infer<typeof UserType>;

const User = z.object({
    id_user: z.number(),
    username: z.string(),
    is_active: z.boolean(),
    owner: z.number().optional(),
    customer: z.string(),
    user_type: UserType,
    has_children: z.boolean(),
    display_name: z.string().nullable(),
});

export type User = z.infer<typeof User>;

const Users = z.array(User);

export type Users = z.infer<typeof Users>;

const UserOwner = z.object({
    id_user: z.number(),
    username: z.string().nullable(),
});

const UserCustomer = z.object({
    id: z.number(),
    name: z.string(),
    partner: z.number(),
    is_active: z.boolean(),
});

const SimpleCustomer = z.object({
    id: z.number(),
    name: z.string(),
    partner: z.number(),
});

export type SimpleCustomer = z.infer<typeof SimpleCustomer>;

const AttributeLineage = z.object({
    id_user: z.number(),
    username: z.string(),
});

const Attribute = z.object({
    value: z.string(),
    default: z.string(),
    inherit: z.boolean(),
    inherit_value: z.string(),
    inherit_lineage: z.array(AttributeLineage),
    whocanset: z.number().nullable(),
    whocanset_lineage: z.array(AttributeLineage).nullable(),
});

export type RawAttribute = z.infer<typeof Attribute>;

const GoogleTranslateQuota = z.object({
    quota: z.union([z.string(), z.number()]),
    translated: z.union([z.string(), z.number()]),
});

export type GoogleTranslateQuota = z.infer<typeof GoogleTranslateQuota>;

const Misc = z.object({
    GT_DATA: GoogleTranslateQuota,
});

type Misc = z.infer<typeof Misc>;

const CustomerOptionCustomer = z.object({
    id_customer: z.number(),
    name: z.string(),
    partner: z.number(),
});

const CustomerOption = z.object({
    option: UserType,
    option_allowed: z.boolean(),
    customer: CustomerOptionCustomer.nullable(),
});

export type CustomerOption = z.infer<typeof CustomerOption>;

const CreateOption = z.object({
    user_type: UserType,
    user_type_allowed: z.boolean(),
    customer_options: z.array(CustomerOption),
});

export type CreateOption = z.infer<typeof CreateOption>;

const CreateOptions = z.object({
    id_owner: z.number(),
    choices: z.array(CreateOption),
});

export type CreateOptions = z.infer<typeof CreateOptions>;

const AdminAccess = z.object({
    contact: z.boolean().default(false),
    owner: z.boolean().default(false),
    access: z.boolean().default(false),
    licenses: z.boolean().default(false),
    modules: z.boolean().default(false),
    attributes: z.boolean().default(false),
    misc: z.boolean().default(false),
    customer: z.boolean().default(false),
});

export type AdminAccess = z.infer<typeof AdminAccess>;

const ImpersonationTarget = z.object({
    id_user: z.number(),
    username: z.string(),
    customer: z.string(),
});

export type ImpersonationTarget = z.infer<typeof ImpersonationTarget>;

const ImpersonationTargets = z.object({
    self: z.array(ImpersonationTarget),
    tree: z.array(ImpersonationTarget),
    user: z.array(ImpersonationTarget),
    customer: z.array(ImpersonationTarget),
});

export type ImpersonationTargets = z.infer<typeof ImpersonationTargets>;

const MigrationState = z.object({
    value: z.number(),
    description: z.string(),
});
export type MigrationState = z.infer<typeof MigrationState>;

const RawUserDetail = z.object({
    id_user: z.number(),
    username: z.string(),
    email: z.string().nullable(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
    descr: z.string().nullable(),
    owner: z.number(),
    owner_name: z.string().nullable(),
    create_date: z.number(),
    is_active: z.boolean(),
    is_staff: z.boolean(),
    external_id: z.string().nullable(),
    customer: UserCustomer,
    attributes: z.record(z.string(), Attribute),
    misc: Misc,
    user_type: UserType,
    admin_access: AdminAccess,
    change_types: z.array(UserType),
    create_options: CreateOptions,
    impersonation_targets: ImpersonationTargets,
    display_name: z.string().nullable(),
    migration_state: MigrationState,
});

export type RawUserDetail = z.infer<typeof RawUserDetail>;

const UserDetail = RawUserDetail.extend({
    owner: UserOwner,
});

export type CreateUserPayload = {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    descr: string;
    password: string;
    owner: number;
    customer: number;
    user_type: number;
    display_name: string;
};

export type UserDetail = z.infer<typeof UserDetail>;

const CustomerDetail = z.object({
    id: z.number(),
    name: z.string(),
    partner: z.number(),
    is_active: z.boolean(),
    is_opoint: z.boolean(),
    is_educational: z.boolean(),
    max_num_users: z.union([z.string(), z.number()]).nullable(),
    num_users: z.number(),
    salesforce_id: z.string().nullable(),
});

export type CustomerDetail = z.infer<typeof CustomerDetail>;

export type CreateCustomerPayload = Omit<
    CustomerDetail,
    "id" | "is_active" | "num_users"
>;
export type PatchCustomerPayload = Partial<
    Omit<CustomerDetail, "id" | "num_users">
>;

// Temporary, should handle data and forms better in components
export interface CreateCustomerRaw {
    name: string;
    partner: number;
    is_educational: boolean;
    max_num_users: string | number | null;
    salesforce_id: string | null;
    is_opoint: boolean;
}

interface PatchCustomerRaw extends CreateCustomerRaw {
    is_active: boolean;
}

export const getCreateCustomerPayload = (
    customer: CreateCustomerRaw
): CreateCustomerPayload => {
    let maxNumUsers = customer.max_num_users;
    if (!maxNumUsers || maxNumUsers.toString().trim().length === 0) {
        maxNumUsers = null;
    }

    let payload: CreateCustomerPayload = {
        name: customer.name,
        partner: customer.partner,
        is_educational: customer.is_educational,
        is_opoint: customer.is_opoint ?? false,
        max_num_users: maxNumUsers,
        salesforce_id: customer.salesforce_id,
    };
    console.log(payload);

    return payload;
};

export const getPatchCustomerPayload = (
    customer: PatchCustomerRaw
): PatchCustomerPayload => {
    let maxNumUsers = customer.max_num_users;
    if (!maxNumUsers || maxNumUsers.toString().trim().length === 0) {
        maxNumUsers = null;
    }

    let payload: PatchCustomerPayload = {
        name: customer.name,
        partner: customer.partner,
        is_active: customer.is_active,
        is_educational: customer.is_educational,
        is_opoint: customer.is_opoint,
        max_num_users: maxNumUsers,
        salesforce_id: customer.salesforce_id,
    };
    console.log(payload);

    return payload;
};

const CustomerSearchResponse = z.array(SimpleCustomer);

export type CustomerSearchResponse = z.infer<typeof CustomerSearchResponse>;

export interface DisplayData {
    userManagement: Record<string, string>;
    accessGroups: Record<string, bigint>;
    licenses: Record<string, bigint>;
}

const SuggestionServerResult = z.object({
    id: z.string(),
    name: z.string(),
});

const SuggestionServerResponse = z.object({
    results: z.array(SuggestionServerResult),
});

export type SuggestionServerResponse = z.infer<typeof SuggestionServerResponse>;

const SiteSearchResult = z.object({
    id_site: z.number(),
    type: z.string(),
    sitename: z.string(),
});

export type SiteSearchResult = z.infer<typeof SiteSearchResult>;

const SiteSearchResponse = z.array(SiteSearchResult);

export type SiteSearchResponse = z.infer<typeof SiteSearchResponse>;

// Should probably just remove this
const postProcessUser = (user: RawUserDetail): UserDetail => {
    return {
        ...user,
        owner: {
            id_user: user.owner,
            username: user.owner_name,
        },
    };
};

export default {
    auth: {
        async logout(): Promise<void> {
            const success = await auth.logout();

            if (!success) {
                console.error("Something went wrong logging out");
            }

            location.reload();
        },
        async sendPasswordResetEmail(
            id: number,
            username: string
        ): Promise<any> {
            return callApi(
                `auth/password_reset/id/`,
                {
                    id,
                    username,
                },
                "POST"
            );
        },
        async toRoot(): Promise<void> {
            await (auth as Auth).impersonate(1);
            location.reload();
        },
        async fromRoot(): Promise<void> {
            await (auth as Auth).impersonate();
            location.reload();
        },
        async canRoot(): Promise<boolean> {
            const user = await (auth as Auth).getUser();

            if (user?.user_id === 1) {
                return false;
            }

            return await (auth as Auth).canImpersonate(1);
        },
        isImpersonating: (auth as Auth).impersonating === 1,
    },
    users: {
        async get(idUser: number | null = null): Promise<UserDetail> {
            let user;

            if (idUser) {
                user = await callApi(`opoint-user/${idUser}/`);
            } else {
                user = await callApi("opoint-user/self/");
            }

            return postProcessUser(RawUserDetail.parse(user));
        },
        async list(): Promise<Users> {
            return Users.parse(await callApi("opoint-user/"));
        },
        async migrate(idUser: number): Promise<boolean> {
            const response = await callApi(`opoint-user/${idUser}/migrate/`, {}, "POST")
                .then(() => true)
                .catch(() => false);

            return response;
        },
        async post(payload: CreateUserPayload): Promise<UserDetail> {
            let user = await callApi("opoint-user/", payload, "POST");
            return postProcessUser(RawUserDetail.parse(user));
        },
        async patch(idUser: number, payload: any): Promise<UserDetail> {
            let user = await callApi(
                `opoint-user/${idUser}/`,
                payload,
                "PATCH"
            );
            return postProcessUser(RawUserDetail.parse(user));
        },
        async search(searchTerm: any): Promise<any> {
            let encodedSearchTerm = encodeURIComponent(searchTerm);

            return await callApi(`opoint-user/search/?q=${encodedSearchTerm}`);
        },
        async searchOwner(idUser: number, searchTerm: string): Promise<any> {
            let encodedSearchTerm = encodeURIComponent(searchTerm);

            return await callApi(
                `opoint-user/${idUser}/owner_search/?q=${encodedSearchTerm}`
            );
        },
        async savePassword(idUser: number, password: string): Promise<any> {
            return callApi(
                `opoint-user/${idUser}/change_password/`,
                {
                    password: password,
                },
                "POST"
            );
        },
        async patchAttributes(
            idUser: number,
            attributes: object
        ): Promise<any> {
            let payload: { attributes: Record<string, any> } = {
                attributes: {},
            };

            for (const [name, a] of Object.entries(attributes)) {
                if (a.inherit) {
                    payload.attributes[name] = {
                        inherit: true,
                    };
                } else {
                    payload.attributes[name] = {
                        value: a.getValueRepresentation(),
                        whocanset: a.whocanset,
                    };
                }
            }

            return await this.patch(idUser, payload);
        },
        async patchMisc(idUser: number, misc: Misc): Promise<any> {
            return await this.patch(idUser, {
                misc: misc,
            });
        },
        async patchCustomer(idUser: number, idCustomer: number): Promise<any> {
            if (!idUser || !idCustomer) {
                throw "Wrong ids.";
            }

            return await this.patch(idUser, {
                customer: idCustomer,
            });
        },
    },
    customers: {
        async get(idCustomer: number): Promise<CustomerDetail> {
            return CustomerDetail.parse(
                await callApi(`customers/${idCustomer}/`)
            );
        },
        async post(payload: CreateCustomerPayload): Promise<CustomerDetail> {
            return CustomerDetail.parse(
                await callApi("customers/", payload, "POST")
            );
        },
        async patch(
            idCustomer: number,
            payload: PatchCustomerPayload
        ): Promise<CustomerDetail> {
            return CustomerDetail.parse(
                await callApi(`customers/${idCustomer}/`, payload, "PATCH")
            );
        },
        async search(searchTerm: string): Promise<CustomerSearchResponse> {
            let encodedSearchTerm = encodeURIComponent(searchTerm);

            return CustomerSearchResponse.parse(
                await callApi(`customers/search/?q=${encodedSearchTerm}`)
            );
        },
    },
    strings: {
        async displayData(): Promise<DisplayData> {
            const data = await Promise.all([
                callApi("strings/user_management/"),
                callApi("licenses/"),
                callApi("strings/access_groups/"),
            ]);

            // map new licenses endpoint to old format
            const licenses: Record<string, bigint> = (data[1] as []).reduce(
                (acc: Record<string, bigint>, obj: any) => {
                    if (obj && obj.name && obj.value) {
                        acc[obj.name] = obj.value;
                    }
                    return acc;
                },
                {}
            );

            const displayData = {
                userManagement: data[0],
                licenses: licenses,
                accessGroups: data[2],
            };

            return displayData;
        },
    },
    suggest: {
        async search(
            searchTerm: string,
            accessGroup: number | null = null
        ): Promise<SuggestionServerResponse> {
            if (accessGroup === null) {
                accessGroup = 2147483647;
            }

            let encodedSearchTerm = encodeURIComponent(searchTerm);

            return SuggestionServerResponse.parse(
                await callApi(
                    `suggest/en_GB_1:UTC+1/0/10/site:0/0/${accessGroup}//${encodedSearchTerm}`
                )
            );
        },
    },
    sites: {
        async search(idSites: number[]): Promise<SiteSearchResponse> {
            const siteString = idSites.map(String).join(",");
            const encodedSiteString = encodeURIComponent(siteString);

            return SiteSearchResponse.parse(
                await callApi(
                    `site_search/site_type/?sites=${encodedSiteString}`
                )
            );
        },
    },
};
