import _ from "lodash";
import api from "@/api";
import {
    hasAccess,
    setAccess,
    removeAccess,
    parseAccessAttribute,
    areAccessesEqual,
} from "@/access";

const updateAccessModuleState = (state, user) => {
    state.user = user;

    state.isSaving = {
        access: false,
        accessTeaser: false,
        accessPay: false,
    };
    state.errorMessage = {
        access: null,
        accessTeaser: null,
        accessPay: null,
    };

    state.access = parseAccessAttribute(user.attributes.OCG_ACCESS_GROUP);
    state.accessTeaser = parseAccessAttribute(
        user.attributes.OCG_ACCESS_GROUP_teaser
    );
    state.accessPay = parseAccessAttribute(user.attributes.ACCESS_GROUP_PAY);

    state.original = {
        access: _.cloneDeep(state.access),
        accessTeaser: _.cloneDeep(state.accessTeaser),
        accessPay: _.cloneDeep(state.accessPay),
    };

    state.activeTab = "access";

    return state;
};

const getters = {
    hasTypeBeenModified: (state) => (accessType) => {
        let access = findAccessByType(state, accessType);
        let originalAccess = findAccessByType(state.original, accessType);

        return !areAccessesEqual(access, originalAccess);
    },
    hasBeenModified: (state, getters) => {
        return (
            getters.hasTypeBeenModified("access") ||
            getters.hasTypeBeenModified("accessTeaser") ||
            getters.hasTypeBeenModified("accessPay")
        );
    },
    typeCanBeSet: (state) => (accessType) => {
        let access = findAccessByType(state, accessType);
        return access.whocanset !== null;
    },
    isTabActive: (state) => (accessType) => {
        return state.activeTab === accessType;
    },
    isTabVisible: (state, getters) => (accessType) => {
        return getters.typeCanBeSet(accessType);
    },
};

const mutations = {
    reset: (state, accessType) => {
        let originalAccess = findAccessByType(state.original, accessType);

        state[accessType] = _.cloneDeep(originalAccess);
        state.errorMessage[accessType] = null;
    },
    updateUser: (state, user) => {
        updateAccessModuleState(state, user);
    },
    updateUserPartial: (state, [accessType, user, data]) => {
        state[accessType] = data;
        state.original[accessType] = _.cloneDeep(state[accessType]);
    },
    setActiveTab: (state, accessType) => {
        state.activeTab = accessType;
    },
    setAccessGroup: (state, [accessType, key]) => {
        let access = findAccessByType(state, accessType);
        access.value.accessGroup = setAccess(access.value.accessGroup, key);
    },
    removeAccessGroup: (state, [accessType, key]) => {
        let access = findAccessByType(state, accessType);
        access.value.accessGroup = removeAccess(access.value.accessGroup, key);
    },
    toggleAccessGroup: (state, [accessType, key]) => {
        let access = findAccessByType(state, accessType);

        if (hasAccess(access.value.accessGroup, key)) {
            access.value.accessGroup = removeAccess(
                access.value.accessGroup,
                key
            );
        } else {
            access.value.accessGroup = setAccess(access.value.accessGroup, key);
        }
    },
    addSite: (state, [accessType, idSite]) => {
        let access = findAccessByType(state, accessType);
        access.value.sites.unshift(idSite);
    },
    removeSite: (state, [accessType, idSite]) => {
        let access = findAccessByType(state, accessType);

        _.remove(access.value.sites, (i) => {
            return i == idSite;
        });
    },
    toggleSite: (state, [accessType, idSite]) => {
        let access = findAccessByType(state, accessType);
        let count = access.value.sites.filter((i) => i == idSite).length > 0;

        if (count > 0) {
            _.remove(access.value.sites, (i) => {
                return i == idSite;
            });
        } else {
            access.value.sites.unshift(idSite);
        }
    },
    updateSources: (state, [accessType, value]) => {
        let access = findAccessByType(state, accessType);
        access.value.sources = value;
    },
    updateInherit: (state, accessType) => {
        let access = findAccessByType(state, accessType);
        access.inherit = !access.inherit;
    },
    updateWhocanset: (state, [accessType, whocanset]) => {
        let access = findAccessByType(state, accessType);
        access.whocanset = whocanset;
    },
    resetErrorMessage: (state, accessType) => {
        state.errorMessage[accessType] = null;
    },
    setErrorMessage: (state, [accessType, message]) => {
        state.errorMessage[accessType] = message;
    },
    updateIsSaving: (state, [accessType, value]) => {
        state.isSaving[accessType] = value;
    },
};

const actions = {
    save: async ({ state, commit, rootState }, accessType) => {
        if (state.user.id_user !== rootState.user.id_user) {
            commit("setErrorMessage", "Users out of sync. Please refresh.");
            return;
        }

        commit("updateIsSaving", [accessType, true]);

        let access = findAccessByType(state, accessType);
        let attributeName = findNameByAccessType(accessType);

        try {
            let updatedUser = await api.users.patchAttributes(
                state.user.id_user,
                {
                    [attributeName]: access,
                }
            );

            const name = findNameByAccessType(accessType);
            const data = parseAccessAttribute(updatedUser.attributes[name]);

            commit("resetErrorMessage", accessType);
            commit("updateUserPartial", [accessType, updatedUser, data]);
        } catch {
            commit("setErrorMessage", [accessType, "Error when saving"]);
        }

        commit("updateIsSaving", [accessType, false]);
    },
};

const findAccessByType = (state, accessType) => {
    switch (accessType) {
        case "access":
            return state.access;
        case "accessTeaser":
            return state.accessTeaser;
        case "accessPay":
            return state.accessPay;
        default:
            throw `Did not recognize access type ${accessType}.`;
    }
};

const findNameByAccessType = (accessType) => {
    switch (accessType) {
        case "access":
            return "OCG_ACCESS_GROUP";
        case "accessTeaser":
            return "OCG_ACCESS_GROUP_teaser";
        case "accessPay":
            return "ACCESS_GROUP_PAY";
        default:
            throw `Did not recognize access type ${accessType}.`;
    }
};

export default {
    namespaced: true,
    state: {},
    getters,
    mutations,
    actions,
};
