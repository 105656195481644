<script setup lang="ts">
import { ref } from "vue";
import api from "@/api";
import type { SimpleCustomer, UserType, UserDetail } from "@/api";
import { parseGenericErrorResponse } from "@/forms";
import type { FormErrors } from "@/forms";
import UserNewForm from "@/components/forms/UserNewForm.vue";
import type { ContactDetails } from "@/components/forms/UserNewForm.vue";

const props = defineProps<{
    owner: number;
    userType: UserType;
    customer: SimpleCustomer;
    cancelText: string;
}>();

const emit = defineEmits<{
    (e: "cancel"): void;
    (e: "newUser", newUser: UserDetail): void;
}>();

const state = ref<"editing" | "saving">("editing");
const errors = ref<FormErrors | undefined>(undefined);

const save = async (contact: ContactDetails) => {
    state.value = "saving";
    errors.value = undefined;

    try {
        emit(
            "newUser",
            await api.users.post({
                username: contact.username,
                email: contact.email,
                first_name: contact.firstName,
                last_name: contact.lastName,
                descr: contact.description,
                password: contact.password,
                owner: props.owner,
                customer: props.customer.id,
                user_type: props.userType.value,
                display_name: contact.displayName,
            })
        );
    } catch (rawErrors) {
        state.value = "editing";
        errors.value = parseGenericErrorResponse(
            rawErrors,
            "Something went wrong creating the new user",
            [
                "username",
                "email",
                "first_name",
                "last_name",
                "descr",
                "password",
                "display_name",
            ]
        );
    }
};
</script>

<template>
    <UserNewForm
        :customer-name="customer.name"
        :is-saving="state === 'saving'"
        :cancel-text="cancelText"
        :errors="errors"
        @cancel="emit('cancel')"
        @submit="save"
    />
</template>
