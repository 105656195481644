import _ from "lodash";
import api from "@/api";
import { computed, ref } from "vue";
import type { Ref } from "vue";
import type { FormErrors } from "@/forms";
import { getPatchCustomerPayload } from "@/api";
import type { UserDetail, CustomerDetail } from "@/api";
import { parseGenericErrorResponse } from "@/forms";
import { defineStore } from "pinia";

export const useCustomerStore = defineStore("customer", () => {
    // Tag of data used to avoid data races
    const user: Ref<UserDetail | undefined> = ref(undefined);

    const state = ref<"editing" | "saving">("editing");
    const errors = ref<FormErrors | undefined>(undefined);

    const customer = ref<CustomerDetail | undefined>(undefined);
    const original = ref<CustomerDetail | undefined>(undefined);

    const hasBeenModified = computed(() => {
        if (!customer.value) {
            return false;
        }

        return !_.isEqual(customer.value, original.value);
    });

    const reset = () => {
        customer.value = _.cloneDeep(original.value);
        errors.value = undefined;
    };

    const open = ([openUser, openCustomer]: [
        UserDetail,
        CustomerDetail
    ]): void => {
        user.value = _.cloneDeep(openUser);
        customer.value = _.cloneDeep(openCustomer);
        original.value = _.cloneDeep(openCustomer);
    };

    const save = async () => {
        if (!customer.value || !user.value) {
            return;
        }

        state.value = "saving";

        let updatedCustomer;

        try {
            updatedCustomer = await api.customers.patch(
                customer.value.id,
                getPatchCustomerPayload(customer.value)
            );
        } catch (rawErrors) {
            state.value = "editing";
            errors.value = parseGenericErrorResponse(
                rawErrors,
                "Could not save customer updates",
                [
                    "name",
                    "partner",
                    "is_educational",
                    "max_num_users",
                    "is_active",
                    "is_opoint",
                    "salesforce_id",
                ]
            );

            return;
        }

        open([user.value, updatedCustomer]);

        // We should ideally signal to user details that we
        // have updated customer, but need more pinia functionality before that,
        // as vuex store is not available for some reason
        // const store = useStore();
        // store.commit(
        //     "userDetails/setCustomer",
        //     [user.value.id_user, updatedCustomer],
        //     {
        //         root: true,
        //     }
        // );

        state.value = "editing";
        errors.value = undefined;
    };

    return {
        user,
        customer,
        original,
        state,
        errors,
        open,
        reset,
        save,
        hasBeenModified,
    };
});
