<template>
    <div class="input-group">
        <label :for="label">{{ label }}</label>
        <p
            v-if="charLimit !== 0"
            :class="charCount > charLimit ? 'input-error' : ''"
        >
            {{ charCount }} / {{ charLimit }}
        </p>
        <input
            v-if="typeName !== 'textarea'"
            :id="label"
            ref="inputElement"
            v-model="value"
            :type="typeName"
            :placeholder="placeholder"
        />
        <textarea
            v-else
            :id="label"
            ref="inputElement"
            v-model="value"
            :placeholder="placeholder"
        />
        <ErrorMessage :message="error" :icon="false" class="mt-0.5" />
    </div>
</template>

<script>
import ErrorMessage from "@/components/utils/ErrorMessage.vue";

export default {
    name: "FormInput",
    components: {
        ErrorMessage,
    },
    props: {
        modelValue: {
            type: null,
            required: true,
        },
        typeName: {
            type: null,
            default: "text",
        },
        label: {
            type: null,
            required: true,
        },
        placeholder: {
            type: null,
            default: "",
        },
        charLimit: {
            type: null,
            default: 0,
        },
        error: {
            type: null,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        },
        charCount: {
            get() {
                return new TextEncoder().encode(this.value).length;
            },
        },
    },
    methods: {
        focus() {
            this.$refs.inputElement.focus();
        },
    },
};
</script>
