import { EXCLUDE_FROM_ATTRIBUTES_PAGE } from "@/config";
import type { RawAttribute, UserDetail } from "@/api";

export interface Lineage {
    id_user: number;
    username: string;
}

export interface BaseAttribute<T, D = T> {
    value: T;
    default: D;
    inherit: boolean;
    inheritValue: T;
    inheritLineage: Lineage[];
    whocanset: null | number;
    whocansetLineage: null | Lineage[];
    getActiveValue(): T;
    getValueRepresentation(): string;
    canBeSet(): boolean;
}

export interface Attribute<T, D = T> extends BaseAttribute<T, D> {
    key: string;
}

export const parseUsersAttributes = (
    user: UserDetail
): Record<string, Attribute<string>> => {
    const attributes: Record<string, Attribute<string>> = {};

    for (const key of Object.keys(user.attributes)) {
        if (EXCLUDE_FROM_ATTRIBUTES_PAGE.some((a) => a === key)) {
            continue;
        }

        if (user.attributes[key].whocanset === null) {
            continue;
        }

        attributes[key] = parseAttribute(key, user.attributes[key]);
    }

    return attributes;
};

export const parseAttribute = (
    key: string,
    attribute: RawAttribute
): Attribute<string> => {
    return {
        key: key,
        value: attribute.value,
        default: attribute.default,
        inherit: attribute.inherit,
        inheritValue: attribute.inherit_value,
        inheritLineage: attribute.inherit_lineage,
        whocanset: attribute.whocanset,
        whocansetLineage: attribute.whocanset_lineage,
        getActiveValue() {
            return this.inherit ? this.inheritValue : this.value;
        },
        getValueRepresentation() {
            return `${this.value}`;
        },
        canBeSet() {
            return this.whocanset !== null;
        },
    };
};

export const parseNumericAttribute = (
    attribute: RawAttribute
): BaseAttribute<bigint> => {
    return {
        value: BigInt(attribute.value),
        default: BigInt(attribute.default),
        inherit: attribute.inherit,
        inheritValue: BigInt(attribute.inherit_value),
        inheritLineage: attribute.inherit_lineage,
        whocanset: attribute.whocanset,
        whocansetLineage: attribute.whocanset_lineage,
        getActiveValue() {
            return this.inherit ? this.inheritValue : this.value;
        },
        getValueRepresentation() {
            return `${this.value}`;
        },
        canBeSet() {
            return this.whocanset !== null;
        },
    };
};

export const areAttributesEqual = <T>(
    first: BaseAttribute<T>,
    second: BaseAttribute<T>
): boolean => {
    if (first.inherit !== second.inherit) {
        return false;
    }

    if (first.whocanset !== second.whocanset) {
        return false;
    }

    if (first.value !== second.value) {
        return false;
    }

    return true;
};

export const getWhoCanSetChoices = (
    value: number | null,
    relativeIdUser: number,
    lineage: Lineage[],
    options: { levels: 2; cutoff: number }
) => {
    let index = lineage.findIndex((l) => relativeIdUser === l.id_user);

    if (index === -1) {
        throw "Error in lineage";
    }

    // The lineage represents all the users above in tree
    let list = lineage.map((l, i) => {
        return {
            label: l.username,
            value: i - index,
        };
    });

    if (options.levels > 0) {
        list.push({
            label: "One level below",
            value: 1,
        });
        list.push({
            label: "Two levels below",
            value: 2,
        });
    }

    const whocanset = value ?? 0;

    // We have to add
    if (whocanset > options.levels && whocanset < options.cutoff) {
        list.push({
            label: `${whocanset} levels below`,
            value: whocanset,
        });
    }

    list.push({
        label: "Everyone",
        value: whocanset >= options.cutoff ? whocanset : 2147483647,
    });

    return list;
};
