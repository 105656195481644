import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { AdminAccess } from "@/api";

export const useFeaturesStore = defineStore("features", () => {
    const features = ref<AdminAccess>({
        contact: false,
        owner: false,
        access: false,
        licenses: false,
        modules: false,
        attributes: false,
        misc: false,
        customer: false,
    });

    const update = (access: AdminAccess) => {
        features.value = access;
    };

    const contact = computed(() => {
        return features.value.owner;
    });
    const owner = computed(() => {
        return features.value.owner;
    });
    const access = computed(() => {
        return features.value.access;
    });
    const licenses = computed(() => {
        return features.value.licenses;
    });
    const modules = computed(() => {
        return features.value.modules;
    });
    const attributes = computed(() => {
        return features.value.attributes;
    });
    const misc = computed(() => {
        return features.value.misc;
    });
    const customer = computed(() => {
        return features.value.customer;
    });

    return {
        update,
        contact,
        owner,
        access,
        licenses,
        modules,
        attributes,
        misc,
        customer,
    };
});
