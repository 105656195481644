import { z } from "zod";

export type FormErrorInput = string | Record<string, string[]>;

export interface FormErrors {
    general?: string;
    fields?: Record<string, string>;
}

export const normalizeErrorResponse = (
    response: FormErrorInput,
    keyFilter: string[] | undefined = undefined
): FormErrors => {
    if (typeof response === "string") {
        return {
            general: response,
        };
    }

    const errors: FormErrors = {};

    for (const [name, messages] of Object.entries(response)) {
        const message = messages.join("\n");

        if (keyFilter?.includes(name)) {
            if (!errors.fields) {
                errors.fields = {};
            }

            errors.fields[name] = message;
        } else {
            if (errors.general) {
                errors.general = errors.general + "\n" + message;
            } else {
                errors.general = message;
            }
        }
    }

    return errors;
};

export const parseGenericErrorResponse = (
    data: unknown,
    fallbackMessage: string,
    keyFilter: string[] | undefined = undefined
): FormErrors => {
    const errorSchema = z
        .union([
            z.string().transform((_) => fallbackMessage),
            z.record(z.string().array()),
        ])
        .catch(fallbackMessage);

    return normalizeErrorResponse(errorSchema.parse(data), keyFilter);
};
