<script setup lang="ts">
import _ from "lodash";
import MicroModal from "micromodal";
import { computed } from "vue";
import { useFeaturesStore } from "@/stores/features";

const features = useFeaturesStore();

const hasAnyManuals = computed(() => {
    return _.some([features.licenses, features.modules]);
});

const showManual = (modalId: string): void => {
    MicroModal.show(modalId, {
        disableFocus: false,
        disableScroll: true,
    });
};
</script>

<template>
    <section v-if="hasAnyManuals">
        <h5>Help</h5>
        <ul class="help-list">
            <li v-if="features.licenses" class="help-item">
                <div
                    class="help-item-icon-wrapper"
                    @click="showManual('modal-license-manual')"
                >
                    <span class="name">Licenses</span>
                    <BaseIcon variant="arrow-up-right" weight="light" />
                </div>
            </li>
            <li v-if="features.modules" class="help-item">
                <div
                    class="help-item-icon-wrapper"
                    @click="showManual('modal-modules-manual')"
                >
                    <span class="name">Modules</span>
                    <BaseIcon variant="arrow-up-right" weight="light" />
                </div>
            </li>
        </ul>
    </section>
</template>

<style lang="scss" scoped>
h5 {
    font-size: $font-size;
}

.help-list {
    margin-top: 0.25rem;
}

li {
    font-size: $font-size-sm;
    color: var(--text-medium);
    line-height: 1.7;
}

.help-item-icon-wrapper {
    display: inline-flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    .name {
        margin-right: 3px;
    }

    &:hover .name {
        text-decoration: underline;
    }
}
</style>
