export function assertNever(value: never) {
    throw new Error(`Should not be able to reach ${value}`);
}

interface GroupStructureGroup {
    readonly title: string;
    readonly keys: readonly string[];
}

export interface GroupStructure {
    readonly groups: Record<string, GroupStructureGroup>;
    readonly defaultGroup: string;
}

export interface Group<T> {
    text: string;
    items: T[];
}

type NamedGroups<T> = Record<string, Group<T>>;

export const groupByStructure = <T>(
    values: Record<string, T>,
    groupStructure: GroupStructure
): NamedGroups<T> => {
    let namedGroups: NamedGroups<T> = {};
    let addedKeys = new Set();

    for (let [groupKey, group] of Object.entries(groupStructure.groups)) {
        if (!(groupKey in namedGroups)) {
            namedGroups[groupKey] = {
                text: group.title,
                items: [],
            };
        }

        for (let key of group.keys) {
            if (key in values) {
                namedGroups[groupKey].items.push(values[key]);
            }

            addedKeys.add(key);
        }
    }

    // Add rest to default group
    for (let key of Object.keys(values)) {
        if (!(groupStructure.defaultGroup in namedGroups)) {
            namedGroups[groupStructure.defaultGroup] = {
                text: groupStructure.groups[groupStructure.defaultGroup].title,
                items: [],
            };
        }

        if (!addedKeys.has(key)) {
            namedGroups[groupStructure.defaultGroup].items.push(values[key]);
        }
    }

    return namedGroups;
};
