import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from 'primevue/config';
import App from "./App.vue";

import BaseIcon from "@/components/utils/BaseIcon.vue";
import ErrorMessage from "@/components/utils/ErrorMessage.vue";
import ErrorSection from "@/components/utils/ErrorSection.vue";
import FormInput from "@/components/utils/FormInput.vue";
import SaveFooter from "@/components/utils/SaveFooter.vue";
import SaveResetFooter from "@/components/utils/SaveResetFooter.vue";

import store from "@/store/index.js";

import "@/styles/main.scss";

if (import.meta.env.DEV && import.meta.env.VITE_MOCK_API) {
    const { worker } = await import("@/mocks/browser");
    await worker.start({
        onUnhandledRequest(req) {
            // TODO: parse url into components
            if (req.url.href.startsWith("https://fonts.gstatic.com")) {
            } else if (
                req.url.href.startsWith("https://localhost.opoint.com")
            ) {
            } else {
                throw new Error();
            }
        },
    });
}

const pinia = createPinia();
const app = createApp(App);
app.use(store);
app.use(pinia);
app.use(PrimeVue);
app.component("BaseIcon", BaseIcon);
app.component("ErrorMessage", ErrorMessage);
app.component("ErrorSection", ErrorSection);
app.component("FormInput", FormInput);
app.component("SaveFooter", SaveFooter);
app.component("SaveResetFooter", SaveResetFooter);
app.mount("#app");
