<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import "jquery-ui-dist/jquery-ui.min.js";
import api from "@/api";
import { userToSearchListEntry } from "@/search";


interface UserSearchProps {
    disabled: boolean
    barId: string
}

const props = withDefaults(defineProps<UserSearchProps>(), {
    barId: "js-user-search",
    disabled: true
});

const searchTerm = ref("");
const searchInput = ref<HTMLInputElement | null>(null);

const placeholder = computed(() => {
    if (props.disabled) {
        return "";
    } else {
        return "Id, username or customer name";
    }
});

const emit = defineEmits<{
    (e: "change", idUser: number): void;
}>();

onMounted(() => {
    const id = "#" + props.barId;
    // @ts-ignore
    $(id).autocomplete({
        minLength: 2,
        delay: 200,
        source: async (req: any, resp: any) => {
            const users = await api.users.search(req.term);
            resp(users.map(userToSearchListEntry));
        },
        select: async (event: any, ui: any) => {
            searchTerm.value = "";
            emit("change", ui.item.user.id_user);
        },
    });

    if (searchInput.value && props.barId === "js-user-search") {
        // Only focus on the user search in the sidebar
        searchInput.value.focus();
    }
});
</script>

<template>
    <input
        :id="`${props.barId}`"
        ref="searchInput"
        v-model="searchTerm"
        class="border-box-full-width shadow"
        type="text"
        :placeholder="placeholder"
        :disabled="disabled"
    />
</template>
