<script setup lang="ts">
import { onMounted, ref } from "vue";
import "jquery-ui-dist/jquery-ui.min.js";
import api from "@/api";
import { userToSearchListEntry } from "@/search";

const props = defineProps<{
    idUser: number;
    searchTerm: string;
}>();

const searchInput = ref<HTMLInputElement | null>(null);

const emit = defineEmits<{
    (e: "update:searchTerm", searchTerm: string): void;
    (e: "selectOwner", owner: any): void;
}>();

const update = (event: any) => {
    emit("update:searchTerm", event.target.value);
};

onMounted(() => {
    // @ts-ignore
    $("#js-owner-search").autocomplete({
        minLength: 2,
        delay: 300,
        source: async (req: any, resp: any) => {
            const results = await api.users.searchOwner(props.idUser, req.term);
            resp(results.map(userToSearchListEntry));
        },
        select: (event: any, ui: any) => {
            emit("selectOwner", ui.item.user);
        },
    });

    if (searchInput.value) {
        searchInput.value.focus();
    }
});
</script>

<template>
    <input
        id="js-owner-search"
        ref="searchInput"
        :value="searchTerm"
        @input="update"
        type="text"
        placeholder="Search"
    />
</template>
