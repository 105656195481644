<script setup lang="ts">
import { computed } from "vue";
import { format } from "date-fns";

const props = defineProps<{
    idUser: number;
    username: string;
    firstName: string;
    lastName: string;
    createDate: number;
    isActive: boolean;
    customerName: string;
    externalId: null | string;
}>();

const title = computed(() => {
    if (props.externalId) {
        return "SSO user";
    }

    if (props.firstName || props.lastName) {
        const fullName = props.firstName + " " + props.lastName;

        if (fullName.trim().length > 0) {
            return fullName.trim();
        }
    }

    return props.username;
});

const formattedCreateDate = computed(() => {
    return format(new Date(props.createDate * 1000), "y-MM-dd");
});
</script>

<template>
    <div class="title -with-meta">
        <div>
            <h1 v-if="title.length > 0">{{ title }}</h1>
            <h1 v-else>&nbsp;</h1>
            <span class="title__secondary">
                {{ customerName }}
            </span>
        </div>

        <div class="title__meta">
            <div>
                <div class="title__date">
                    Created: <span>{{ formattedCreateDate }}</span>
                </div>
                <div v-if="!isActive" class="title__date -deactivated pt-0.5">
                    Deactivated
                </div>
            </div>

            <div class="title__id">
                ID
                <strong>{{ idUser }}</strong>
            </div>
        </div>
    </div>
</template>
