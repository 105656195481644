<template>
    <div>
        <div
            :class="{ disabled: disabled }"
            class="checkbox-container"
            @click="toggle"
        >
            <div class="checkbox-alignment">
                &#8203;
                <span class="checkbox toggle" :class="toggleStateClass"></span>
            </div>
            <span class="checkbox-label">{{ description.text }}</span>
        </div>
    </div>
</template>

<script>
import { hasLicense } from "@/licenses";

export default {
    props: ["license", "licenseType", "tabType", "description"],
    data: function () {
        return {
            toggle_state: 0,
        };
    },
    computed: {
        disabled() {
            return this.$store.getters["licenses/isValueDisabled"](
                this.license,
                this.tabType,
                this.description.value
            );
        },
        toggleStateClass() {
            const isRegular = hasLicense(
                this.description.value,
                this.license.regular.getActiveValue()
            );

            const isEnforced = hasLicense(
                this.description.value,
                this.license.enforce.getActiveValue()
            );

            return {
                "tri-state-off": !isRegular,
                "tri-state-on": isRegular && !isEnforced,
                "tri-state-enforce": isEnforced,
            };
        },
    },
    methods: {
        toggle() {
            if (!this.disabled) {
                this.$store.commit("licenses/updateValue", [
                    this.licenseType,
                    this.tabType,
                    this.description.value,
                ]);
            }
        },
    },
};
</script>
