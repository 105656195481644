
<script setup lang="ts">
const excerpt = undefined
  defineExpose({ frontmatter: {}, excerpt: undefined })
</script>
<script lang="ts">
/** frontmatter meta-data for MD page **/
export interface Frontmatter {
  title?: string
  description?: string
  subject?: string
  category?: string
  name?: string
  excerpt?: string
  image?: string
  layout?: string
  requiredAuth?: boolean
  meta?: Record<string, any>[]
  [key: string]: unknown
}
export const frontmatter: Frontmatter = {}
export const excerpt: string | undefined = ""

</script>
<template>
<div class="markdown-body"><h1>Licenses</h1>
<p>Licenses are possible restrictions on the usage of an articles content and associated data, offering a flexible system to enforce compliance rules for users.</p>
<p>As compliance requirements depends on many factors, for instance the users country of residence or whether it is an internal employee, having the flexibility to toggle rules on a per user basis does for instance give adminstrators a way to enforce different rules for Norwegian, Swedish and Danish users respectively.</p>
<p>But the flexibility also comes with a cost, both the broad scope and the fact that whether the license takes effect depends on various properties of the publisher or the users search, and  also means it can be difficult to understand exactly what effect that license has in practice.</p>
<h2>Overview of the system</h2>
<p>Let’s start by setting aside some of the details and build up knowledge and complexity gradually.</p>
<p>Each license represents some form of restriction, for example <em>Don’t return header</em>, although often complicated by being phrased in negative terms.</p>
<p>It is very important to realize that these rules are not only on or off for a user, but in fact can take on one of <em>three</em> states</p>
<ul>
<li>Off, represented by white in the UI</li>
<li>Respect terms given in agreements with publishers or by local laws, represented by blue in the UI</li>
<li>Enforced, the restriction applies regardless of any agreements, represented by yellow in the UI</li>
</ul>
<p>If the license is enforced, it will apply to all articles regardless. Conversely, if it is off, the reverse effect will always apply. If it is set to respect the publishers agreements, whether the rules takes effect or not depends on each individual article in the search result.</p>
<h3>Respecting agreements</h3>
<p>Different publishers may set different terms for the usage of their content. This is where having a state for respecting those agreements come into play.</p>
<p>To see the benefit of this setup, let’s walk through an example with two sites with imaginary agreements</p>
<ul>
<li><em>Aftenposten.no</em> doesn’t allow anyone to see body text, but everything else is freely avaiable</li>
<li><em>Vg.no</em> has no restrictions, and allows anyone to see all their content.</li>
</ul>
<p>Consider the license <em>Don’t return text</em> and the possible settings on the user:</p>
<ul>
<li>If the license is turned off, it means the user will never consider this rule at all, meaning it will always return body text regardless.</li>
<li>If the license is enforced, the rule will apply regardless of the site, and the user will never see any body text.</li>
<li>If if is set to respect the conditions in the agreements, meaning the license is blue, the user will see body text for the articles from <em>Vg.no</em>, but the articles from <em>Aftenposten.no</em> will not have any body text visible as per the agreement.</li>
</ul>
<p>Similar reasoning can be used for most of the licenses, and the correspondence
is quite direct for much of the licenses pertaining to article content, such as
URLs.</p>
<h3>Profiles</h3>
<p>Whether an article shows up in the search results at all can also depend on licenses influced by which kind of search is being made.</p>
<p>For that purpose, we classify the search into three classes</p>
<ul>
<li><strong>Blessed profile searches</strong>: Searches using profiles that are marked by
adminstrators as compliant. It’s up to the adminstrator to judge what
constitutes a blessed profile. If a blessed profile is updated, it currently
keep it’s blessed state.</li>
<li><strong>Profile searches</strong>: Searches involving profiles where some of the profiles are
not marked as blessed. This also applies to tag and basket searches, although
we will refer to all of these as a profile searches.</li>
<li><strong>Free search</strong>: Any other search.</li>
</ul>
<p>In addition, a profile keeps track of when it was first created.</p>
<p>A license such as <em>Don’t allow searches not involving blessed profiles</em>, when <em>enforced</em>, would ensure that no results where returned if the user did not search with profiles that was vetted for compliance.</p>
<p>The respecting agreements part is maybe a little less clear in this case, so let’s consider an example. Certain publishers might want users to actually have a match word in the search in order to see articles from their publications. This way, users can’t just list all articles from todays newspaper, the article needs to be relevant for them in some way. This restriction would not apply for general web content. In this case, searching with a blessed profile would give results both from this publisher and from the web. A free search, however, for instance a search for articles in Norwegian, would only return hits from the web, even though the search term matches the publishers content, and the user in general has access to the respective publications.</p>
<h3>Changing licenses in the UI</h3>
<p>For technical reasons, setting and representing these three states in the UI is a little more complicated than it ideally should be.</p>
<p>There are two modes: <em>Respect</em> and <em>Enforce</em>. By default, you are in <em>Respect</em> mode, toggling whether each license is off or whether agreements should be respected. Clicking on a license will toggle between white and blue.</p>
<p>You can switch mode by clicking on the <em>Enforce</em> label below <em>Respect</em>. If you click on either a white or a blue checkbox it will turn yellow meaning the license will be enforced. This works as a mask on top of the respect values. Untoggling it will reveal the value you can set in <em>Respect</em> mode.</p>
<p>Switching back to <em>Respect</em> you will see that the values you toggled to enforce can no longer be changed. This is because once the license is enforced, whether to respect agreements or not is irrelevant, it always applies. This is the masking aspect of the enforce mode, and although not ideal, a consequence of the fact that users can set and inherit the <em>Respect</em> and <em>Enforce</em> aspects of the licenses independently.</p>
<h3>Standard, teaser and login articles</h3>
<p>This section will be expanded later, but will cover the difference between
standard, teaser and login licenses.</p>
<h2>Explanation of specific licenses</h2>
<p>In this section we will elaborate on the individual licenses. This section is
not complete, and will be expanded on a need by need basis.</p>
<h3>Article components</h3>
<p>Many of the licenses controls what parts of the article to remove from the
results , that could be the header, all the transcribed text, URLs to images
etc.</p>
<h3>Additional data</h3>
<p>Some licenses control what additional data the user is permitted to receive.
Typical examples are readership numbers or topics and entities.</p>
<p>Toggling these licenses are usually not enough for data to show up, the
application or API client typically has to explicitly ask for the data as well.
The portal either asks for data by default or expose a setting allowing the
user to show the data in the UI. For feed customers the data typically has to
be requested explicitly with the corresponding parameter.</p>
<h3>Article delay override</h3>
<p>For compliance reasons, some articles are not allowed to be displayed to users
until some time has past, to limit the utility of our platform compared to e.g.
outlets the publisher have agreements with. Respecting the delay is on by default, but
can be overriden, and thus ignored. Overriding is mostly for internal
employees.</p>
<h3>Max datesite count</h3>
<p>To avoid having users setup alerts, searches or feeds sending out full editions
of papers, the number of articles for a single day can be limited. This way, a
user might only be able to get a subset of todays articles from e.g. the
Guardian, although the search says to return everything, like <code class="">site:328511</code>.
Setting this license will respect that configured value of the site, if the
site has any limitation set.</p>
</div>
</template>
