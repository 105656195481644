<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";
import { useCustomerStore } from "@/stores/customer";
import api, { getCreateCustomerPayload } from "@/api";
import type { CreateCustomerPayload } from "@/api";
import { parseGenericErrorResponse } from "@/forms";
import type { FormErrors } from "@/forms";
import CustomerNewForm from "@/components/forms/CustomerNewForm.vue";

const store = useStore();
const customerStore = useCustomerStore();

const state = ref<"editing" | "saving">("editing");
const errors = ref<FormErrors | undefined>(undefined);

const save = async (customerData: CreateCustomerPayload) => {
    if (!customerStore.user) {
        return;
    }

    const idUser = customerStore.user.id_user;

    // Sanity check that stores has not gotten out of sync
    if (store.state.user.id_user !== idUser) {
        errors.value = { general: "Users out of sync. Please refresh." };
        return;
    }

    state.value = "saving";
    errors.value = undefined;

    // Create the new customer
    let customer;

    try {
        customer = await api.customers.post(
            getCreateCustomerPayload(customerData)
        );
    } catch (rawErrors) {
        errors.value = parseGenericErrorResponse(
            rawErrors,
            "Something went wrong creating the new customer",
            [
                "name",
                "partner",
                "is_educational",
                "is_opoint",
                "max_num_users",
                "salesforce_id",
            ]
        );

        state.value = "editing";

        return;
    }

    // Patching user with the new customer
    try {
        const user = await api.users.patchCustomer(idUser, customer.id);

        if (user.id_user === idUser) {
            customerStore.open([store.state.user, customer]);
            store.commit(
                "userDetails/setCustomer",
                [store.state.user.id_user, customer],
                {
                    root: true,
                }
            );
            store.commit("sidebar/updateTree", user, { root: true });
            store.commit("changePage", "user");
        }

        state.value = "editing";
        errors.value = undefined;
    } catch (rawErrors) {
        errors.value = {
            general:
                "Something went wrong, customer was created but could update the users customer link.",
        };

        return;
    }
};
</script>

<template>
    <CustomerNewForm
        :is-saving="state === 'saving'"
        :errors="errors"
        @cancel="store.commit('changePage', 'user')"
        @submit="save"
    />
</template>
