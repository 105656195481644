<template>
    <section id="licenses">
        <h1 class="mx-2">Licenses</h1>

        <div class="mx-2">
            <ol class="flex">
                <TabItem
                    :active="isTabActive('license')"
                    @click="setActiveTab('license')"
                >
                    Standard
                </TabItem>
                <TabItem
                    :active="isTabActive('licenseTeaser')"
                    @click="setActiveTab('licenseTeaser')"
                >
                    Teaser
                </TabItem>
                <TabItem
                    :active="isTabActive('licenseLogin')"
                    @click="setActiveTab('licenseLogin')"
                >
                    Login
                </TabItem>

                <TabItem
                    :active="isTabActive('licenseUser')"
                    @click="setActiveTab('licenseUser')"
                >
                    Content Limits
                </TabItem>
            </ol>
        </div>

        <div>
            <CanSetWrapper
                v-if="isTabActive('license')"
                :can-be-set="typeCanBeSet('license')"
            >
                <LicenseTab :license="license" license-type="license" />
            </CanSetWrapper>
            <CanSetWrapper
                v-if="isTabActive('licenseTeaser')"
                :can-be-set="typeCanBeSet('licenseTeaser')"
            >
                <LicenseTab
                    :license="licenseTeaser"
                    license-type="licenseTeaser"
                />
            </CanSetWrapper>
            <CanSetWrapper
                v-if="isTabActive('licenseLogin')"
                :can-be-set="typeCanBeSet('licenseLogin')"
            >
                <LicenseTab
                    :license="licenseLogin"
                    license-type="licenseLogin"
                />
            </CanSetWrapper>
            <CanSetWrapper
                v-if="isTabActive('licenseUser')"
                :can-be-set="typeCanBeSet('licenseUser')"
            >
                <LicenseContentLimit
                    :license="licenseUser"
                    license-type="licenseUser"
                /> 
            </CanSetWrapper>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import LicenseTab from "./LicenseTab.vue";
import LicenseContentLimit from "./LicenseContentLimit.vue";
import CanSetWrapper from "@/components/utils/CanSetWrapper.vue";
import TabItem from "@/components/utils/TabItem.vue";

export default {
    components: {
        CanSetWrapper,
        LicenseTab,
        LicenseContentLimit,
        TabItem,
    },
    computed: {
        ...mapGetters("licenses", ["isTabActive", "typeCanBeSet"]),
        ...mapState("licenses", ["license", "licenseTeaser", "licenseLogin", "licenseUser"]),
    },
    methods: {
        ...mapMutations("licenses", ["setActiveTab"]),
    },
};
</script>
