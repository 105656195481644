<template>
    <div :class="{ inherited: access.inherit }">
        <div class="mx-2 bt meta-container--wide">
            <AttributeInherit
                :attribute="access"
                @change="updateInherit(accessType)"
            />
            <AttributeWhoCanSet
                :relative-id-user="user.id_user"
                :attribute="access"
                @update="
                    (v) =>
                        $store.commit('access/updateWhocanset', [accessType, v])
                "
            />
        </div>

        <div class="access-groups bg-inherit px-2 py-label">
            <p class="label">Groups</p>
            <ol>
                <li
                    v-for="description in accessGroupDescriptions"
                    :key="description"
                    class="access-group checkbox-container"
                    :class="{
                        disabled: accessDisabled(description.value),
                    }"
                    @click.stop="toggleAccessGroup(description.value)"
                >
                    <div class="checkbox-alignment">
                        &#8203;
                        <input
                            type="checkbox"
                            :disabled="accessDisabled(description.value)"
                            :checked="accessChecked(description.value)"
                            @click.stop="nothing"
                            @change.stop="toggleAccessGroup(description.value)"
                        />
                    </div>
                    <label>{{ description.text }}</label>
                </li>
            </ol>
        </div>

        <div class="access-sites bg-inherit px-2 py-label bt">
            <SiteSearch
                :access-type="accessType"
                :access="access"
                :disabled="access.inherit"
            />
        </div>

        <div class="access-sources bg-inherit px-2 py-label bt">
            <div class="input-group">
                <label for="sources">Individual sources</label>
                <input
                    id="sources"
                    v-model="sources"
                    :disabled="access.inherit"
                    placeholder="Source string"
                    type="text"
                />
            </div>
        </div>

        <ErrorSection :message="errorMessage" />

        <SaveResetFooter
            :is-saving="isSaving"
            :is-modified="hasTypeBeenModified(accessType)"
            @reset="resetAccess"
            @save="saveAccess"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { mapState as mapPiniaState } from "pinia";
import AttributeInherit from "@/components/attributes/AttributeInherit.vue";
import AttributeWhoCanSet from "@/components/attributes/AttributeWhoCanSet.vue";
import SiteSearch from "./SiteSearch.vue";
import { useDisplayStore } from "@/stores/display";

import { hasAccess } from "@/access";

export default {
    components: {
        AttributeInherit,
        AttributeWhoCanSet,
        SiteSearch,
    },
    props: ["access", "accessType"],
    computed: {
        ...mapPiniaState(useDisplayStore, ["accessGroupDescriptions"]),
        ...mapState(["user"]),
        ...mapGetters("access", ["hasTypeBeenModified"]),
        ...mapState("access", {
            errorMessage(state) {
                return state.errorMessage[this.accessType];
            },
            isSaving(state) {
                return state.isSaving[this.accessType];
            },
        }),
        sources: {
            get() {
                return this.access.value.sources;
            },
            set(value) {
                this.$store.commit("access/updateSources", [
                    this.accessType,
                    value,
                ]);
            },
        },
    },
    methods: {
        ...mapMutations("access", ["updateInherit", "reset"]),
        ...mapActions("access", ["save"]),
        resetAccess() {
            this.reset(this.accessType);
        },
        saveAccess() {
            this.save(this.accessType);
        },
        accessChecked(key) {
            return hasAccess(key, this.access.getActiveValue().accessGroup);
        },
        accessDisabled(key) {
            return this.access.inherit;
        },
        toggleAccessGroup(key) {
            if (!this.accessDisabled(key)) {
                this.$store.commit("access/toggleAccessGroup", [
                    this.accessType,
                    key,
                ]);
            }
        },
        nothing() {
            // Just to prevent bubbling of event.
        },
    },
};
</script>
