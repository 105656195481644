<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { findDefaultOption, findDefaultCustomerOption } from "@/createOptions";
import type {
    CustomerDetail,
    CustomerOption,
    CreateOptions,
    CreateOption,
    SimpleCustomer,
    UserDetail,
} from "@/api";
import CustomerSearch from "@/components/create/CustomerSearch.vue";
import CustomerCreate from "@/components/create/CustomerCreate.vue";
import CreateOptionChoice from "@/components/create/CreateOptionChoice.vue";
import UserCreateDetails from "@/components/create/UserCreateDetails.vue";

type Step = "option" | "customerSearch" | "customerCreate" | "userDetails";

const props = defineProps<{
    options: CreateOptions;
}>();

const createOption = ref<CreateOption | undefined>(undefined);
const customerOption = ref<CustomerOption | undefined>(undefined);
const customer = ref<SimpleCustomer | undefined>(undefined);

const step = ref<Step>("option");
const previous = ref<Step | null>(null);

const emit = defineEmits<{
    (e: "newUser", user: UserDetail): void;
    (e: "cancel"): void;
}>();

function setCreateOption(
    choice: [CreateOption, CustomerOption],
    registerPrevious: boolean = true
) {
    createOption.value = choice[0];
    customerOption.value = choice[1];

    switch (customerOption.value.option.value) {
        case 0:
            // This field should be here already
            if (!customerOption.value.customer) {
                throw "Incomplete data";
            }
            customer.value = {
                id: customerOption.value.customer.id_customer,
                name: customerOption.value.customer.name,
                partner: customerOption.value.customer.partner,
            };
            step.value = "userDetails";

            if (registerPrevious) {
                previous.value = "option";
            }

            break;
        case 1:
            step.value = "customerSearch";
            break;
        case 2:
            step.value = "customerCreate";
            break;
        default:
            throw `Did not recognize option ${customerOption}`;
    }
}

// In some cases, we have few options and thus can jump directly
// to other steps
const initialOption = findDefaultOption(props.options.choices);

if (initialOption) {
    createOption.value = initialOption;

    const initialCustomerOption = findDefaultCustomerOption(initialOption);

    if (initialCustomerOption) {
        customerOption.value = initialCustomerOption;
    }
}

if (createOption.value && customerOption.value) {
    setCreateOption([createOption.value, customerOption.value], false);
}

// Make sure we update initial state when create options change
watch(
    () => {
        return props.options.id_owner;
    },
    (_, _1) => {
        step.value = "option";
        previous.value = null;
        createOption.value = undefined;
        customerOption.value = undefined;
        customer.value = undefined;
    }
);

const setCustomerFromSearch = (selectedCustomer: SimpleCustomer) => {
    customer.value = selectedCustomer;
    step.value = "userDetails";
    previous.value = "customerSearch";
};

const setNewCustomer = (newCustomer: CustomerDetail) => {
    customer.value = {
        id: newCustomer.id,
        name: newCustomer.name,
        partner: newCustomer.partner,
    };
    step.value = "userDetails";
};

const setNewUser = (newUser: UserDetail) => {
    emit("newUser", newUser);
};

const detailsCancelText = computed(() => {
    if (previous.value) {
        return "Go back";
    } else {
        return "Cancel";
    }
});

const handleDetailsCancellation = () => {
    if (previous.value) {
        step.value = previous.value;
        previous.value = null;
    } else {
        emit("cancel");
    }
};
</script>

<template>
    <div class="content bl">
        <CreateOptionChoice
            v-if="step === 'option'"
            :options="options.choices"
            :initial-option="createOption"
            :initial-customer-option="customerOption"
            @cancel="emit('cancel')"
            @submit="setCreateOption"
        />
        <CustomerSearch
            v-else-if="step === 'customerSearch'"
            @cancel="step = 'option'"
            @customer="setCustomerFromSearch"
        />
        <CustomerCreate
            v-else-if="step === 'customerCreate'"
            @cancel="step = 'option'"
            @new-customer="setNewCustomer"
        />
        <UserCreateDetails
            v-else-if="step === 'userDetails' && createOption && customer"
            :owner="options.id_owner"
            :user-type="createOption.user_type"
            :customer="customer"
            :cancel-text="detailsCancelText"
            @cancel="handleDetailsCancellation"
            @new-user="setNewUser"
        />
    </div>
</template>
