<script setup lang="ts">
import { ref } from "vue";
import type { SimpleCustomer } from "@/api";
import CustomerSearchField from "@/components/utils/CustomerSearchField.vue";

const selectedCustomer = ref<SimpleCustomer | undefined>(undefined);

const emit = defineEmits<{
    (e: "customer", customer: SimpleCustomer): void;
    (e: "cancel"): void;
}>();

const setCustomerFromSearch = (customer: SimpleCustomer) => {
    selectedCustomer.value = customer;
};

const submit = (event: any) => {
    if (!selectedCustomer.value) {
        return;
    }

    emit("customer", selectedCustomer.value);
};
</script>

<template>
    <section>
        <div class="user-create-content">
            <h1 class="mx-2">Search for customer</h1>

            <div class="px-2 py-clean w-2/3 customer-form">
                <CustomerSearchField @change="setCustomerFromSearch" />
            </div>
        </div>
        <div class="w-2/3 flex justify-between items-center bt footer">
            <button class="btn btn-underline -left" @click="emit('cancel')">
                Go back
            </button>
            <button :disabled="!selectedCustomer" @click="submit">Next</button>
        </div>
    </section>
</template>
