<template>
    <section>
        <UserDetailsHeader
            :id-user="user.id_user"
            :username="username"
            :first-name="firstName"
            :last-name="lastName"
            :create-date="createDate"
            :is-active="isActive"
            :customer-name="customer.name"
            :external-id="user.external_id"
        />

        <div class="px-2 py-label w-2/3 form form-layout">
            <FormInput
                v-model="username"
                label="Username"
                char-limit="255"
                :error="errors.username"
            />
            <FormInput
                v-model="displayName"
                label="Display name"
                char-limit="255"
                :error="errors.displayName"
            />
            <FormInput
                v-model="firstName"
                label="First name"
                char-limit="100"
                :error="errors.firstName"
            />
            <FormInput
                v-model="lastName"
                label="Last name"
                char-limit="100"
                :error="errors.lastName"
            />
            <FormInput
                v-model="email"
                label="Email"
                char-limit="100"
                :error="errors.email"
            />
            <FormInput
                v-model="description"
                label="Description"
                type-name="textarea"
                char-limit="200"
                :error="errors.description"
            />
            <div class="input-group">
                <label>Password</label>
                <div>
                    <button
                        class="btn btn-narrow"
                        @click="openPasswordResetModal"
                    >
                        Change here
                    </button>
                    <button
                        class="btn btn-narrow reset-email"
                        @click="openPasswordResetEmailModal"
                    >
                        Send password reset email
                    </button>
                </div>

                <ErrorMessage
                    v-if="passwordResetEmailError"
                    :message="passwordResetEmailError"
                />
                <span style="color: green">
                    {{ passwordResetEmailMessage }}
                </span>
            </div>
        </div>

        <div class="mx-2 py-label bt w-2/3 form form-layout">
            <div class="advanced-options-container">
                <div class="input-group">
                    <label for="">Type</label>
                    <select
                        :value="$store.state.userDetails.userType"
                        :disabled="isUserTypeDisabled"
                        @change="updateUserType"
                    >
                        <option
                            v-for="userType in $store.getters[
                                'userDetails/changeUserTypes'
                            ]"
                            :key="userType"
                            :value="userType.value"
                        >
                            {{ userType.name }}
                        </option>
                    </select>
                </div>

                <FormInput
                    v-if="$store.getters['isStaff']"
                    v-model="isStaff"
                    label="Staff"
                    :error="errors.isStaff"
                    type-name="checkbox"
                />

                <FormInput
                    v-model="isActive"
                    label="Active"
                    :error="errors.isActive"
                    type-name="checkbox"
                />
            </div>

            <div
                v-show="$store.getters['userDetails/isOwnerVisible']"
                class="input-group"
            >
                <label>Owner</label>
                <OwnerSearchWrapper
                    :disabled="!owner || $store.getters['sidebar/isRoot']"
                />
            </div>
        </div>

        <ErrorSection class="w-2/3" :message="errorMessage" />

        <MigrateUser
            class="mx-2 py-label w-2/3"
            :state="migrationState"
            :status="migrationInProgress"
            @migrate="migrate"
        />

        <SaveResetFooter
            class="w-2/3"
            :is-saving="isSaving"
            :is-modified="hasBeenModified"
            @reset="reset"
            @save="save"
        >
            <div>
                <button
                    v-if="
                        user.create_options.choices.filter(
                            (c) => c.user_type_allowed
                        ).length > 0
                    "
                    @click="$store.dispatch('createNewUser')"
                >
                    New child user
                </button>
                <div v-else class="create-error-message">
                    {{ user.create_options.message }}
                </div>
            </div>
        </SaveResetFooter>
    </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { mapState as mapPiniaState } from "pinia";
import UserDetailsHeader from "./UserDetailsHeader.vue";
import OwnerSearchWrapper from "@/components/OwnerSearchWrapper.vue";
import MicroModal from "micromodal";
import MigrateUser from "@/components/utils/MigrateUser.vue";
import { useFeaturesStore } from "@/stores/features";

export default {
    name: "UserDetails",
    components: {
        UserDetailsHeader,
        OwnerSearchWrapper,
        MigrateUser,
    },
    computed: {
        ...mapPiniaState(useFeaturesStore, ["owner"]),
        ...mapState("userDetails", [
            "customer",
            "createDate",
            "errorMessage",
            "errors",
            "isSaving",
            "passwordResetEmailMessage",
            "passwordResetEmailError",
            "user",
            "migrationState",
            "migrationInProgress",
        ]),
        ...mapGetters("userDetails", ["hasBeenModified"]),
        ...mapFields("userDetails", [
            "contact.username",
            "contact.email",
            "contact.firstName",
            "contact.lastName",
            "contact.description",
            "contact.isActive",
            "contact.isStaff",
            "contact.displayName",
        ]),
        isUserTypeDisabled() {
            return (
                this.$store.getters["userDetails/changeUserTypes"].length === 1
            );
        },
    },
    methods: {
        updateUserType(event) {
            this.$store.commit(
                "userDetails/updateUserType",
                parseInt(event.target.value)
            );
        },
        ...mapMutations("userDetails", ["reset"]),
        ...mapActions("userDetails", [
            "save",
            "openPasswordResetModal",
            "openPasswordResetEmailModal",
            "migrate",
        ]),
        openPasswordResetModal() {
            MicroModal.show("modal-password-reset", {
                disableFocus: false,
                disableScroll: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.reset-email {
    margin-top: 26px;
}

.advanced-options-container {
    display: flex;
    justify-content: space-between;

    > * + * {
        margin-left: 1.5rem;
    }
}

.create-error-message {
    font-style: italic;
    font-size: $font-size-sm;
    color: #aaa;
}
</style>
