<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import UserListPagination from "./UserListPagination.vue";

const store = useStore();

const props = withDefaults(
    defineProps<{
        node: any;
        hasNewUser?: boolean;
        showEmptyMessage?: boolean;
    }>(),
    {
        showEmptyMessage: false,
    }
);

const isListVisible = computed(() => {
    return props.hasNewUser || props.node.visibleChildren.length > 0;
});

const isVisible = computed(() => {
    return isListVisible.value || props.showEmptyMessage;
});

const missingFullPage = computed(() => {
    let page = props.node.pagination.page;
    let pages = props.node.pagination.pages;

    if (pages === 1 || page < pages) {
        return 0;
    }

    return pages * props.node.pagination.pageSize - props.node.pagination.total;
});
</script>

<template>
    <div v-if="isVisible">
        <ul v-if="isListVisible" class="sidebar__user-list-container">
            <li v-if="hasNewUser">
                <slot name="new-user"></slot>
            </li>

            <li v-for="child in node.getVisibleChildren()" :key="child">
                <slot name="child" :child="child" />
            </li>

            <li v-for="n in missingFullPage" :key="n">
                <div class="sidebar__user-entry">
                    <div class="sidebar__username">&#8203;</div>
                </div>
            </li>
        </ul>

        <div v-if="!isListVisible && showEmptyMessage">No users.</div>

        <UserListPagination
            :page="node.pagination.page"
            :pages="node.pagination.pages"
            @gotoPreviousPage="store.commit('sidebar/previousPage', node)"
            @gotoNextPage="store.commit('sidebar/nextPage', node)"
        />
    </div>
</template>
