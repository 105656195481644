<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import type { Attribute } from "@/attributes";
import type { Option } from "@/config";

const props = defineProps<{
    attribute: Attribute<string>;
    options: readonly Option[];
}>();

const emit = defineEmits<{
    (e: "update", value: string): void;
}>();

const bits = computed(() => {
    let value = parseInt(props.attribute.getActiveValue());
    let options = _.cloneDeep(props.options);

    return options.map((o) => {
        return {
            label: o.label,
            shift: o.value,
            value: ((1 << o.value) & value) > 0,
        };
    });
});

const updateValue = (shift: number): void => {
    let value = parseInt(props.attribute.getActiveValue());
    let bit = 1 << shift;

    if ((value & bit) > 0) {
        value -= bit;
    } else {
        value += bit;
    }

    emit("update", value.toString());
};
</script>

<template>
    <div class="-mt-0.5 py-0.5">
        <div
            v-for="bit in bits"
            :key="bit.shift"
            class="checkbox-container--plain"
            :class="{
                disabled: attribute.inherit,
            }"
        >
            <label>
                <input
                    type="checkbox"
                    :disabled="attribute.inherit"
                    :checked="bit.value"
                    @input="updateValue(bit.shift)"
                />
                {{ bit.label }}
            </label>
        </div>
    </div>
</template>

<style scoped>
input {
    margin-right: 5px !important;
}
</style>
