<script setup lang="ts">
defineProps<{
    translated: string;
    quota: string;
}>();

const emit = defineEmits<{
    (e: "update:translated", value: string): void;
    (e: "update:quota", value: string): void;
}>();

function updateTranslated(event: any): void {
    emit("update:translated", event.target.value);
}

function updateQuota(event: any): void {
    emit("update:quota", event.target.value);
}
</script>

<template>
    <div>
        <div class="translate-fields">
            <div class="input-group">
                <label>
                    Translated
                    <input
                        :value="translated"
                        @input="updateTranslated"
                        type="number"
                    />
                </label>
            </div>
            <div class="input-group translate-quota">
                <label>
                    Quota
                    <input :value="quota" @input="updateQuota" type="number" />
                </label>
            </div>
        </div>
        <p>
            <small>
                Translation quotas are per character translated. An article is
                on average 1500 characters long.
            </small>
        </p>
    </div>
</template>

<style lang="scss" scoped>
.translate-fields {
    display: flex;
}

.translate-fields .input-group {
    flex: 1 1 5rem;
    min-width: 0;
}

.translate-fields input {
    width: 100%;
}

.translate-quota {
    margin-left: 1rem;
}

p {
    line-height: 1.4rem;
}
</style>
