import { createStore } from "vuex";
import api from "@/api";

import accessModule from "./modules/access.js";
import licensesModule from "./modules/licenses.js";
import sidebarModule from "./modules/sidebar.js";
import userDetailsModule from "./modules/user_details.js";

import { useAttributesStore } from "@/stores/attributes";
import { useCustomerStore } from "@/stores/customer";
import { useMiscStore } from "@/stores/misc";
import { useModulesStore } from "@/stores/modules";
import { useOverviewStore } from "@/stores/overview";

const getters = {
    isCurrentPage: (state) => (page) => {
        return state.page === page;
    },
    isStaff: (state) => {
        return state.loginUser.is_staff;
    },
    userChangeDialogVisible: (state) => {
        return state.userChangeTarget !== null;
    },
    hasBeenModified: (state, getters) => {
        const attributes = useAttributesStore();
        const customerStore = useCustomerStore();
        const misc = useMiscStore();
        const modules = useModulesStore();

        return (
            getters["userDetails/hasBeenModified"] ||
            getters["access/hasBeenModified"] ||
            getters["licenses/hasBeenModified"] ||
            modules.hasBeenModified ||
            attributes.hasBeenModified ||
            misc.hasBeenModified ||
            customerStore.hasBeenModified
        );
    },
};

const mutations = {
    updateUser: (state, user) => {
        state.user = user;
    },
    setLoginUser: (state, user) => {
        state.loginUser = user;
    },
    changePage: (state, page) => {
        state.page = page;
    },
    showUserChangeDialog: (state, data) => {
        state.userChangeTarget = data;
    },
    closeUserChangeDialog: (state) => {
        state.userChangeTarget = null;
    },
};

const actions = {
    updateUser: async ({ state, commit, dispatch }, { idUser, resetTree }) => {
        if (!idUser) {
            throw "Undefined id";
        }

        const user = await api.users.get(idUser);

        const overview = useOverviewStore();
        const modules = useModulesStore();
        const attributes = useAttributesStore();
        const misc = useMiscStore();

        commit("updateUser", user);
        commit("userDetails/updateUser", user);
        overview.updateUser(user);
        commit("access/updateUser", user);
        commit("licenses/updateUser", user);
        modules.updateUser(user);
        attributes.updateUser(user);
        misc.updateUser(user);

        commit("sidebar/updateUser", {
            user: user,
            resetTree: resetTree,
        });

        const customerStore = useCustomerStore();
        customerStore.open([user, null]);

        commit("closeUserChangeDialog");
        commit("changePage", "user");
    },
    updateUserInAll: ({ commit }, user) => {
        const overview = useOverviewStore();
        const modules = useModulesStore();
        const attributes = useAttributesStore();
        const misc = useMiscStore();

        commit("updateUser", user);
        commit("userDetails/updateUser", user);
        overview.updateUser(user);
        commit("access/updateUser", user);
        commit("licenses/updateUser", user);
        modules.updateUser(user);
        attributes.updateUser(user);
        misc.updateUser(user);

        commit("sidebar/updateUser", {
            user: user,
            resetTree: true,
        });

        const customerStore = useCustomerStore();
        customerStore.open([user, null]);

        commit("closeUserChangeDialog");
        commit("changePage", "user");
    },
    updateState: async ({ state, commit, getters, dispatch }, change) => {
        switch (change.type) {
            case "user":
                dispatch("updateUser", change.payload);
                commit("closeUserChangeDialog");
                break;
            case "newUser":
                commit("changePage", "newUser");
                commit("closeUserChangeDialog");
                break;
            default:
                throw "Uknown target type";
        }
    },
    updateStateSaveSafe: async (
        { state, commit, getters, dispatch },
        change
    ) => {
        // Delay if something has been modified
        if (getters["hasBeenModified"]) {
            commit("showUserChangeDialog", change);
        } else {
            dispatch("updateState", change);
        }
    },
    moveToUserChangeTarget: async ({ state, dispatch, commit }) => {
        dispatch("updateState", state.userChangeTarget);
    },
    updateToUser: async ({ dispatch }, idUser) => {
        dispatch("updateStateSaveSafe", {
            type: "user",
            payload: {
                idUser: idUser,
            },
        });
    },
    updateTree: async ({ commit }) => {
        const users = await api.users.list();
        commit("sidebar/setUsers", users);
    },
    createNewUser: ({ state, dispatch }, data) => {
        dispatch("updateStateSaveSafe", {
            type: "newUser",
        });
    },
    cancelNewUser: ({ state, dispatch }) => {
        dispatch("updateUser", {
            idUser: state.user.id_user,
            resetTree: false,
        });
    },
};

export default createStore({
    state: {
        page: "user",
        userChangeTarget: null,
    },
    getters,
    mutations,
    actions,
    modules: {
        sidebar: sidebarModule,
        userDetails: userDetailsModule,
        access: accessModule,
        licenses: licensesModule,
    },
});
