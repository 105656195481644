<script setup lang="ts">
import api from "@/api";
import MicroModal from "micromodal";
import ModalWrapper from "@/components/utils/ModalWrapper.vue";
import { ref } from "vue";
import type { Ref } from "vue";

const props = defineProps<{
    idUser: number;
}>();

enum State {
    Default,
    Saving,
}

const state = ref(State.Default);
const password = ref("");
const repeated = ref("");
const errorMessage: Ref<string | null> = ref(null);

async function save() {
    if (password.value.length == 0 || repeated.value.length == 0) {
        errorMessage.value = "Please fill in both fields";
    } else if (password.value.length !== repeated.value.length) {
        errorMessage.value = "Passwords don't match";
    } else {
        try {
            state.value = State.Saving;
            await api.users.savePassword(props.idUser, password.value);
            // TODO: add confirmation message on a message queue
            closeModal();
        } catch (e) {
            state.value = State.Default;
            if (e instanceof Array) {
                errorMessage.value = e.join("\n");
            } else {
                errorMessage.value = "Error when saving.";
            }
        }
    }
}

function closeModal() {
    state.value = State.Default;
    password.value = "";
    repeated.value = "";
    errorMessage.value = null;
    MicroModal.close("modal-password-reset");
}
</script>

<template>
    <ModalWrapper modal-id="modal-password-reset">
        <section class="password-reset-wrapper form">
            <h3 class="h2">Change password</h3>

            <div class="input-group">
                <label for="password-reset-new">New password</label>
                <input
                    id="password-reset-new"
                    v-model="password"
                    type="password"
                    autocomplete="new-password"
                />
            </div>

            <div class="input-group">
                <label for="password-reset-repeat">Repeat password</label>
                <input
                    id="password-reset-repeat"
                    v-model="repeated"
                    type="password"
                    autocomplete="new-password"
                />
            </div>

            <ErrorMessage :message="errorMessage" :icon="true" />

            <div class="button-container-right">
                <button class="btn btn-underline" @click="closeModal">
                    Cancel
                </button>
                <button :disabled="state === State.Saving" @click="save">
                    Save
                </button>
            </div>
        </section>
    </ModalWrapper>
</template>

<style lang="scss" scoped>
.password-reset-wrapper {
    padding: 2rem;
    width: 350px;
}

h3 {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
}

.form > * + * {
    margin-top: 1.5rem;
}

.button-container-right {
    margin-top: 3rem;
}
</style>
